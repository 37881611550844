import {Injectable, Injector} from '@angular/core';
import {ArchitectElement} from './architect-element';
import {ELEMENT_DEFAULTS} from './element-defaults';
import {AppHttpClient} from '@common/core/http/app-http-client.service';
import {Settings} from '@common/core/config/settings.service';

@Injectable({
    providedIn: 'root',
})
export class CustomElementsService {

    constructor(
        private injector: Injector,
        private settings: Settings,
    ) {
    }

    public async fetchCustomEls(): Promise<ArchitectElement[]> {
        const path = this.settings.getBaseUrl() + `/${AppHttpClient.prefix}/elements/custom`;

        try {
            const module = await import(/* webpackIgnore: true */ path);
            const components = [];
            Object.keys(module).forEach((key, index) => {
                if (!key.startsWith('style') && !key.startsWith('template')) {
                    const component = new module[key](this.injector);
                    component.html = module[`template${index}`];
                    component.css = module[`style${index}`];
                    Object.entries(ELEMENT_DEFAULTS).forEach(
                        ([prop, value]) => {
                            if (typeof component[prop] === 'undefined') {
                                component[prop] = value;
                            }
                        }
                    );
                    components.push(component);
                }
            });
            return components;
        } catch (e) {
            return [];
        }
    }
}
