<h1 mat-dialog-title trans>
    {{ title }}
</h1>

<div mat-dialog-content *ngIf="message != null" trans>
    <p *ngIf="message != null">{{ message }}</p>
</div>

<div mat-dialog-actions>
    <button mat-button (click)="onDismiss()" trans>Okay</button>
</div>
