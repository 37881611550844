<div class="modal-header">
    <h2 class="modal-title" mat-dialog-title trans>Choose projects to write content block changes to</h2>
    <button type="button" (click)="close()" class="close-button" mat-icon-button tabindex="-1">
        <mat-icon svgIcon="close"></mat-icon>
    </button>
</div>

<form (ngSubmit)="confirm()" ngNativeValidate>
    <mat-dialog-content>
        <ul class="selection-list">
            <li class="list-item">
                <div class="list-item-header" (click)="selectAll()" matRipple>
                    <div class="meta">
                        <div class="list-item-name">Alle auswählen</div>
                    </div>
                    <mat-pseudo-checkbox [state]="allSelected ? 'checked' : 'unchecked'"></mat-pseudo-checkbox>
                </div>
            </li>
            <li class="list-item" *ngFor="let project of getProjects()">
                <div class="list-item-header" (click)="selectionModel.toggle(project)" matRipple>
                    <div class="meta">
                        <div class="list-item-name">{{project.name}}</div>
                    </div>
                    <mat-pseudo-checkbox [state]="selectionModel.isSelected(project) ? 'checked' : 'unchecked'"></mat-pseudo-checkbox>
                </div>
            </li>
        </ul>
    </mat-dialog-content>

    <mat-dialog-actions class="buttons right mat-dialog-actions">
        <button (click)="close()" mat-button type="button" trans>Cancel</button>
        <button type="submit" mat-raised-button color="accent" trans>Select</button>
    </mat-dialog-actions>
</form>
