<div class="wrapper" id="project-media-container">
    <file-drop-container (filesDropped)="filesDropped($event)" [enabled]="uploadEnabled">
        <div class="header">
            <div class="header-left">
                <h2 *ngIf="title" trans>{{title}}</h2>
                <file-entry-upload-button
                        [hidden]="!uploadEnabled"
                        [endpoint]="'media/project/'+projectId"
                        [acceptType]="filterType"
                        [ownershipType]="ownershipType"
                        [ownershipId]="'' + projectId"
                        (filesUploaded)="filesUploaded($event)"></file-entry-upload-button>
            </div>
            <div class="header-right">
                <span *ngIf="hasSelection()" class="selection-info">
                    {{getSelectionCount()}} files selected
                </span>
                <button mat-icon-button [matTooltip]="'Delete selected' | trans"
                        *ngIf="canDelete()"
                        (click)="maybeDeleteSelectedFiles()">
                    <mat-icon svgIcon="delete"></mat-icon>
                </button>
            </div>
        </div>

        <div class="filter-panel">
            <div class="inline-inputs-container">
                <div class="input-container">
                    <label for="sortSelect" trans>Sort</label>
                    <ng-select id="sortSelect" [clearable]="false" appearance="outline"
                               appendTo="body"
                               [(ngModel)]="selectedSortOption"
                               (change)="onSortOrFilterChange()">
                        <ng-option *ngFor="let sortOption of sortOptions"
                                   [value]="sortOption">{{sortOption.label}}</ng-option>
                    </ng-select>
                </div>
                <div class="input-container">
                    <label for="filterSelect" trans>Filter</label>
                    <ng-select id="filterSelect" [clearable]="false" appearance="outline"
                               appendTo="body"
                               [(ngModel)]="selectedFilterOption"
                               (change)="onSortOrFilterChange()">
                        <ng-option *ngFor="let filterOption of filterOptions"
                                   [value]="filterOption">{{filterOption.label}}</ng-option>
                    </ng-select>
                </div>
            </div>
        </div>

        <div class="content">
            <media-grid-breadcrumbs [path]="currentFolderPath$ | async" (pathClick)="navigateFolderPath($event)"></media-grid-breadcrumbs>

            <div *ngIf="projectId == null" trans>No project selected</div>
            <no-results-message
                    *ngIf="!loading && !((visibleFiles$ | async)?.length > 0) && !((visibleFolders$ | async)?.length > 0)">
                <span primary-text trans>Nothing To Display.</span>
                <span secondary-text
                      trans>Seems like no files have been created yet.</span>
            </no-results-message>
            <loading-indicator [isVisible]="loading" class="overlay overlay-partial"></loading-indicator>
            <media-grid [files]="visibleFiles$ | async"
                        [folders]="visibleFolders$ | async"
                        [selectable]="canSelect()"
                        [multiselect]="multiselect"
                        (selectionChange)="onSelectionChange($event)"
                        (folderClick)="navigateFolder($event)"></media-grid>
        </div>
    </file-drop-container>
</div>
