import {Injectable} from '@angular/core';
import {
    MediaSelectModalComponent, MediaSelectModalData
} from '@common/media/media-select-modal/media-select-modal.component';
import {Modal} from '@common/core/ui/dialogs/modal.service';
import {MatDialogRef} from '@angular/material/dialog';

@Injectable({
    providedIn: 'root'
})
export class MediaSelectorService {

    constructor(
        private modal: Modal
    ) {
    }

    openModal(data?: MediaSelectModalData): MatDialogRef<MediaSelectModalComponent> {
        return this.modal.show(MediaSelectModalComponent, {multiselect: false, ...data});
    }
}
