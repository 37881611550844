export class Video {
    id?: number;
    type?: string;
    link?: string;
    videoID?: string;
    title?: string;
    description?: string;
    thumb?: string;
    agency_id?: number = null;
    publish_agency_ids?: string;
    created_at?: string;
    updated_at?: string;

    agency_name?: string;
    model: Video;

    constructor(params: Object = {}) {
        for (const name in params) {
            this[name] = params[name];
        }
    }
}

export interface VideoRef {
    type: VideoType;
    id: string;
}

export enum VideoType {
    youtube = 'youtube',
    vimeo = 'vimeo',
}
