import {Injectable} from '@angular/core';
import {CurrentUser} from '@common/auth/current-user';
import {isNodeEnclosedByNodeWithCssClass} from '@common/core/utils/is-node-enclosed-by-node-with-css-class';

@Injectable({
    providedIn: 'root',
})
export class BuilderContentService {

    constructor(
        private currentUser: CurrentUser,
    ) {
    }

    public isEditable(node?: HTMLElement): boolean {
        if (node == null) {
            return false;
        }

        if (this.isContentAdmin()) {
            return true;
        }

        const isParentLocked = this.parentLocked(node);
        if (isParentLocked) {
            return false;
        }

        return true;
    }

    isContentAdmin() {
        return this.currentUser.hasPermission('content.edit-locked');
    }

    public parentLocked(node): boolean {
        if (this.isContentAdmin()) {
            return false;
        }

        return isNodeEnclosedByNodeWithCssClass(node, 'not-editable');
    }
}
