export class GalleryWidget {
    rowHeight = 160;
    maxRowHeight = 250;

    constructor(public items: GalleryWidgetItem[] = []) {
    }
}

export class GalleryWidgetItem {
    constructor(
        public url: string,
        public label: string = '',
    ) {
    }
}
