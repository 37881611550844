import {
    ChangeDetectionStrategy,
    Component,
    EventEmitter,
    Input,
    OnDestroy,
    OnInit,
    Output,
    ViewChild
} from '@angular/core';
import {MatSort} from '@angular/material/sort';
import {Modal} from '../../../core/ui/dialogs/modal.service';
import {
    ConfirmModalComponent
} from '../../../core/ui/confirm-modal/confirm-modal.component';
import {Settings} from '../../../core/config/settings.service';
import {FileEntry, FileEntryType} from '../../../uploads/types/file-entry';
import {CurrentUser} from '../../../auth/current-user';
import {UploadsApiService} from '../../../uploads/uploads-api.service';
import {
    PaginatedDataTableSource
} from '@common/shared/data-table/data/paginated-data-table-source';
import {Paginator} from '@common/shared/paginator.service';
import {
    FILE_ENTRY_INDEX_FILTERS
} from '@common/admin/file-entry/file-entry-index/file-entry-index-filters';
import {ViewTypes} from '@common/shared/data-table/data-table.component';
import {SelectionChange, SelectionModel} from '@angular/cdk/collections';
import {
    FileEntryUploadButtonComponent
} from '@common/admin/file-entry/file-entry-upload-button/file-entry-upload-button.component';

@Component({
    selector: 'file-entry-index',
    templateUrl: './file-entry-index.component.html',
    styleUrls: ['./file-entry-index.component.scss'],
    providers: [Paginator],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FileEntryIndexComponent implements OnInit, OnDestroy {

    @Input() title;
    @Input() filterType?: FileEntryType;
    @Input() mode: 'view' | 'select' | 'manage' = 'view';
    @Input() multiselect = true;
    @Input() endpoint = 'uploads';
    @Input() uploadEnabled = false;
    @Input() ownershipType?: string;
    @Input() ownershipId?: string;
    @Input() deletePermission = 'files.delete';

    @Output() filesSelectionChange = new EventEmitter<SelectionModel<FileEntry>>();

    public ViewTypes = ViewTypes;

    @ViewChild(MatSort, {static: true}) matSort: MatSort;
    @ViewChild(FileEntryUploadButtonComponent, {static: true}) uploadButton: FileEntryUploadButtonComponent;

    public dataSource: PaginatedDataTableSource<FileEntry>;
    public columns: string[] = [];

    constructor(
        public paginator: Paginator<FileEntry>,
        public currentUser: CurrentUser,
        public settings: Settings,
        private uploads: UploadsApiService,
        private modal: Modal,
    ) {
    }

    ngOnInit() {
        this.dataSource = new PaginatedDataTableSource<FileEntry>({
            uri: this.endpoint,
            dataPaginator: this.paginator,
            matSort: this.matSort,
            filters: this.getFilters(),
            multiselect: this.mode === 'manage' ? true : this.multiselect,
            dontUpdateQueryParams: true,
        });

        this.columns = this.settings.getJson('table.files');

        this.dataSource.selectedRows.changed.subscribe((selectionChange: SelectionChange<FileEntry>) => {
            this.filesSelectionChange.emit(selectionChange.source);
        });
    }

    ngOnDestroy() {
        this.paginator.destroy();
    }

    private getFilters() {
        const filters = [
            ...FILE_ENTRY_INDEX_FILTERS,
        ];

        if (this.filterType != null) {
            const typeFilterIndex = filters.findIndex(f => f.column === 'type');
            if (typeFilterIndex !== -1) {
                const defaultValue = this.filterType;
                const defaultOption = filters[typeFilterIndex].options.find(option => option.value === defaultValue);

                if (defaultOption != null) {
                    filters[typeFilterIndex] = {
                        ...filters[typeFilterIndex],
                        options: [defaultOption],
                        defaultValue,
                    };
                }
            }
        }

        return filters;
    }

    /**
     * Delete currently selected entries.
     */
    public deleteSelectedEntries() {
        const entryIds = this.dataSource.getSelectedItems();
        this.uploads.delete({
            entryIds,
            deleteForever: true
        }, {endpoint: this.endpoint}).subscribe(() => {
            this.dataSource.reset();
        });
    }

    /**
     * Ask entry to confirm deletion of selected tags
     * and delete selected tags if entry confirms.
     */
    public maybeDeleteSelectedEntries() {
        this.modal.show(ConfirmModalComponent, {
            title: 'Delete Entries',
            body: 'Are you sure you want to delete selected entries?',
            ok: 'Delete'
        }).afterClosed().subscribe(confirmed => {
            if (!confirmed) return;
            this.deleteSelectedEntries();
        });
    }

    filesDropped(files?: File[]) {
        this.uploadButton.showUploadModal(files);
    }

    filesUploaded() {
        this.dataSource.reset();
    }

    canSelect(): boolean {
        return this.mode === 'select' || this.mode === 'manage';
    }

    canDelete() {
        return this.currentUser.hasPermission(this.deletePermission);
    }
}
