import {isNotBlank} from '../../../../shared/util/string-util';
import {BlockGridWidget, BlockGridWidgetItem} from './block-grid-widget.model';

export function generateBlockGridWidgetMarkup(widget: BlockGridWidget): string {
    const buildConfigHtml = (itemHoverOverlayColor, gridGap, linkIndicatorIconColor, linkIndicatorBackgroundColor) => {
        const dataAttrs = [
            `data-item-hover-overlay-color="${itemHoverOverlayColor ?? ''}"`,
            `data-grid-gap="${gridGap ?? ''}"`,
            `data-link-indicator-icon-color="${linkIndicatorIconColor ?? ''}"`,
            `data-link-indicator-background-color="${linkIndicatorBackgroundColor ?? ''}"`,
        ].join(' ');
        return `<div class="sb-block-grid-widget__config" ${dataAttrs}></div>`;
    };

    const elements = [];

    elements.push(buildConfigHtml(
        widget.itemHoverOverlayColor,
        widget.gridGap,
        widget.linkIndicatorIconColor,
        widget.linkIndicatorBackgroundColor,
    ));

    widget.items
        .map(item => generateBlockGridWidgetItemMarkup(item))
        .forEach(markMarkup => elements.push(markMarkup));

    return elements.join('\n');
}

export function generateBlockGridWidgetItemMarkup(item: BlockGridWidgetItem): string {
    const buildTitleHtml = (title) => `<h3 class="sb-block-grid-widget__item__title">${title ?? ''}</h3>`;
    const buildDescriptionHtml = (description) => `<p class="sb-block-grid-widget__item__description">${description ?? ''}</p>`;
    const buildImageHtml = (src, alt) => `<div class="sb-block-grid-widget__item__image" data-src="${src ?? ''}" data-alt="${alt ?? ''}"></div>`;

    const elements = [];

    elements.push(`<div class="sb-block-grid-widget__item" 
        data-enabled="${item.enabled ?? 'true'}"
        data-layout="${item.layout ?? '1_1'}"
        data-background-color="${item.backgroundColor ?? ''}"
        data-text-color="${item.textColor ?? ''}"
        data-href="${item.href ?? ''}"
        data-href-new-tab="${item.hrefNewTab ?? 'false'}"
        data-image-position="${item.imagePosition ?? ''}"
    >`);

    if (isNotBlank(item.title)) {
        elements.push(buildTitleHtml(item.title));
    }

    if (isNotBlank(item.description)) {
        elements.push(buildDescriptionHtml(item.description));
    }

    if (isNotBlank(item.imageSrc)) {
        elements.push(buildImageHtml(item.imageSrc, item.imageAlt));
    }

    elements.push(`</div>`);

    return elements.join('\n');
}

