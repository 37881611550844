import {Pipe, PipeTransform} from '@angular/core';
import {Agency} from '../builder-types';
import {getAgencyDisplayName} from './agencies.util';

@Pipe({
    name: 'agencyDisplayName'
})
export class AgencyDisplayNamePipe implements PipeTransform {

    transform(value: Agency): string {
        return getAgencyDisplayName(value);
    }

}
