<div class="options-container" [formGroup]="formGroup">
    <div class="option">
        <mat-checkbox formControlName="nofollow">
            nofollow
        </mat-checkbox>
        <mat-icon svgIcon="info"
                  [matTooltip]="'\'nofollow\' is an attribute that can be added to a weblink to instruct search engines not to follow that link and not to use it for evaluating the target website. It is commonly used to label untrusted links, paid advertisements, or user-generated content.' | trans"
        ></mat-icon>
    </div>
    <div class="option">
        <mat-checkbox formControlName="noreferrer">
            noreferrer
        </mat-checkbox>
        <mat-icon svgIcon="info"
                  [matTooltip]="'\'noreferrer\' is an attribute that is typically used together with the \'nofollow\' attribute. It prevents the referring link from including information about the original website when the user clicks on the link. This prevents the target website from receiving information such as the referrer header (the original URL from which the user came). It is often used to protect user privacy.' | trans"
        ></mat-icon>
    </div>
</div>
