import {addIdToNode} from './add-id-to-node';
import {removeIdFromNode} from './remove-id-from-node';

export function reassignNodeId(node: HTMLElement, recursive = false, updateMap: Map<string, string> = null): Map<string, string> {
    const changedMap = new Map<string, string>();

    const oldArId = node.dataset.arId;
    removeIdFromNode(node);
    if (updateMap != null && updateMap.has(oldArId)) {
        node.dataset.arId = updateMap.get(oldArId);
    } else {
        addIdToNode(node);
    }
    const newArId = node.dataset.arId;

    if (oldArId != null && !updateMap?.has(oldArId)) {
        changedMap.set(oldArId, newArId);
    }

    if (recursive) {
        for (let i = 0; i < node.children.length; i++) {
            const child = node.children[i] as HTMLElement;
            const childChangedMap = reassignNodeId(child, true, updateMap);
            childChangedMap.forEach((value, key) => {
                changedMap.set(key, value);
            });
        }
    }

    return changedMap;
}
