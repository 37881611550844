import {CONTENT_BLOCK_TYPE_EDITABLE, ContentBlock, ContentBlockUpdate} from '../../shared/builder-types';
import {DependencyQueue, DependencyQueueItem} from './dependency-queue';
import {ContentBlockApplier} from './content-block-applier';
import {ContentBlockInspector} from './content-block-inspector';
import {ContentBlockComparator} from './content-block-comparator';
import {getType} from './content-block-utils';

interface ContentBlockProcessorUpdateData {
    key: string;
    contentBlockId: number;
    content: string;
    isLocal: boolean;

    originalContent: string;
    type: string;
}

export class ContentBlockUpdateProcessor {

    private queue = new DependencyQueue<ContentBlockProcessorUpdateData>();
    private activeBlockKeys: string[] = [];   // for debugging purposes as member variable

    public createOrUpdateContentBlockUpdates(originalContentBlocks: ContentBlock[], contentBlockUpdates: ContentBlockUpdate[]): ContentBlockUpdate[] {
        this.prepare(originalContentBlocks, contentBlockUpdates);
        this.process();
        const updates = this.getUpdates(originalContentBlocks);
        return updates;
    }

    private prepare(originalContentBlocks: ContentBlock[], contentBlockUpdates: ContentBlockUpdate[]): void {
        // create a complete list of all content blocks
        const contentBlockProcessorUpdates: Map<string, ContentBlockProcessorUpdateData> = new Map();

        // filter inactive blocks
        const activeContentBlocks = originalContentBlocks.filter(contentBlock => {
            return true; // -> we only get active blocks from the backend
        });

        // get keys of all active/valid blocks
        this.activeBlockKeys = originalContentBlocks.map((contentBlock) => contentBlock.content_key);

        // transform current content blocks to updates (there wont be a change, if nothing changed)
        activeContentBlocks.forEach(contentBlock => {
            const key = contentBlock.content_key;
            const contentBlockId = contentBlock.content_block.id;
            const content = contentBlock.content;
            const originalContent = contentBlock.content;  // for debugging

            // resolve content of resolver content-blocks
            // -> only in backend

            contentBlockProcessorUpdates.set(key, {
                key,
                contentBlockId,
                content,
                isLocal: contentBlock.is_local,
                originalContent,
                type: getType(contentBlock),
            });
        });

        // override updated with current list
        contentBlockUpdates.forEach(contentBlockUpdate => {
            // only that exist and are active and are editable
            const originalContentBlockUpdate = contentBlockProcessorUpdates.get(contentBlockUpdate.content_key);
            if (originalContentBlockUpdate != null && originalContentBlockUpdate.type === CONTENT_BLOCK_TYPE_EDITABLE) {
                originalContentBlockUpdate.content = contentBlockUpdate.content;
            }
        });

        // fill update dependency queue
        contentBlockProcessorUpdates.forEach(contentBlockProcessorUpdate => {
            const key = contentBlockProcessorUpdate.key;
            const content = contentBlockProcessorUpdate.content;

            const dependencies = ContentBlockInspector.getOuterBlockKeys(content, this.activeBlockKeys);

            this.queue.add(key, dependencies, contentBlockProcessorUpdate);
        });
    }

    private process(): void {
        let queueItem: DependencyQueueItem<ContentBlockProcessorUpdateData>;
        // tslint:disable-next-line:no-conditional-assignment
        while (queueItem = this.queue.getNext()) {
            queueItem.getKey();
            queueItem.getDependencies().forEach(dependency => {
                const innerBlockUpdate = this.queue.getProcessedItem(dependency).getData();
                const updatedContent = ContentBlockApplier.applyBlockForHtml(queueItem.getData().content, innerBlockUpdate.key, innerBlockUpdate.content);
                this.queue.getOpenItem(queueItem.getKey()).getData().content = updatedContent;
            });
            this.queue.markProcessed(queueItem);
        }
    }

    private getUpdates(originalContentBlocks: ContentBlock[]): ContentBlockUpdate[] {
        return this.queue.getAllProcessed().map(queueItem => {
            return queueItem.getData();
        }).filter(processedContentBlock => {
            return processedContentBlock.type === CONTENT_BLOCK_TYPE_EDITABLE;
        }).filter(processedContentBlock => {
            const originalContentBlock = originalContentBlocks.find(cb => cb.content_key === processedContentBlock.key);
            return !ContentBlockComparator.equal(processedContentBlock.content, originalContentBlock.content, this.activeBlockKeys);
        }).map(processedContentBlock => {
            const update: ContentBlockUpdate = {
                content_key: processedContentBlock.key,
                content_block_id: processedContentBlock.contentBlockId,
                content: processedContentBlock.content,
                is_local: processedContentBlock.isLocal,
            };
            return update;
        });
    }

}
