import {Injectable} from '@angular/core';
import {MatDialogRef} from '@angular/material/dialog/dialog-ref';
import {Modal} from '@common/core/ui/dialogs/modal.service';
import {TemplateI18nModalComponent, TemplateI18nModalData} from '../../../../shared/templates/template-i18n-modal/template-i18n-modal.component';
import {BuilderStateService} from '../../../builder-state.service';
import {take} from 'rxjs/operators';

@Injectable({
    providedIn: 'root',
})
export class I18nWidgetService {
    public modalRef: MatDialogRef<TemplateI18nModalComponent>;

    constructor(
        public modal: Modal,
        private state: BuilderStateService,
    ) {
    }

    open(
        origin: HTMLElement,
        i18nKey: string,
    ): Promise<MatDialogRef<TemplateI18nModalComponent>> {
        return new Promise((resolve, reject) => {
            this.state.project$.pipe(take(1)).subscribe(project => {
                const data: TemplateI18nModalData = {
                    templateName: project.template.config.template_bundle,
                    currentLanguage: project.model.language,
                    searchTerm: i18nKey
                };
                this.modalRef = this.modal.open(TemplateI18nModalComponent, data);
                resolve(this.modalRef);
            });
        });
    }

    close() {
        this.modalRef?.close();
    }

}
