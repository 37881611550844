<div>
    <div class="modal-header">
        <h2 class="modal-title" mat-dialog-title trans>Testimonial </h2>
        <button type="button" (click)="close()" class="close-button no-style" tabindex="-1">
            <mat-icon svgIcon="close"></mat-icon>
        </button>
    </div>
    <loading-bar *ngIf="processing"></loading-bar>
</div>

<mat-dialog-content>
    <testimonial-widget-editor
            [testimonialWidget]="data.testimonialWidget"
            (processing)="processing = $event"
    ></testimonial-widget-editor>
</mat-dialog-content>

<mat-dialog-actions>
    <button type="button" (click)="addTestimonial()" mat-raised-button class="add-testimonial-button" [disabled]="processing" trans>
        <mat-icon svgIcon="add"></mat-icon>
        Add testimonial
    </button>
    <button type="button" (click)="close()" mat-button trans>Cancel</button>
    <button (click)="submit()" mat-raised-button color="accent" [disabled]="processing" trans>Apply</button>
</mat-dialog-actions>
