import {isNotBlank} from '../../../../shared/util/string-util';
import {SlideshowWidget} from './slideshow-widget.model';

export function generateSlideshowWidgetMarkup(slideshowWidget: SlideshowWidget): string {
    const buildConfigHtml = (interval, role, height) => `<div class="sb-slideshow-widget__config" data-interval="${interval ?? ''}" data-role="${role ?? ''}" data-height="${height ?? ''}"></div>`;
    const buildBgImageHtml = (src, alt, position, mobilePositionAbove) => `<img src="${src}" alt="${alt ?? ''}" class="sb-slideshow-widget__slide__bgImage img-fluid" data-position="${position ?? ''}" data-mobile-position-above="${mobilePositionAbove ?? true}">`;
    const buildTitleHtml = (title, color) => `<h2 class="sb-slideshow-widget__slide__title" data-color="${color ?? ''}">${title ?? ''}</h2>`;
    const buildTextHtml = (text, color) => `<p class="sb-slideshow-widget__slide__text" data-color="${color ?? ''}">${text ?? ''}</p>`;
    const getListItemHtml = (listItem) => `<li class="sb-slideshow-widget__slide__list-item">${listItem.text ?? ''}</li>`;
    const buildListHtml = (listItems, listStyle, color) => `<ul class="sb-slideshow-widget__slide__list" data-list-style="${listStyle ?? ''}" data-color="${color ?? ''}">${'\n' + (listItems ?? []).map(listItem => getListItemHtml(listItem) + '\n')}</ul>`;
    const buildButtonHtml = (label, src, target, bgColor, textColor) => `<a href="${src ?? ''}" class="btn btn-primary sb-slideshow-widget__slide__button" role="button" target="${target ?? ''}" data-bg-color="${bgColor ?? ''}" data-text-color="${textColor ?? ''}">${label ?? ''}</a>`;

    const elements = [];

    elements.push(buildConfigHtml(slideshowWidget.interval, slideshowWidget.role, slideshowWidget.height));

    (slideshowWidget.slides ?? []).forEach(slide => {

        let hrefData = '';
        if (slide.hrefEnabled) {
            hrefData = `data-href="${slide.href ?? ''}" data-href-target="${slide.hrefTarget ?? ''}"`;
        }

        elements.push(`<div class="sb-slideshow-widget__slide" data-id="${slide.id ?? ''}" data-enabled="${slide.enabled ?? 'true'}" data-layout="${slide.layout ?? ''}" data-theme="${slide.theme ?? ''}" data-townsign-visible="${slide.townsignVisible ?? false}" data-townsign-mobile-visible="${slide.townsignMobileVisible ?? true}" data-bg-color="${slide.bgColor ?? ''}" data-content-bg-color="${slide.contentBgColor ?? ''}" ${hrefData}>`);

        if (isNotBlank(slide.bgImageSrc)) {
            elements.push(buildBgImageHtml(slide.bgImageSrc, slide.bgImageAlt, slide.bgPosition, slide.bgImageMobilePositionAbove));
        }
        if (slide.titleEnabled) {
            elements.push(buildTitleHtml(slide.title, slide.titleColor));
        }
        if (slide.textEnabled) {
            elements.push(buildTextHtml(slide.text, slide.textColor));
        }
        if (slide.listEnabled) {
            elements.push(buildListHtml(slide.listItems, slide.listStyle, slide.listColor));
        }
        if (slide.buttonEnabled) {
            elements.push(buildButtonHtml(slide.buttonLabel, slide.buttonUrl, slide.buttonTarget, slide.buttonBgColor, slide.buttonTextColor));
        }

        elements.push(`</div>`);
    });

    return elements.join('\n');
}
