import {Injectable} from '@angular/core';
import {CurrentUser} from '@common/auth/current-user';
import {BehaviorSubject, Observable} from 'rxjs';
import {map, switchMap} from 'rxjs/operators';
import {UserSettings} from '@common/auth/user-settings.api.service';

@Injectable({
    providedIn: 'root',
})
export class UserSettingsService {
    private settings$: BehaviorSubject<object> = new BehaviorSubject({});

    constructor(
        private user: CurrentUser,
        private userSettingsApi: UserSettings
    ) {
        user.model$
            .pipe(switchMap(it => userSettingsApi.get(it.id)))
            .pipe(map(it => it.settings))
            .subscribe(it => this.settings$.next(it));
    }

    public get(settingName: string): Observable<any> {
        return this.settings$
            .pipe(map(it => it[settingName]));
    }

    public save(settingName: string, newValue: any): void {
        const currentSettings = this.settings$.getValue();
        currentSettings[settingName] = newValue;
        this.settings$.next(currentSettings);

        this.userSettingsApi.update(this.user.model$.value.id, currentSettings).subscribe();
    }
}
