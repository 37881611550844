import {Injectable} from '@angular/core';
import * as ArchitectElements from './definitions/all';

@Injectable({
    providedIn: 'root',
})
export class DefinedElementsService {

    /**
     * Get all defined elements
     */
    public getAll() {
        return Object.values(ArchitectElements);
    }

}
