import {Component, Inject, OnInit, Optional} from '@angular/core';
import {BuilderStateService} from '../../../../builder-state.service';
import {GalleryWidget, GalleryWidgetItem} from '../gallery-widget.model';
import {CdkDragDrop, moveItemInArray} from '@angular/cdk/drag-drop';
import {ImageElementService} from '../../../image-element.service';
import {ProjectUrl} from '../../../../../shared/projects/project-url.service';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';

export interface GalleryWidgetEditorModalComponentData {
    widget: GalleryWidget;
}

@Component({
    selector: 'gallery-widget-editor-modal',
    templateUrl: './gallery-widget-editor-modal.component.html',
    styleUrls: ['./gallery-widget-editor-modal.component.scss'],
})
export class GalleryWidgetEditorModalComponent implements OnInit {

    loading = false;

    constructor(
        private projectUrl: ProjectUrl,
        public state: BuilderStateService,
        public imageElementService: ImageElementService,
        @Inject(MAT_DIALOG_DATA)
        @Optional()
        public data: GalleryWidgetEditorModalComponentData,
        @Inject(MatDialogRef) @Optional() public modalRef: MatDialogRef<GalleryWidgetEditorModalComponent>
    ) {
    }

    ngOnInit() {
    }

    submit() {
        this.close(this.data.widget);
    }

    close(value?: GalleryWidget) {
        this.modalRef.close(value);
    }

    drop(event: CdkDragDrop<string[]>) {
        moveItemInArray(this.data.widget.items, event.previousIndex, event.currentIndex);
    }

    getImageUrl(item: GalleryWidgetItem) {
        return `${this.projectUrl.getBaseUrl(this.state.project$.value.model)}/${item.url}`;
    }

    updateItemImage(item: GalleryWidgetItem) {
        this.loading = true;
        this.imageElementService.openModal().subscribe((url: string) => {
            this.loading = false;

            if (!url) return;

            item.url = url;
        });
    }

    addItem() {
        this.loading = true;
        this.imageElementService.openModalMultiSelect().subscribe((data: string[]) => {
            this.loading = false;

            if (!data) return;

            for (const src of data) {
                const newItem = new GalleryWidgetItem(src);
                this.data.widget.items.push(newItem);
            }
        });
    }

    removeItem(itemIndex: number) {
        this.data.widget.items.splice(itemIndex, 1);
    }
}
