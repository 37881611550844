import {
    ChangeDetectionStrategy,
    Component,
    EventEmitter,
    Input,
    Output
} from '@angular/core';
import {
    FileEntryUploadModalComponent,
    FileEntryUploadModalData
} from '@common/admin/file-entry/file-entry-upload-modal/file-entry-upload-modal.component';
import {Modal} from '@common/core/ui/dialogs/modal.service';
import {AgenciesApi} from '../../../../app/shared/agencies/agencies.service';
import {CurrentUser} from '@common/auth/current-user';
import {Projects} from '../../../../app/shared/projects/projects.service';
import {FileEntry, FileEntryType} from '@common/uploads/types/file-entry';

@Component({
    selector: 'file-entry-upload-button',
    templateUrl: './file-entry-upload-button.component.html',
    styleUrls: ['./file-entry-upload-button.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class FileEntryUploadButtonComponent {

    @Input() acceptType?: FileEntryType;
    @Input() ownershipType?: string;
    @Input() ownershipId?: string;
    @Input() endpoint?: string;

    @Output() filesUploaded = new EventEmitter<FileEntry[] | undefined>();

    constructor(
        private modal: Modal,
        public agencies: AgenciesApi,
        public projects: Projects,
        public currentUser: CurrentUser,
    ) {
    }

    showUploadModal(files?: File[]): void {
        this.modal.show(FileEntryUploadModalComponent, {
            files,
            endpoint: this.endpoint,
            acceptType: this.acceptType,
            ownershipType: this.ownershipType,
            ownershipId: this.ownershipId,
        } as FileEntryUploadModalData).afterClosed().subscribe((data?: FileEntry[]) => {
            if (!data) return;
            this.filesUploaded.emit(data);
        });
    }
}
