import {randomString} from '@common/core/utils/random-string';

export class SlideshowWidget {
    public slides: SlideshowWidgetSlide[] = [];
    public role: 'header' | 'other' = 'other';
    public interval?: number;
    public height?: number;
}

export class SlideshowWidgetSlide {
    public id: string = randomString(8);
    public enabled = true;
    public hrefEnabled = false;
    public href = '';
    public hrefTarget = '_blank';
    public bgColor?: string;
    public bgImageSrc?: string;
    public bgImageAlt?: string;
    public bgPosition = 'center center';
    public bgImageMobilePositionAbove = false;
    public contentBgColor?: string;
    public titleEnabled = false;
    public title = '';
    public titleColor?: string;
    public textEnabled = false;
    public text = '';
    public textColor?: string;
    public buttonEnabled = false;
    public buttonLabel = '';
    public buttonUrl = '';
    public buttonTarget = '';
    public buttonBgColor?: string;
    public buttonTextColor?: string;
    public listEnabled = false;
    public listStyle = 'checkmark';
    public listItems: { text: string }[] = [];
    public listColor?: string;
    public position: number | string = 'last';
    public layout: 'top-left' | 'top-center' | 'top-right' | 'middle-left' | 'middle-center' | 'middle-right' | 'bottom-left' | 'bottom-center' | 'bottom-right' | 'person_pointing' = 'top-left';
    public theme: 'light' | 'dark' = 'light';
    public townsignVisible = false;
    public townsignMobileVisible = true;
}

/**
 * Duplicates a slide
 * @param slide The slide to duplicate
 * @returns The duplicated slide
 */
export function duplicateSlide(slide: SlideshowWidgetSlide): SlideshowWidgetSlide {
    return {
        ...slide,
        listItems: [
            ...slide.listItems,
        ],
    };
}
