<form autocomplete="off" (ngSubmit)="confirm()" ngNativeValidate>
    <div class="many-inputs mat-dialog-content" mat-dialog-content>

        <div class="input-container">
            <file-chooser-control name="name" [files]="files" [acceptType]="acceptType"
                                  (filesChange)="setFiles($event)"></file-chooser-control>
            <div class="error" *ngIf="errors?.file">{{errors.file}}</div>
        </div>
        <div class="input-container" *ngIf="!ownershipSpecified">
            <label for="ownership">Ownership</label>
            <select id="ownership" name="ownership" [ngModel]="selectedOwnershipOption"
                    (ngModelChange)="ownershipChanged($event)">
                <ng-container *ngFor="let ownershipOption of ownershipOptions$ | async"
                              [ngSwitch]="ownershipOption">
                    <option *ngSwitchCase="null" [ngValue]="null" disabled>----------
                    </option>
                    <option *ngSwitchDefault [ngValue]="ownershipOption" trans>
                        {{ownershipOption.label}}
                    </option>
                </ng-container>
            </select>
            <div class="error"
                 *ngIf="errors?.ownershipType">{{errors.ownershipType}}</div>
            <div class="error" *ngIf="errors?.ownershipId">{{errors.ownershipId}}</div>
        </div>

    </div>

    <div class="buttons right mat-dialog-actions" mat-dialog-actions>
        <button type="submit" class="button primary" mat-raised-button color="accent"
                [disabled]="!canUpload()">
            <span trans>Upload</span>
        </button>
    </div>
</form>
