<div>
    <div class="modal-header">
        <h2 class="modal-title" mat-dialog-title trans>Slideshow </h2>
        <button type="button" (click)="close()" class="close-button no-style" tabindex="-1">
            <mat-icon svgIcon="close"></mat-icon>
        </button>
    </div>
    <loading-bar *ngIf="processing"></loading-bar>
</div>

<mat-dialog-content>
    <slideshow-widget-editor
            [slideshowWidget]="data.slideshowWidget"
            (processing)="processing = $event"
            [slideImgUrlTransformerFn]="slideImgUrlTransformerFn"
            [positionEditable]="false"
    ></slideshow-widget-editor>
</mat-dialog-content>

<mat-dialog-actions>
    <button type="button" (click)="addSlide()" mat-raised-button class="add-slide-button" [disabled]="processing" trans>
        <mat-icon svgIcon="add"></mat-icon>
        Add slide
    </button>
    <button type="button" (click)="close()" mat-button trans>Cancel</button>
    <button (click)="submit()" mat-raised-button color="accent" [disabled]="processing" trans>Apply</button>
</mat-dialog-actions>
