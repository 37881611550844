import {ChangeDetectionStrategy, Component, EventEmitter, Input, OnChanges, Output, SimpleChanges} from '@angular/core';

interface Breadcrumb {
    icon?: string;
    label?: string;
    path: string;
}

@Component({
    selector: 'media-grid-breadcrumbs',
    templateUrl: './media-grid-breadcrumbs.component.html',
    styleUrls: ['./media-grid-breadcrumbs.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MediaGridBreadcrumbsComponent implements OnChanges {
    @Input() path: string;
    @Output() pathClick = new EventEmitter<string>();

    breadcrumbs: Breadcrumb[] = [];

    onClick(breadcrumb: Breadcrumb) {
        this.pathClick.emit(breadcrumb.path);
    }

    ngOnChanges(changes: SimpleChanges): void {
        let pathParts = this.path.split('/');
        pathParts = pathParts.slice(0, pathParts.length - 1);
        this.breadcrumbs = pathParts.map((pathPart, i) => {
            const breadcrumbPath = pathParts.slice(0, i + 1).join('/') + '/';
            return {
                icon: i === 0 ? 'home' : undefined,
                label: i === 0 ? undefined : pathPart,
                path: breadcrumbPath,
            };
        });
    }
}
