import {Component, EventEmitter, Input, Output} from '@angular/core';

@Component({
    selector: 'media-grid-folder',
    templateUrl: './media-grid-folder.component.html',
    styleUrls: ['./media-grid-folder.component.scss']
})
export class MediaGridFolderComponent {
    @Input() folderName: string;

    @Output() folderClick = new EventEmitter<void>();

    onClick() {
        this.folderClick.emit();
    }
}
