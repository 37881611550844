import {Injectable} from '@angular/core';
import {NotificationModalModel} from './notification-modal.model';
import {InsertScript} from '../../../../../html-builder/mutations/dom/insert-script';
import {getNodeId} from '../../../../../html-builder/utils/get-node-id';
import {BaseMutation} from '../../../../../html-builder/mutations/base-mutation';
import {InsertNode} from '../../../../../html-builder/mutations/dom/insert-node';
import {ReplaceNodeContent} from '../../../../../html-builder/mutations/dom/replace-node-content';
import {ModifyAttributes} from '../../../../../html-builder/mutations/attributes/modify-attributes';
import {BuilderStateService} from '../../../../../html-builder/builder-state.service';
import {MutationsService} from '../../../../../html-builder/mutations/mutations.service';
import {stringToBoolean} from '@common/core/utils/string-to-boolean';

@Injectable({
    providedIn: 'root',
})
export class NotificationModalService {
    constructor(
        private state: BuilderStateService,
        private mutations: MutationsService
    ) {}

    public isModalCurrentlyShowing(): boolean {
        const enabled = stringToBoolean(this.getExistingHtmlElement()?.dataset?.enabled, false);

        if (!enabled) {
            return false;
        }

        const showUntil = this.getExistingHtmlElement()?.dataset?.showUntil;

        return Date.parse(showUntil) > Date.now();
    }

    public save(model: NotificationModalModel): void {
        this.insertScript(model);
        this.insertHtml(model);
    }

    private insertScript(model: NotificationModalModel): boolean {
        const mutation = new InsertScript(this.buildScript(model), 'vacation-modal-script');
        return this.mutations.execute(mutation, {skipUndoStack: true});
    }

    private buildScript(model: NotificationModalModel): string {
        if (model.enabled) {
            return `
                $(document).ready(function() {
                    function isInIframe() {
                        try {
                            return window.self !== window.top;
                        } catch (e) {
                            return true;
                        }
                    }
                
                    function isInBuilder() {
                        return isInIframe();
                    }
                
                    if (!isInBuilder()) {
                        var currentDate = new Date();
                        var targetDate = new Date('${model.showUntil}');
                        if (currentDate < targetDate) {
                            $('#vacation-modal').modal('show');
                        }
                    }
                });`;
        } else {
            return '';
        }
    }

    private insertHtml(model: NotificationModalModel): void {
        const parentId = getNodeId(this.state.previewDoc.body);
        const existingContainerNode = this.getExistingHtmlElement();

        const mutations: BaseMutation[] = [];
        if (this.getExistingHtmlElement() == null) {
            mutations.push(new InsertNode(this.buildHtmlElement(model), 0, parentId));
        } else {
            mutations.push(new ReplaceNodeContent(existingContainerNode, undefined, this.buildHtmlString(model)));
            mutations.push(new ModifyAttributes(existingContainerNode, this.buildDataset(model)));
        }

        mutations.forEach(mutation => this.mutations.execute(mutation, {skipUndoStack: true}));
    }

    public getExistingHtmlElement(): HTMLElement | null {
        return this.state.previewDoc?.body?.querySelector('#vacation-modal-container') as HTMLElement;
    }

    private buildHtmlElement(model: NotificationModalModel): HTMLElement {
        const element = document.createElement('div');

        element.id = 'vacation-modal-container';
        element.innerHTML = this.buildHtmlString(model);

        element.dataset.enabled = this.booleanAsString(model.enabled);
        element.dataset.showUntil = model.showUntil;
        element.dataset.imageSource = model.imageSource;
        element.dataset.imageAlt = model.imageAlt;

        return element;
    }

    private buildDataset(model: NotificationModalModel): Record<string, string> {
        return {
            'data-enabled': this.booleanAsString(model.enabled),
            'data-show-until': model.showUntil,
            'data-image-source': model.imageSource,
            'data-image-alt': model.imageAlt,
        };
    }

    private buildHtmlString(model: NotificationModalModel): string {
        let imageHtml = '';
        if (model.imageSource !== null && model.imageSource !== undefined) {
            imageHtml = `<figure><img src="${model.imageSource}" alt="${model.imageAlt}" class="img-fluid"></figure>`;
        }

        return `<div class="modal fade" id="vacation-modal" data-backdrop="static" data-keyboard="false" tabindex="-1" role="dialog" style="display: none;" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered">
            <div class="modal-content">

                ${imageHtml}

                <div class="modal-body">
                    <div class="row">
                        <div id="vacation-modal-text" class="mb-4 mt-1 col-md-12">
                            ${model.text}
                        </div>
                    </div>

                    <button type="button" class="btn btn-primary_custom" data-dismiss="modal" data-bs-dismiss="modal" aria-label="Close">Ok</button>
                </div>
            </div>
        </div>
    </div>`;
    }

    private booleanAsString(value: boolean): string {
        return value ? 'true' : 'false';
    }
}
