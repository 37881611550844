export function anyChildNodeContainsComment(rootNode: HTMLElement, comment: string) {
    function filterNone() {
        return NodeFilter.FILTER_ACCEPT;
    }

    // @ts-ignore Fourth argument, which is actually obsolete according to the DOM4 standard, is required in IE 11
    const iterator = document.createNodeIterator(rootNode, NodeFilter.SHOW_COMMENT, filterNone, false);
    let curNode;
    while (curNode = iterator.nextNode()) {
        if ((curNode.nodeValue ?? '').includes(comment)) {
            return true;
        }
    }
    return false;
}

export function nodeOrAnyChildNodeContainsCssClass(node: HTMLElement, cssClass: string) {
    return node.classList.contains(cssClass) || anyChildNodeContainsCssClass(node, cssClass);
}

export function anyChildNodeContainsCssClass(rootNode: HTMLElement, cssClass: string) {
    function filterDiv(node: HTMLElement) {
        return node.nodeName.toLowerCase() === 'div' ? NodeFilter.FILTER_ACCEPT : NodeFilter.FILTER_REJECT;
    }

    // @ts-ignore Fourth argument, which is actually obsolete according to the DOM4 standard, is required in IE 11
    const iterator = document.createNodeIterator(rootNode, NodeFilter.SHOW_ELEMENT, filterDiv, false);
    let curNode: Node;
    while (curNode = iterator.nextNode()) {
        if (curNode instanceof HTMLElement) {
            if (curNode.classList.contains(cssClass)) {
                return true;
            }
        }
    }
    return false;
}

export function getNodesBetweenCommentBlock(rootNode: HTMLElement, commentStart: string, commentEnd: string): HTMLElement[] {
    function filterNone() {
        return NodeFilter.FILTER_ACCEPT;
    }

    const nodes = [];
    let foundStartCommentNode: any;

    // @ts-ignore Fourth argument, which is actually obsolete according to the DOM4 standard, is required in IE 11
    const iterator = document.createNodeIterator(rootNode, NodeFilter.SHOW_COMMENT, filterNone, false);
    let curNode;
    while (curNode = iterator.nextNode()) {
        if ((curNode.nodeValue ?? '').includes(commentStart)) {
            foundStartCommentNode = curNode;
            break;
        }
    }

    if (foundStartCommentNode != null) {
        let node = foundStartCommentNode.nextSibling;
        while (node != null) {
            if (node.nodeType === Node.ELEMENT_NODE) {
                nodes.push(node);
            } else if (node.nodeType === Node.COMMENT_NODE && (node.nodeValue ?? '').includes(commentEnd)) {
                break;
            }
            node = node.nextSibling;
        }
    }

    return nodes;
}
