<ng-template #selectTpl>
    <mat-checkbox (click)="$event.stopPropagation()"
                  (change)="onSelectionChange()"
                  [checked]="selected">
    </mat-checkbox>
</ng-template>

<div class="grid-item"
     title="{{entry.name}}{{entry.created_at != null ? '  •  ' + (entry.created_at! | formattedDateTime) : ''}}{{entry.file_size != null ? '  •  ' + (entry.file_size! | formattedFileSize) : ''}}">
    <div class="grid-item-selector" *ngIf="selectable">
        <div *ngTemplateOutlet="selectTpl"></div>
    </div>
    <div class="grid-item-content">
        <ng-container *ngIf="entry.type !== 'image'">
            <mat-icon svgIcon="file"></mat-icon>
            <div class="grid-item-name">{{entry.name}}</div>
        </ng-container>
        <ng-container *ngIf="entry.type === 'image'">
            <span class="grid-item-image-dimensions"><img-dimensions [src]="entry.url"></img-dimensions></span>
            <img class="grid-item-image" src="{{entry.url}}"
                 loading="lazy"
                 alt="{{entry.name}}">
        </ng-container>
        <a mat-flat-button mat-icon-button [href]="entry.url"
           target="_blank" class="open-button">
            <mat-icon svgIcon="open-in-new"></mat-icon>
        </a>
    </div>
</div>
