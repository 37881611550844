<div class="trigger" #trigger
     [colorPicker]="color"
     [cpAlphaChannel]="'always'"
     [cpOutputFormat]="'rgba'"
     (colorPickerChange)="emitSelectedEvent($event)"
     [cpDialogDisplay]="'inline'"
     [cpAddColorButton]="true"
     [cpPresetColors]="presetsService.colors$ | async"
     (cpPresetColorsChange)="onPresetsChanged($event)"
     [cpToggle]="false"
     [cpAddColorButtonText]="'Save color' | trans"
     [cpPresetEmptyMessage]="'No colors saved' | trans"
     [cpPresetLabel]="'Saved colors' | trans"
></div>