import {Component, ElementRef, Inject, OnDestroy, OnInit, Optional, ViewChild} from '@angular/core';
import {aceThemes} from './ace-themes';
import {LazyLoaderService} from '@common/core/utils/lazy-loader.service';
import {OverlayPanelRef} from '@common/core/ui/overlay-panel/overlay-panel-ref';
import {BuilderStateService} from '../../builder-state.service';
import {BehaviorSubject} from 'rxjs';
import {randomString} from '@common/core/utils/random-string';
import {createDocFromHtml} from '../../utils/create-doc-from-html';
import {ContentBlockService} from '../../content-blocks/content-block.service';
import {ActiveProject} from '../../projects/active-project';

declare let ace: any;
// tslint:disable-next-line:ban-types
declare let html_beautify: Function;

@Component({
    selector: 'code-editor',
    templateUrl: './code-editor.component.html',
    styleUrls: ['./code-editor.component.scss'],
})
export class CodeEditorComponent implements OnInit, OnDestroy {
    @ViewChild('editor', {static: true}) editorEl: ElementRef;

    public loading$ = new BehaviorSubject(false);
    public initialized$ = new BehaviorSubject(false);

    private editor;
    activeEditorTheme = 'chrome';
    editorThemes = aceThemes;
    private activeEditor: 'css' | 'js' | 'html' = 'html';

    private oldHtml = '';

    private content = {
        css: '',
        js: '',
        html: '',
    };

    constructor(
        private lazyLoader: LazyLoaderService,
        private state: BuilderStateService,
        private activeProject: ActiveProject,
        private contentBlockService: ContentBlockService,
        @Inject(OverlayPanelRef) @Optional() public overlayRef: OverlayPanelRef
    ) {
    }

    ngOnInit() {
        this.initEditor().then(() => {
            this.content.css = this.state.project$.value.css;
            this.content.js = this.state.project$.value.js;
            this.updateEditorContents(this.activeEditor);
            this.initialized$.next(true);
        });
    }

    ngOnDestroy() {
        if (this.editor != null) {
            this.editor.destroy();
        }
    }

    useTheme(name: string) {
        this.editor.setTheme('ace/theme/' + name);
    }

    switchType(name: 'css' | 'js' | 'html') {
        this.activeEditor = name;
        this.changeEditorMode(name);
        this.updateEditorContents(name);
    }

    /**
     * Select source code of specified node in code editor.
     */
    public selectNodeSource(node: HTMLElement) {
        console.log(html_beautify(node.outerHTML));
        this.editor.find(html_beautify(node.outerHTML), {}, true);
    }

    private updateEditorContents(type: 'css' | 'js' | 'html') {
        if (type === 'html') {
            const page = this.state.pages$.value.find(curr => curr.id === this.state.activePage$.value.id);
            this.setEditorValue(html_beautify(page.doc ? page.doc.documentElement.outerHTML : page.html));
        } else if (type === 'css') {
            this.setEditorValue(this.state.project$.value.css);
        } else if (type === 'js') {
            this.setEditorValue(this.state.project$.value.js);
        }
    }

    private setEditorValue(value: string) {
        if (this.editor && this.editor.getValue() !== value) {
            this.editor.setValue(value, -1);
        }
    }

    activeTypeIs(name: 'css' | 'js' | 'html') {
        return this.activeEditor === name;
    }

    closeEditor() {
        this.overlayRef.close();
    }

    private initEditor(language: 'js' | 'css' | 'html' = 'html') {
        this.loading$.next(true);
        return Promise.all([
            this.lazyLoader.loadAsset('js/ace/ace.js', {type: 'js'}),
            this.lazyLoader.loadAsset('js/beautify-html.js', {type: 'js'}),
        ]).then(() => {
            this.editor = ace.edit(this.editorEl.nativeElement);
            this.changeEditorMode(language);
            this.useTheme('chrome');
            this.editor.$blockScrolling = Infinity;
            this.loading$.next(false);

            this.editor.on('change', () => {
                this.content[this.activeEditor] = this.editor.getValue();
            });
        });
    }

    private changeEditorMode(mode: 'js' | 'css' | 'html') {
        mode = mode === 'js' ? 'javascript' : (mode as any);
        if (this.editor) {
            this.editor.getSession().setMode('ace/mode/' + mode);
        }
    }

    async saveCustomCode() {
        this.loading$.next(true);

        const page = this.state.activePage$.value;
        page.html = this.content.html;
        page.doc = createDocFromHtml(page.html);
        this.contentBlockService.checkContentBlocks();
        this.state.activePage$.next(page);

        const project = this.state.project$.value;
        if (project.css !== this.content.css || project.js !== this.content.js) {
            await this.activeProject.save({
                thumbnail: false,
                payload: {
                    css: this.content.css,
                    js: this.content.js,
                }
            });
            this.reloadAssetsInBuilder();
        }

        this.loading$.next(false);
        this.closeEditor();
    }

    private reloadAssetsInBuilder() {
        const cssEl = this.state.previewDoc.querySelector(
            '#custom-css'
        ) as HTMLLinkElement;
        if (cssEl != null) {
            const cssUrl = new URL(cssEl.href);
            cssEl.href = `${cssUrl.origin}${cssUrl.pathname}?${randomString(8)}`;
        }

        const jsEl = this.state.previewDoc.querySelector(
            '#custom-js'
        ) as HTMLScriptElement;
        if (jsEl != null) {
            const jsUrl = new URL(jsEl.src);
            jsEl.src = `${jsUrl.origin}${jsUrl.pathname}?${randomString(8)}`;
        }
    }
}
