import {ArchitectElControl, ArchitectElement, ElControlType, } from '../../architect-element';
import {MutationsService} from '../../../mutations/mutations.service';
import {ReplaceNodeContent} from '../../../mutations/dom/replace-node-content';
import {ModifyAttributes} from '../../../mutations/attributes/modify-attributes';
import {GalleryWidget} from './gallery-widget.model';
import {generateGalleryWidgetMarkup} from './gallery-widget-markup-generator';
import {extractGalleryWidget} from './gallery-widget-markup-extractor';
import {GalleryWidgetEditor} from './gallery-widget-editor.service';

const template = `<div class="sb-gallery-widget">
<a class="sb-gallery-widget__item" href="images/placeholder/300x300.png"><img class="img-fluid" src="images/placeholder/300x300.png" alt="Image Alt Text"></a>
</div>`;

export class GalleryWidgetEl extends ArchitectElement {
    name = 'Image gallery';
    contentCategories = ['flow'];
    html = template;
    allowedContent = [];
    category = 'components';
    icon = 'photo-size-select-large';
    specificity = 5;
    hiddenClasses = ['sb-gallery-widget'];
    controls = [
        new ArchitectElControl(this.injector, {
            label: 'Image Gallery: Row height',
            type: ElControlType.Input,
            inputType: 'number',
            defaultValue(node: HTMLInputElement) {
                return node.getAttribute('data-row-height') || '160';
            },
            onChange(node: HTMLElement, value: string) {
                this.get(MutationsService).execute(
                    new ModifyAttributes(node, {
                        'data-row-height': value,
                    })
                );
            },
        }),
        new ArchitectElControl(this.injector, {
            label: 'Image Gallery: Max row height',
            type: ElControlType.Input,
            inputType: 'number',
            defaultValue(node: HTMLInputElement) {
                return node.getAttribute('data-max-row-height') || '250';
            },
            onChange(node: HTMLElement, value: string) {
                this.get(MutationsService).execute(
                    new ModifyAttributes(node, {
                        'data-max-row-height': value,
                    })
                );
            },
        }),
    ];
    editActions = [
        {
            name: 'Edit',
            onClick: (node: HTMLElement) => {
                const widget = extractGalleryWidget(node);
                this.get(GalleryWidgetEditor)
                    .open(node, widget)
                    .afterClosed()
                    .subscribe((updatedWidget?: GalleryWidget) => {
                        if (updatedWidget != null) {
                            const newInnerHTML = generateGalleryWidgetMarkup(updatedWidget);
                            this.get(MutationsService).execute(
                                new ReplaceNodeContent(node, node.innerHTML, newInnerHTML)
                            );
                        }
                    });
            },
        },
    ];

    matcher(node: HTMLElement) {
        return node.closest('.sb-gallery-widget') as HTMLElement;
    }
}
