type cleanupParagraphsValueT = string | null | undefined;

/**
 * This function is used to cleanup the paragraphs, because ckeditor adds them automatically.
 * https://github.com/ckeditor/ckeditor5/issues/1537
 * https://github.com/ckeditor/ckeditor5/issues/617#issuecomment-337650735
 * https://ckeditor.com/docs/ckeditor5/latest/updating/ckeditor4/ckeditor4-configuration-compatibility.html
 * "CKEditor 5 always creates a new paragraph (<p> element) as specified by Editor Recommendations - Enter key."
 * @param value value to cleanup
 */
export function cleanupParagraphs(value: string): string;
export function cleanupParagraphs(value: null): null;
export function cleanupParagraphs(value: undefined): undefined;
export function cleanupParagraphs(value: string | undefined | null): string | undefined | null {
    if (value === undefined) {
        return undefined;
    }

    if (value === null) {
        return null;
    }

    return value
        .replace(new RegExp(/<\/p><p.*?>/g), '<br>')
        .replace(new RegExp(/<p.*?>/g), '')
        .replace(new RegExp(/<\/p>/g), '');
}
