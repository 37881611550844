import {Injectable} from '@angular/core';
import {AppHttpClient} from '@common/core/http/app-http-client.service';
import {PaginatedBackendResponse} from '@common/core/types/pagination/paginated-backend-response';
import {BackendResponse} from '@common/core/types/backend-response';
import {ProjectBundle} from './ProjectBundle';
import {BuilderProjectBundleInfo} from '../builder-types';

@Injectable({
    providedIn: 'root'
})
export class ProjectBundles {
    static BASE_URI = 'project-bundles';

    constructor(private http: AppHttpClient) {
    }

    list(params?: { user_id?: number, per_page?: number }): PaginatedBackendResponse<ProjectBundle> {
        return this.http.get(ProjectBundles.BASE_URI, params);
    }

    get(id: number): BackendResponse<BuilderProjectBundleInfo> {
        return this.http.get(`${ProjectBundles.BASE_URI}/${id}`);
    }

    create(params: object): BackendResponse<{projectBundle: ProjectBundle}> {
        return this.http.post(ProjectBundles.BASE_URI, params);
    }

    public update(id: number, params: object): BackendResponse<{projectBundle: ProjectBundle}> {
        return this.http.put(`${ProjectBundles.BASE_URI}/${id}`, params);
    }

    public delete(params: {ids: number[]}): BackendResponse<void> {
        return this.http.delete(`${ProjectBundles.BASE_URI}`, params);
    }

    public updatePageMappings(id: number, params: object): BackendResponse<{projectBundle: ProjectBundle}> {
        return this.http.put(`${ProjectBundles.BASE_URI}/${id}/pageMappings`, params);
    }
}
