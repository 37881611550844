/**
 * Generate a random string with a given length. The default length is 36.
 * @param length The length of the random string to generate
 */
export function randomString(length: number = 36) {
    let random = '';
    const possible = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';

    for (let i = 0; i < length; i++) {
        random += possible.charAt(Math.floor(Math.random() * possible.length));
    }

    return random;
}
