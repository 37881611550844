<div class="modal-header">
    <h2 class="modal-title" mat-dialog-title trans>Media upload</h2>

    <button type="button" (click)="close()" class="close-button no-style icon-button">
        <mat-icon svgIcon="close"></mat-icon>
    </button>
</div>

<file-entry-upload
    [files]="data.files"
    [endpoint]="data.endpoint"
    [acceptType]="data.acceptType"
    (filesUploaded)="filesUploaded($event)"
    [ownershipType]="data.ownershipType"
    [ownershipId]="data.ownershipId"
></file-entry-upload>
