import {Pipe, PipeTransform} from '@angular/core';
import {Project} from './Project';
import {getProjectDisplayName} from './projects.util';

@Pipe({
    name: 'projectDisplayName'
})
export class ProjectDisplayNamePipe implements PipeTransform {

    transform(value: Project): string {
        return getProjectDisplayName(value);
    }

}
