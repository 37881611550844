import {Injectable, NgZone} from '@angular/core';
import {FileEntry} from '@common/uploads/types/file-entry';
import {MediaSelectorService} from '@common/media/media-selector.service';
import {EdContentAiApi} from '../../../app/shared/edcontentai/ed-content-ai-api.service';
import {Settings} from '@common/core/config/settings.service';
import {CurrentUser} from '@common/auth/current-user';

export interface AngularBridge {
    openMediaSelector: (callbackFn: (media?: FileEntry) => void) => void;

    openEdContentAi(): void;

    isEdContentAiButtonEnabled(): boolean;
}

@Injectable({
    providedIn: 'root',
})
export class AngularBridgeService {

    constructor(
        private zone: NgZone,
        private mediaSelectorService: MediaSelectorService,
        private settings: Settings,
        private currentUser: CurrentUser,
        private edContentAiApi: EdContentAiApi,
    ) {
    }

    init(): void {
        // @ts-ignore
        window.angularBridge = {
            openMediaSelector: this.openMediaSelector.bind(this),
            openEdContentAi: this.openEdContentAi.bind(this),
            isEdContentAiButtonEnabled: this.isEdContentAiButtonEnabled.bind(this),
        } as AngularBridge;
    }

    openMediaSelector(callbackFn?: Function): void {
        this.zone.run(() => {
            this.mediaSelectorService.openModal()
                .afterClosed().subscribe((data?: FileEntry[]) => {
                if (!data) return;

                const media = data[0];

                if (callbackFn != null) {
                    callbackFn(media);
                }
            });
        });
    }

    openEdContentAi(): void {
        this.zone.run(() => {
            this.edContentAiApi.getToken().subscribe(response => {
                const url = this.settings.get('edcontentai_redirect_url', 'http://edcontentai.local/dashboard');
                window.open(`${url}?auth_token=${response.token}`, '_blank');
            });
        });
    }

    isEdContentAiButtonEnabled(): boolean {
        return this.currentUser.hasPermission('edContentAI.ckeditor.enabled');
    }
}
