import {TestimonialWidget} from './testimonial-widget.model';

export function generateTestimonialWidgetMarkup(testimonialWidget: TestimonialWidget): string {
    const buildConfigHtml = (interval: number, showIndicators: boolean, showControls: boolean) => `<div class="carousel__config" data-interval="${interval ?? ''}" data-showIndicators="${showIndicators ?? true}" data-showControls="${showControls ?? true}" data-ride="carousel"></div>`;
    const buildIndicators = (numberOfQuotes: number, testimonialId: string) => {
        let indicators = `<ol class="carousel-indicators mb-0">`;
        for (let i = 0; i < numberOfQuotes; i++) {
            indicators += `<li data-target="#${testimonialId}" data-bs-target="#${testimonialId}" data-slide-to="${i}" data-bs-slide-to="${i}" ${i === 0 ? 'class="active"' : ''}></li>`;
        }
        indicators += `</ol>`;

        return indicators;
    };
    const buildSlide = (quote: string, person: string, isActive: boolean) => `<div class="carousel-item ${isActive ? 'active' : ''}">
            <div class="media">
                <div class="media-body">
                    <blockquote class="blockquote text-center border-0 p-0">
                        <p class="font-italic lead">${quote}<br></p>
                        <footer class="blockquote-footer mt-3">${person}<br></footer>
                    </blockquote>
                </div>
            </div>
        </div>`;

    const buildControls = (testimonialId: string) => `
    <a class="carousel-control-prev" href="#${testimonialId}" role="button" data-slide="prev" data-bd-slide="prev">
        <span aria-hidden="true">
            <svg fill="var(--col_primary)" height="32px" width="32px" version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 330.002 330.002" xml:space="preserve" stroke="#333">
                <g id="SVGRepo_bgCarrier" stroke-width="0"/>
                <g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"/>
                <g id="SVGRepo_iconCarrier"> <path id="XMLID_227_" d="M233.25,306.001L127.5,165.005L233.25,24.001c4.971-6.628,3.627-16.03-3-21c-6.627-4.971-16.03-3.626-21,3 L96.75,156.005c-4,5.333-4,12.667,0,18l112.5,149.996c2.947,3.93,7.451,6.001,12.012,6.001c3.131,0,6.29-0.978,8.988-3.001 C236.878,322.03,238.221,312.628,233.25,306.001z"/> </g>
            </svg>
        </span>
        <span class="sr-only">Previous</span>
    </a>
    <a class="carousel-control-next" href="#${testimonialId}" role="button" data-slide="next" data-bs-slide="next">
        <span aria-hidden="true">
            <svg fill="var(--col_primary)" height="32px" width="32px" version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 330.002 330.002" xml:space="preserve" stroke="#333">
                <g id="SVGRepo_bgCarrier" stroke-width="0"/>
                <g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"/>
                <g id="SVGRepo_iconCarrier"> <path id="XMLID_226_" d="M233.252,155.997L120.752,6.001c-4.972-6.628-14.372-7.97-21-3c-6.628,4.971-7.971,14.373-3,21 l105.75,140.997L96.752,306.001c-4.971,6.627-3.627,16.03,3,21c2.698,2.024,5.856,3.001,8.988,3.001 c4.561,0,9.065-2.072,12.012-6.001l112.5-150.004C237.252,168.664,237.252,161.33,233.252,155.997z"/> </g>
            </svg>
        </span>
        <span class="sr-only">Next</span>
    </a>`;

    const elements = [];

    elements.push(`<div class="pt-4 pb-4 bg-white shadow rounded">`);
    elements.push(buildConfigHtml(testimonialWidget.interval, testimonialWidget.showIndicators, testimonialWidget.showControls));

    if (testimonialWidget.showIndicators) {
        elements.push(buildIndicators(testimonialWidget.elements.length, testimonialWidget.id));
    }

    elements.push('<div class="carousel-inner px-5 pb-4">');
    (testimonialWidget.elements ?? []).forEach(slide => {
        elements.push(buildSlide(slide.quote, slide.person, slide === testimonialWidget.elements[0]));
    });
    elements.push(`</div>`);

    if (testimonialWidget.showControls) {
        elements.push(buildControls(testimonialWidget.id));
    }
    elements.push(`</div>`);

    return elements.join('\n');
}
