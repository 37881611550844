import {BuilderPage, BuilderPageBundleInfoLinkedPage, BuilderProjectBundleInfo} from '../../shared/builder-types';

export function addBundleInfoToPages(
    pages: BuilderPage[],
    projectBundleInfo?: BuilderProjectBundleInfo,
    currentLanguage?: string,
): BuilderPage[] {
    if (projectBundleInfo == null) {
        return pages;
    }

    const projectBundle = projectBundleInfo.projectBundle;
    const projectBundleProjects = projectBundleInfo.projects;
    const bundleTemplateConfig = projectBundle.templateBundleConfig;
    const activeLanguages = projectBundleProjects.map(p => p.language);

    if (bundleTemplateConfig == null || currentLanguage == null) {
        return pages;
    }

    const currentProject = projectBundleProjects.find(project => project.language === currentLanguage);
    const pageMappings = JSON.parse(projectBundle.page_mappings) as Array<Record<string, string>> ?? [];

    return pages.map(page => {

        const currentPageMapping = pageMappings.find(pageMapping => {
            return pageMapping[currentLanguage] === page.name;
        });

        const linkedPages = activeLanguages.map(language => {
            if (language === currentLanguage) {
                return {
                    projectId: currentProject.id,
                    language,
                    exists: true,
                    name: page.name,
                } as BuilderPageBundleInfoLinkedPage;
            } else {
                const translatedPage = currentPageMapping != null ? currentPageMapping[language] : undefined;

                const languageProject = projectBundleProjects.find(p => p.language === language);
                const languageProjectPageExists = languageProject.pages.includes(translatedPage);

                return {
                    projectId: languageProject.id,
                    language,
                    exists: languageProjectPageExists,
                    name: translatedPage,
                } as BuilderPageBundleInfoLinkedPage;
            }
        }).filter(i => i != null);

        page.bundleInfo = {
            linkedPages
        };

        return page;
    });
}
