import {SlideshowWidget, SlideshowWidgetSlide} from './slideshow-widget.model';
import {getValidValue} from '../../../../shared/util/string-util';

export function extractSlideshowWidget(slideshowMarkupEl: HTMLElement): SlideshowWidget {
    const slideshowWidget = new SlideshowWidget();

    const configEl = slideshowMarkupEl.querySelector('.sb-slideshow-widget__config') as HTMLElement;
    if (configEl != null) {
        slideshowWidget.role = getValidValue(configEl.dataset.role, 'other', ['header', 'other']) as 'header' | 'other';
        slideshowWidget.interval = !Number.isNaN(Number.parseInt(configEl.dataset.interval)) ? Number.parseInt(configEl.dataset.interval) : undefined;
        slideshowWidget.height = !Number.isNaN(Number.parseInt(configEl.dataset.height)) ? Number.parseInt(configEl.dataset.height) : undefined;
    }

    const slideEls = slideshowMarkupEl.querySelectorAll('.sb-slideshow-widget__slide');
    for (let i = 0; i < slideEls.length; i++) {
        const slideEl = slideEls.item(i);
        const slideshowWidgetSlide = extractSlideshowSlide(slideEl as HTMLElement);
        slideshowWidget.slides.push(slideshowWidgetSlide);
    }
    return slideshowWidget;
}

function extractSlideshowSlide(slideEl: HTMLElement): SlideshowWidgetSlide {
    const bgImgEl = slideEl.querySelector('.sb-slideshow-widget__slide__bgImage') as HTMLElement;
    const titleEl = slideEl.querySelector('.sb-slideshow-widget__slide__title') as HTMLElement;
    const textEl = slideEl.querySelector('.sb-slideshow-widget__slide__text') as HTMLElement;
    const listEl = slideEl.querySelector('.sb-slideshow-widget__slide__list') as HTMLElement;
    const buttonEl = slideEl.querySelector('.sb-slideshow-widget__slide__button') as HTMLElement;

    const slide = new SlideshowWidgetSlide();

    slide.id = slideEl.dataset.id;
    slide.enabled = slideEl.dataset.enabled !== 'false';
    // @ts-ignore
    slide.layout = slideEl.dataset.layout;
    // @ts-ignore
    slide.theme = slideEl.dataset.theme;
    slide.townsignVisible = slideEl.dataset.townsignVisible === 'true';
    slide.townsignMobileVisible = slideEl.dataset.townsignMobileVisible !== 'false';
    slide.bgColor = slideEl.dataset.bgColor;
    slide.contentBgColor = slideEl.dataset.contentBgColor;
    slide.hrefEnabled = slideEl.dataset.href != null;
    slide.href = slideEl.dataset.href;
    slide.hrefTarget = slideEl.dataset.hrefTarget;

    if (bgImgEl != null) {
        slide.bgImageSrc = bgImgEl.getAttribute('src');
        slide.bgImageAlt = bgImgEl.getAttribute('alt');
        slide.bgPosition = bgImgEl.dataset.position;
        slide.bgImageMobilePositionAbove = bgImgEl.dataset.mobilePositionAbove === 'true';
    }

    slide.titleEnabled = titleEl != null;
    if (slide.titleEnabled) {
        slide.title = titleEl.textContent;
        slide.titleColor = titleEl.dataset.color;
    }

    slide.textEnabled = textEl != null;
    if (slide.textEnabled) {
        slide.text = textEl.textContent;
        slide.textColor = textEl.dataset.color;
    }

    slide.listEnabled = listEl != null;
    if (slide.listEnabled) {
        slide.listStyle = listEl.dataset.listStyle;
        const listItemsEls = listEl.querySelectorAll('li');
        for (let i = 0; i < listItemsEls.length; i++) {
            const listItemEl = listItemsEls.item(i);
            slide.listItems.push({text: listItemEl.textContent});
        }
        slide.listColor = listEl.dataset.color;
    }

    slide.buttonEnabled = buttonEl != null;
    if (slide.buttonEnabled) {
        slide.buttonLabel = buttonEl.textContent;
        slide.buttonUrl = buttonEl.getAttribute('href');
        slide.buttonTarget = buttonEl.getAttribute('target');
        slide.buttonBgColor = buttonEl.dataset.bgColor;
        slide.buttonTextColor = buttonEl.dataset.textColor;
    }

    return slide;
}
