import {Component, ChangeDetectionStrategy, Input, Output, EventEmitter, HostBinding, OnInit} from '@angular/core';

@Component({
  selector: 'language-badge',
  templateUrl: './language-badge.component.html',
  styleUrls: ['./language-badge.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LanguageBadgeComponent implements OnInit {

  @Input()
  default = false;
  @Input()
  active = false;
  @Input()
  disabled = false;
  @Input()
  highlight = false;
  @Input()
  exists = true;

  @Output()
  click = new EventEmitter();

  clickable;

  ngOnInit(): void {
    this.clickable = this.click.observers.length > 0;
  }

  onClick($event: MouseEvent) {
    $event.stopPropagation();
    this.click.emit($event);
  }
}
