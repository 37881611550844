import {ArchitectElement} from '../../architect-element';
import {MutationsService} from '../../../mutations/mutations.service';
import {SlideshowWidget} from './slideshow-widget.model';
import {SlideshowWidgetEditorModal} from './builder/slideshow-widget-editor-modal.service';
import {ReplaceNodeContent} from '../../../mutations/dom/replace-node-content';
import {generateSlideshowWidgetMarkup} from './slideshow-widget-markup-generator';
import {extractSlideshowWidget} from './slideshow-widget-markup-extractor';

const template = `<div class="sb-slideshow-widget">
    <div class="sb-slideshow-widget__slide" data-layout="left" data-theme="light">
        <div class="sb-slideshow-widget__slide__config" data-role="other"></div>
        <img class="sb-slideshow-widget__slide__bgImage img-fluid" src="images/placeholder/300x300.png" alt="">
        <h2 class="sb-slideshow-widget__slide__title">Fancy title</h2>
        <p class="sb-slideshow-widget__slide__text">Even better text.</p>
        <a class="btn btn-primary sb-slideshow-widget__slide__button" href="#" role="button">Action</a>
    </div>
</div>`;

export class SlideshowWidgetEl extends ArchitectElement {
    name = 'Slideshow';
    contentCategories = ['flow'];
    html = template;
    allowedContent = [];
    category = 'components';
    icon = 'slideshow';
    specificity = 5;
    hiddenClasses = ['sb-slideshow-widget'];
    editActions = [
        {
            name: 'Edit',
            onClick: (node: HTMLElement) => {
                const widget = extractSlideshowWidget(node);
                this.get(SlideshowWidgetEditorModal)
                    .open(node, widget)
                    .afterClosed()
                    .subscribe((updatedWidget?: SlideshowWidget) => {
                        if (updatedWidget != null) {
                            const newInnerHTML = generateSlideshowWidgetMarkup(updatedWidget);
                            this.get(MutationsService).execute(
                                new ReplaceNodeContent(node, node.innerHTML, newInnerHTML)
                            );
                        }
                    });
            },
        },
    ];

    matcher(node: HTMLElement) {
        return node.closest('.sb-slideshow-widget') as HTMLElement;
    }
}
