<div cdkDropList class="list" (cdkDropListDropped)="drop($event)">

    <div class="row" *ngIf="slideshowSettingsEditable">
        <div class="col-grow many-inputs">
            <div class="input-container">
                <label for="role" trans>Role</label>
                <mat-radio-group (change)="slideshowWidget.role = $event.value" id="role">
                    <mat-radio-button [value]="'header'" [checked]="slideshowWidget.role === 'header'">
                        <span trans>Header</span>
                    </mat-radio-button>
                    <mat-radio-button [value]="'other'" [checked]="slideshowWidget.role === 'other'">
                        <span trans>Other</span>
                    </mat-radio-button>
                </mat-radio-group>
            </div>
        </div>
        <div class="col-grow many-inputs">
            <div class="input-container">
                <label for="interval" trans>Interval (ms)</label>
                <input type="number" id="interval" name="interval" [(ngModel)]="slideshowWidget.interval"
                       [disabled]="_processing">
                <mat-hint trans>Leave empty for default value (5000). 0 to disable auto-play.</mat-hint>
            </div>
        </div>
        <div class="col-grow many-inputs"
             *ngIf="currentUser.hasPermission('slideshows.builder.height') && slideshowWidget.role === 'other'">
            <div class="input-container">
                <label for="slideshowHeight" trans>Min-height (px)</label>
                <input type="number" id="slideshowHeight" name="slideshowHeight" [(ngModel)]="slideshowWidget.height"
                       [disabled]="_processing">
                <mat-hint trans>Leave empty for default value (470px). If the content is larger, it will grow.
                </mat-hint>
            </div>
        </div>
    </div>

    <div class="list-item" *ngFor="let slide of slideshowWidget.slides; let i = index" cdkDrag
         [cdkDragDisabled]="_processing">
        <button cdkDragHandle mat-icon-button class="drag-handle">
            <mat-icon svgIcon="drag-handle" matListIcon></mat-icon>
        </button>
        <div class="row">
            <div class="col-grow">
                <div class="row">
                    <div class="col-grow many-inputs">
                        <div class="input-container">
                            <mat-slide-toggle [(ngModel)]="slide.enabled" trans>
                                Enabled
                            </mat-slide-toggle>
                        </div>
                    </div>
                    <div class="col-grow slide-actions">
                        <button type="button" mat-icon-button *ngIf="canMoveSlideUp(i)"
                                (click)="moveSlideUp(i)" [matTooltip]="'Move up' | trans" [disabled]="_processing">
                            <mat-icon svgIcon="move-up"></mat-icon>
                        </button>
                        <button type="button" mat-icon-button *ngIf="canMoveSlideDown(i)"
                                (click)="moveSlideDown(i)" [matTooltip]="'Move down' | trans" [disabled]="_processing">
                            <mat-icon svgIcon="move-down"></mat-icon>
                        </button>
                        <button type="button" mat-icon-button
                                (click)="duplicateSlide(i)" [matTooltip]="'Duplicate slide' | trans" [disabled]="_processing">
                            <mat-icon svgIcon="content-copy"></mat-icon>
                        </button>
                        <button type="button" mat-icon-button
                                (click)="removeSlide(i)" [matTooltip]="'Remove slide' | trans" [disabled]="_processing">
                            <mat-icon svgIcon="delete"></mat-icon>
                        </button>
                    </div>
                </div>

                <div class="row">
                    <div class="col many-inputs">
                        <div class="input-container input-container-bg-image">
                            <label trans>Background image</label>
                            <ng-container *ngIf="slideImgSelectorTemplate != null; else defaultImageSelector">
                                <ng-container
                                        *ngTemplateOutlet="slideImgSelectorTemplate; context: { slide : slide }"></ng-container>
                            </ng-container>
                            <ng-template #defaultImageSelector>
                                <div class="image-preview"
                                     *ngIf="getBgImageSrc(slide)"
                                     (click)="!_processing && updateSlideImage(slide)"
                                     [matTooltip]="'Change image' | trans">
                                    <img [src]="getBgImageSrc(slide)" [alt]="slide.bgImageAlt">
                                </div>
                                <div class="action-buttons">
                                    <button type="button" class="action-button" mat-stroked-button color="accent"
                                            (click)="!_processing && updateSlideImage(slide)" trans>Select
                                    </button>
                                    <button type="button" class="action-button delete-button" mat-icon-button
                                            [matTooltip]="'Remove background image' | trans"
                                            *ngIf="getBgImageSrc(slide)"
                                            (click)="!_processing && deleteSlideImage(slide)">
                                        <mat-icon svgIcon="delete"></mat-icon>
                                    </button>
                                </div>
                            </ng-template>
                        </div>
                        <ng-container *ngIf="slide.bgImageSrc">
                            <div class="input-container input-container-bg-image-alt">
                                <label for="bgImageAlt" trans>Alt text</label>
                                <input type="text" id="bgImageAlt" name="bgImageAlt" [(ngModel)]="slide.bgImageAlt"
                                       [disabled]="_processing">
                            </div>
                            <div class="input-container input-container-bg-image-alt">
                                <label for="bgImageAlt" trans>Image alignment</label>
                                <position-grid-control
                                        [value]="slide.bgPosition"
                                        (valueChanged)="slide.bgPosition = $event"
                                ></position-grid-control>
                            </div>
                        </ng-container>

                        <div class="input-container" *ngIf="slide.bgImageSrc">
                            <mat-slide-toggle [(ngModel)]="slide.bgImageMobilePositionAbove" trans>
                                Mobile: Show above
                            </mat-slide-toggle>
                        </div>

                        <div class="input-container">
                            <label for="bgColor" trans>Slide background color</label>
                            <color-input id="bgColor" [(ngModel)]="slide.bgColor" [disabled]="_processing"></color-input>
                        </div>

                        <div class="input-container">
                            <label for="contentBgColor" trans>Content background color</label>
                            <color-input id="contentBgColor" [(ngModel)]="slide.contentBgColor" [disabled]="_processing"></color-input>
                        </div>

                        <div class="input-container">
                            <label for="layout" trans>Content box - Title, Text Alignment</label>
                            <ng-select id="layout" name="layout"
                                       appearance="outline"
                                       appendTo="body"
                                       [clearable]="false"
                                       [(ngModel)]="slide.layout"
                                       [ngModelOptions]="{standalone: true}">
                                <ng-option [value]="'top-left'" trans>Align Top-Left</ng-option>
                                <ng-option [value]="'top-center'" trans>Align Top-Center</ng-option>
                                <ng-option [value]="'top-right'" trans>Align Top-Right</ng-option>
                                <ng-option [value]="'middle-left'" trans>Align Middle-Left</ng-option>
                                <ng-option [value]="'middle-center'" trans>Align Middle-Center</ng-option>
                                <ng-option [value]="'middle-right'" trans>Align Middle-Right</ng-option>
                                <ng-option [value]="'bottom-left'" trans>Align Bottom-Left</ng-option>
                                <ng-option [value]="'bottom-center'" trans>Align Bottom-Center</ng-option>
                                <ng-option [value]="'bottom-right'" trans>Align Bottom-Right</ng-option>
                                <ng-option [value]="'person_pointing'" trans>Person pointing</ng-option>
                            </ng-select>
                        </div>

                        <div class="input-container">
                            <label for="theme" trans>Theme</label>
                            <ng-select id="theme" name="theme"
                                       appearance="outline"
                                       appendTo="body"
                                       [clearable]="false"
                                       [(ngModel)]="slide.theme"
                                       [ngModelOptions]="{standalone: true}">
                                <ng-option [value]="'light'" trans>Light</ng-option>
                                <ng-option [value]="'dark'" trans>Dark</ng-option>
                            </ng-select>
                        </div>

                        <div class="input-container" *ngIf="positionEditable">
                            <label for="position" trans>Position</label>
                            <ng-select id="position" name="position"
                                       appearance="outline"
                                       appendTo="body"
                                       [clearable]="false"
                                       [(ngModel)]="slide.position"
                                       [ngModelOptions]="{standalone: true}">
                                <ng-option [value]="'first'" trans>First</ng-option>
                                <ng-option [value]="'last'" trans>Last</ng-option>
                                <ng-option [value]="1" trans>Custom...</ng-option>
                            </ng-select>
                        </div>
                        <div class="input-container" *ngIf="isNumber(slide.position)">
                            <input type="text" id="customPosition" name="customPosition" [(ngModel)]="slide.position"
                                   [disabled]="_processing">
                        </div>

                        <div class="input-container">
                            <mat-slide-toggle [(ngModel)]="slide.hrefEnabled" trans>
                                Linked
                            </mat-slide-toggle>
                        </div>
                        <ng-container *ngIf="slide.hrefEnabled">
                            <div class="input-container">
                                <label for="href" trans>Link url</label>
                                <input type="text" id="href" name="href" [(ngModel)]="slide.href"
                                       [disabled]="_processing">
                            </div>
                            <div class="input-container">
                                <label for="hrefTarget" trans>Link target</label>
                                <ng-select id="hrefTarget" name="hrefTarget"
                                           appearance="outline"
                                           appendTo="body"
                                           [clearable]="false"
                                           [(ngModel)]="slide.hrefTarget"
                                           [ngModelOptions]="{standalone: true}">
                                    <ng-option [value]="''" trans>Current tab</ng-option>
                                    <ng-option [value]="'_blank'" trans>New tab</ng-option>
                                </ng-select>
                            </div>
                        </ng-container>
                    </div>

                    <div class="col col-grow many-inputs">
                        <div class="input-container">
                            <mat-slide-toggle [(ngModel)]="slide.titleEnabled" trans>
                                Title
                            </mat-slide-toggle>
                        </div>
                        <ng-container *ngIf="slide.titleEnabled">
                            <div class="input-container">
                                <textarea type="text" id="title" name="title" [(ngModel)]="slide.title"
                                          [disabled]="_processing"></textarea>
                            </div>
                            <div class="input-container">
                                <label for="titleColor" trans>Title color</label>
                                <color-input id="titleColor" [(ngModel)]="slide.titleColor" [disabled]="_processing"></color-input>
                            </div>
                        </ng-container>

                        <div class="input-container">
                            <mat-slide-toggle [(ngModel)]="slide.textEnabled" trans>
                                Text
                            </mat-slide-toggle>
                        </div>
                        <ng-container *ngIf="slide.textEnabled">
                            <div class="input-container">
                                <textarea type="text" id="text" name="text" [(ngModel)]="slide.text"
                                          [disabled]="_processing"></textarea>
                            </div>
                            <div class="input-container">
                                <label for="textColor" trans>Text color</label>
                                <color-input id="textColor" [(ngModel)]="slide.textColor" [disabled]="_processing"></color-input>
                            </div>
                        </ng-container>

                        <div class="input-container">
                            <mat-slide-toggle [(ngModel)]="slide.listEnabled" trans>
                                List
                            </mat-slide-toggle>
                        </div>
                        <ng-container *ngIf="slide.listEnabled">
                            <div class="input-container">
                                <label for="listStyle" trans>Style</label>
                                <ng-select id="listStyle" name="listStyle"
                                           appearance="outline"
                                           appendTo="body"
                                           [clearable]="false"
                                           [(ngModel)]="slide.listStyle"
                                           [ngModelOptions]="{standalone: true}">
                                    <ng-option [value]="'none'" trans>None</ng-option>
                                    <ng-option [value]="'disc'" trans>Square</ng-option>
                                    <ng-option [value]="'circle'" trans>Circle</ng-option>
                                    <ng-option [value]="'square'" trans>Square</ng-option>
                                    <ng-option [value]="'checkmark'" trans>Checkmark</ng-option>
                                    <ng-option [value]="'decimal'" trans>Decimal</ng-option>
                                </ng-select>
                            </div>

                            <div class="input-container">
                                <label trans>List items</label>
                                <div class="input-container-list-item"
                                     *ngFor="let listItem of slide.listItems; index as i">
                                    <input type="text" name="listItem[{{i}}]" [(ngModel)]="slide.listItems[i].text"
                                           [disabled]="_processing">
                                    <button type="button" mat-icon-button (click)="slide.listItems.splice(i, 1)"
                                            [matTooltip]="'Remove item' | trans">
                                        <mat-icon svgIcon="delete"></mat-icon>
                                    </button>
                                </div>
                                <button type="button" mat-icon-button (click)="slide.listItems.push({text: ''})"
                                        [matTooltip]="'Add item' | trans">
                                    <mat-icon svgIcon="add"></mat-icon>
                                </button>
                            </div>

                            <div class="input-container">
                                <label for="listColor" trans>List text color</label>
                                <color-input id="listColor" [(ngModel)]="slide.listColor" [disabled]="_processing"></color-input>
                            </div>
                        </ng-container>

                        <div class="input-container">
                            <mat-slide-toggle [(ngModel)]="slide.buttonEnabled" trans>
                                Button
                            </mat-slide-toggle>
                        </div>
                        <ng-container *ngIf="slide.buttonEnabled">
                            <div class="input-container">
                                <label for="buttonLabel" trans>Label</label>
                                <input type="text" id="buttonLabel" name="buttonLabel" [(ngModel)]="slide.buttonLabel"
                                       [disabled]="_processing">
                            </div>
                            <div class="input-container">
                                <label for="buttonUrl" trans>Url</label>
                                <input type="text" id="buttonUrl" name="buttonUrl" [(ngModel)]="slide.buttonUrl"
                                       [disabled]="_processing">
                            </div>
                            <div class="input-container">
                                <label for="buttonTarget" trans>Target</label>
                                <ng-select id="buttonTarget" name="buttonTarget"
                                           appearance="outline"
                                           appendTo="body"
                                           [clearable]="false"
                                           [(ngModel)]="slide.buttonTarget"
                                           [ngModelOptions]="{standalone: true}">
                                    <ng-option [value]="''" trans>Current tab</ng-option>
                                    <ng-option [value]="'_blank'" trans>New tab</ng-option>
                                </ng-select>
                            </div>
                            <div class="input-container">
                                <label for="buttonBgColor" trans>Button background color</label>
                                <color-input id="buttonBgColor" [(ngModel)]="slide.buttonBgColor" [disabled]="_processing"></color-input>
                            </div>
                            <div class="input-container">
                                <label for="buttonTextColor" trans>Button text color</label>
                                <color-input id="buttonTextColor" [(ngModel)]="slide.buttonTextColor" [disabled]="_processing"></color-input>
                            </div>
                        </ng-container>

                        <div class="input-container" *ngIf="townsignEnabled">
                            <mat-slide-toggle [(ngModel)]="slide.townsignVisible" trans>
                                Townsign
                            </mat-slide-toggle>
                        </div>

                        <div class="mx-3">
                            <div class="input-container" *ngIf="townsignEnabled && slide.townsignVisible">
                                <mat-slide-toggle [(ngModel)]="slide.townsignMobileVisible" trans>
                                    Mobile: Visible
                                </mat-slide-toggle>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
