import {ArchitectElement} from '../../architect-element';
import {MutationsService} from '../../../mutations/mutations.service';
import {TestimonialWidget} from './testimonial-widget.model';
import {TestimonialWidgetEditorModal} from './builder/testimonial-widget-editor-modal.service';
import {ReplaceNodeContent} from '../../../mutations/dom/replace-node-content';
import {generateTestimonialWidgetMarkup} from './testimonial-widget-markup-generator';
import {extractTestimonialWidget} from './testimonial-widget-markup-extractor';
import {randomString} from '@common/core/utils/random-string';
import {ModifyAttributes, NodeAttributes} from '../../../mutations/attributes/modify-attributes';

function generateTemplate(): string {
    const testimonialId = randomString(10);

    return `<div id="${testimonialId}" class="sb-testimonials-widget carousel slide mt-5 mb-5" data-ride="carousel" data-bs-ride="carousel">
    <div class="pt-4 pb-4 bg-white shadow rounded">
        <div class="carousel__config" data-interval="" data-showindicators="true" data-showcontrols="true" data-ride="carousel"></div>
        <ol class="carousel-indicators mb-0">
            <li data-target="#testemonialCarousel" data-bs-target="#testemonialCarousel" data-slide-to="0" data-bs-slide-to="0" class="active"></li>
            <li data-target="#testemonialCarousel" data-bs-target="#testemonialCarousel" data-slide-to="1" data-bs-slide-to="1"></li>
        </ol>
        <div class="carousel-inner px-5 pb-4">
            <div class="carousel-item active">
                <div class="media">
                    <div class="media-body">
                        <blockquote class="blockquote text-center border-0 p-0">
                            <p class="font-italic lead">"Es war toll mit Euch zu arbeiten!"<br></p>
                            <footer class="blockquote-footer mt-3">Max Mustermann<br></footer>
                        </blockquote>
                    </div>
                </div>
            </div>
            <div class="carousel-item ">
                <div class="media">
                    <div class="media-body ml-3">
                        <blockquote class="blockquote border-0 p-0">
                            <p class="font-italic lead">"Ich habe es sehr genossen!"<br></p>
                            <footer class="blockquote-footer mt-3">Andrea Andersnamig<br></footer>
                        </blockquote>
                    </div>
                </div>
            </div>
        </div>
        <a class="carousel-control-prev" href="#${testimonialId}" role="button" data-slide="prev" data-bs-slide="prev">
            <span aria-hidden="true">
                <svg fill="var(--col_primary)" height="32px" width="32px" version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 330.002 330.002" xml:space="preserve" stroke="#333">
                    <g id="SVGRepo_bgCarrier" stroke-width="0"/>
                    <g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"/>
                    <g id="SVGRepo_iconCarrier"> <path id="XMLID_227_" d="M233.25,306.001L127.5,165.005L233.25,24.001c4.971-6.628,3.627-16.03-3-21c-6.627-4.971-16.03-3.626-21,3 L96.75,156.005c-4,5.333-4,12.667,0,18l112.5,149.996c2.947,3.93,7.451,6.001,12.012,6.001c3.131,0,6.29-0.978,8.988-3.001 C236.878,322.03,238.221,312.628,233.25,306.001z"/> </g>
                </svg>
            </span>
            <span class="sr-only">Previous</span>
        </a>
        <a class="carousel-control-next" href="#${testimonialId}" role="button" data-slide="next" data-bs-slide="prev">
            <span aria-hidden="true">
                <svg fill="var(--col_primary)" height="32px" width="32px" version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 330.002 330.002" xml:space="preserve" stroke="#333">
                    <g id="SVGRepo_bgCarrier" stroke-width="0"/>
                    <g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"/>
                    <g id="SVGRepo_iconCarrier"> <path id="XMLID_226_" d="M233.252,155.997L120.752,6.001c-4.972-6.628-14.372-7.97-21-3c-6.628,4.971-7.971,14.373-3,21 l105.75,140.997L96.752,306.001c-4.971,6.627-3.627,16.03,3,21c2.698,2.024,5.856,3.001,8.988,3.001 c4.561,0,9.065-2.072,12.012-6.001l112.5-150.004C237.252,168.664,237.252,161.33,233.252,155.997z"/> </g>
                </svg>
            </span>
            <span class="sr-only">Next</span>
        </a>
    </div>
</div>`;
}

function generateTestimonialWidgetAttributes(testimonialWidget: TestimonialWidget): NodeAttributes {
    const result: NodeAttributes = {};
    if (testimonialWidget.interval !== undefined) {
        result['data-interval'] = testimonialWidget.interval.toString();
    }
    return result;
}

export class TestimonialWidgetEl extends ArchitectElement {
    name = 'Testimonials';
    contentCategories = ['flow'];
    html = generateTemplate;
    allowedContent = [];
    category = 'components';
    icon = 'format-quote';
    specificity = 5;
    hiddenClasses = ['carousel', 'slide', 'sb-testimonials-widget'];
    editActions = [
        {
            name: 'Edit',
            onClick: (node: HTMLElement) => {
                const widget = extractTestimonialWidget(node);
                this.get(TestimonialWidgetEditorModal)
                    .open(node, widget)
                    .afterClosed()
                    .subscribe((updatedWidget?: TestimonialWidget) => {
                        if (updatedWidget != null) {
                            const newInnerHTML = generateTestimonialWidgetMarkup(updatedWidget);
                            const newAttributes = generateTestimonialWidgetAttributes(updatedWidget);
                            this.get(MutationsService).execute(
                                new ReplaceNodeContent(node, node.innerHTML, newInnerHTML)
                            );
                            this.get(MutationsService).execute(
                                new ModifyAttributes(node, newAttributes)
                            );
                        }
                    });
            },
        },
    ];

    matcher(node: HTMLElement): HTMLElement {
        return node.closest('.sb-testimonials-widget') as HTMLElement;
    }
}
