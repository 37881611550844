<div class="color-input-wrapper">
    <button type="button" mat-icon-button matSuffix
            class="color-button"
            [disabled]="disabled"
            (click)="openColorPicker(colorInput)">
        <span class="color-preview" [style.background-color]="colorInputFormControl.value"></span>
    </button>
    <input type="text" #colorInput
           [formControl]="colorInputFormControl"
           [disabled]="disabled"
           placeholder="#00000, rgba(0,0,0,.5),..."
    >
</div>
