import {Injectable} from '@angular/core';
import {AppHttpClient} from '@common/core/http/app-http-client.service';
import {BackendResponse} from '@common/core/types/backend-response';

@Injectable({
    providedIn: 'root'
})
export class EdContentAiApi {
    static BASE_URI = 'ed-content-ai';

    constructor(private http: AppHttpClient) {
    }

    public getToken(): BackendResponse<{ token: string }> {
        return this.http.get(`${EdContentAiApi.BASE_URI}/token`);
    }
}
