import {Injectable} from '@angular/core';
import {Settings} from '../../core/config/settings.service';
import * as ClassicEditor from 'src/assets/js/ckeditor/ckeditor';
import {CKEditor5} from '@ckeditor/ckeditor5-angular';

@Injectable({
    providedIn: 'root'
})
export class CkEditor5 {

    public Editor: CKEditor5.EditorConstructor = ClassicEditor;

    constructor(
        private settings: Settings,
    ) {
    }

    public get config(): CKEditor5.Config {
        return {
            language: 'de',
            simpleUpload: {
                uploadUrl: '/secure/ckeditor5/upload?_token=' + this.settings.csrfToken,
            },
        };
    }

    public get configMinimal(): CKEditor5.Config {
        return {
            ...this.config,
            toolbar: {
                items: [
                    'bold',
                    'italic',
                    'underline',
                    'strikethrough',
                    '|',
                    'undo',
                    'redo',
                ]
            },
        };
    }
}
