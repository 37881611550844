import {Project} from './projects/Project';
import {ProjectBundle} from './projects/ProjectBundle';


export interface BuilderPageBundleInfoLinkedPage {
    projectId: number;
    language: string;
    exists: boolean;
    name?: string;
}

export interface BuilderPageBundleInfo {
    linkedPages: BuilderPageBundleInfoLinkedPage[];
}

export interface BuilderPage {
    id?: string;
    name: string;
    html: string;
    doc?: Document;
    description?: string;
    keywords?: string;
    title?: string;
    bundleInfo?: BuilderPageBundleInfo;
}

export interface BuilderProjectBundleProjectInfo {
    id: number;
    language: string;
    pages: string[];
}

export interface BuilderProjectBundleInfo {
    projectBundle: ProjectBundle;
    projects: BuilderProjectBundleProjectInfo[];
}

export interface BuilderProject {
    model: Project;
    pages: BuilderPage[];
    css: string;
    js: string;
    template: BuilderTemplate;
}

// TODO type naming missmatch
//  ContentBlock is ProjectContentBlock in Backend
//  TemplateContentBlock is ContentBlock in Backend

export const CONTENT_BLOCK_TYPE_RESOLVER = 'resolver-block';
export const CONTENT_BLOCK_TYPE_EDITABLE = 'editable-block';

export interface ContentBlock {
    id: number;
    template?: string;
    name?: string;
    content_key: string;
    content: string;
    revision?: number;
    is_local?: boolean;
    created_at?: string;
    created_by?: number;
    content_block?: TemplateContentBlock;
}

export interface TemplateContentBlock {
    id: number;
    resolver?: string;
}

export interface ContentBlockUpdate {
    content_key: string;
    content_block_id: number;
    content: string;
    is_local: boolean;
}

export interface ContentBlockKeyRef {
    content_key: string;
}

export interface ContentBlockGlobalUpdateRequest {
    originProjectId: number;
    targetProjectIds: number[];
    contentBlockRefs: ContentBlockKeyRef[];
}

export interface BuilderTemplateConfig extends HtmlParserConfig {
    libraries: string[];
    name?: string;
    color: string;
    category: string;
    theme: string;
    framework: string;
    language?: string;
    template_bundle?: string;
    bundle?: boolean;
    bundle_config?: BuilderBundleTemplateConfig;

    // bundle properties
    languages?: string[];
    default_language?: string;
}

export interface BuilderBundleTemplateConfig extends HtmlParserConfig {
    bundle_name: string;
    languages: string[];
    default_language: string;
    i18n?: I18nEntry[];
    // pages: string[];
    // templates: Record<string, { // key: language
    //     pages_map: Record<string, { // key: page name key from 'pages'
    //         name: string, // translated page name
    //         exists: boolean
    //     }>
    // }>;
}

export interface HtmlParserConfig {
    includeBootstrap?: boolean;
    includeFontawesome?: boolean;
    nodesToRestore?: string[];
    classesToRemove?: string[];
}

export type Language = string;

export type I18nLang = Language;

export interface I18nEntry {
    key: string;
    values: Record<I18nLang, string>;
}

export class BuilderTemplate {
    name: string;
    updated_at: string;
    js: string;
    css: string;
    thumbnail: string;
    pages: BuilderPage[];
    i18nEntries: I18nEntry[];
    // config: {
    //     libraries: string[],
    //     name?: string,
    //     color: string,
    //     category: string,
    //     theme: string,
    //     framework: string,
    // };
    config: BuilderTemplateConfig;

    constructor(params: Object = {}) {
        for (const name in params) {
            this[name] = params[name];
        }
    }
}

export interface FtpDetails {
    host?: string;
    username?: string;
    password?: string;
    directory?: string;
    port?: number;
    ssl?: boolean;
}

export interface Agency {
    id: number;
    agency_hash: string;
    name: string;
    description?: string;
    url?: string;
    user_id: string;
    active: boolean;
    settings?: AgencySettings;
    agency_metadata?: AgencyMetadata;
    openingHours?: OpeningHour[];
    slug?: string;
    contact_person_name?: string;
    contact_person_lastname?: string;
    contact_person_email?: string;
    contact_person_function?: string;
    contact_person_phone?: string;
    inquiries_email?: string;
    street_address?: string;
    city?: string;
    postal_code?: Region;
    agency_form_email_address?: string;
    agency_base_url?: string;
    agency_logoname?: string;
    agency_url?: string;
    google_api_key?: string;
    google_place_id?: string;
    google_tracking_code?: string;
    google_tagmanager_code?: string;
    agency_phone?: string;
    agency_phone_formatted?: string;
    agency_phone_mobile?: string;
    agency_fax?: string;
    country?: Country;
    region?: Region;
    broker_id: string;
    has_employee_insurance?: boolean;
    has_car_registration_module?: boolean;
    has_news_module?: boolean;
    has_jobs_module?: boolean;
    has_events_module?: boolean;
    has_company: boolean;
    has_google_ratings: boolean;
    has_dependency_adhoga?: boolean;
    has_projects?: boolean;
    own_id?: string;
}

export interface AgencyTeamMember {
    id: number;
    agency_hash: string;
    salutation?: string;
    image_link?: string;
    firstname?: string;
    lastname?: string;
    function?: string;
    role?: string;
    email?: string;
    phone?: string;
    date_added?: string;
    position?: number;
    matching_active: boolean;
    matching_sent_count: number;
    matching_completed_at: string | null;
    archived: boolean;
    archived_at: number;
}

export interface AgencyMetadata {
    id: number;
    agency_hash: string;
    imprint_company_name?: string;
    imprint_ceo_firstname?: string;
    imprint_ceo_lastname?: string;
    imprint_ceo2_firstname?: string;
    imprint_ceo2_lastname?: string;
    imprint_company_asset?: string;
    imprint_uid_number?: string;
    imprint_commercial_register_number?: string;
    imprint_commercial_register_court?: string;
    imprint_gisa?: string;
    imprint_supervisory_and_trade_authority?: string;
    imprint_company_head_office?: string;
    imprint_awarding_state?: string;
    date_added?: string;
    contact_person_2_salutation?: string;
    contact_person_2_name?: string;
    contact_person_2_lastname?: string;
    contact_person_2_email?: string;
    contact_person_2_function?: string;
    contact_person_2_phone?: string;
}

export enum AgencyOpeningHoursVariant {
    main = 'main',
    other = 'other',
}

export interface OpeningHour {
    id: number;
    agency_id: number;
    week_day?: string;
    week_day_short?: string;
    am_from?: string;
    am_to?: string;
    pm_from?: string;
    pm_to?: string;
    opening_hours: number;
    opening_status: number;
}

export interface AgencySettings {
    hours: AgencyHourSettings;
}

export interface AgencyHourSettings {
    monday?: FromToHour;
    tuesday?: FromToHour;
    wednesday?: FromToHour;
    thursday?: FromToHour;
    friday?: FromToHour;
    saturday?: FromToHour;
    sunday?: FromToHour;
    freetextHours?: string;
}

export interface FromToHour {
    start: string;
    end: string;
}

export interface Product {
    id: number;
    name: string;
    description?: string;
    code?: string;
    user_id: string;
    active: boolean;
    company_id: number;
    has_excel?: boolean;
    details_page_url?: string;
    page_url?: string;
}

export interface CompanyProductBox {
    id: number;
    company_id: number;
    product_id: number;
    group_number?: number;
    title?: string;
    price?: number;
    total_insurance_sum?: number;
    discount_percentage?: number;
    is_top?: boolean;
    rate_code_array?: string;
    product_rates: string[];
    injury_product_rates?: FreetextProductRate[];
    product_texts?: [];
    standard_text?: string;
}

export interface ProductRate {
    rate_code: string;
    rate_name?: string;
    rate_designation?: string;
    amount_insurance?: number;
}

export interface FreetextProductRate {
    rate_code: string;
    rate_name?: string;
    rate_designation?: string;
    amount_insurance?: number;
    is_sub?: boolean;
    rate_hash?: string;
}

export interface ProductBoxText {
    id: number;
    rate_text?: string;
}

export interface Company {
    id: number;
    name: string;
    description?: string;
    slug?: string;
    user_id: string;
    agency_id: number;
    agency: Agency;
    products: Product[];
    active: boolean;
    has_products: boolean;
    internal_notes?: string;
    contact_person_lastname?: string;
    contact_person_name?: string;
    contact_person_email?: string;
    street_address?: string;
    city?: string;
    postal_code?: string;
    country?: string;
    email_suffix?: string;
    pin_code?: string;
    inquiries_email?: string;
}

export interface ProductText {
    id: number;
    rate_text: string;
}

export interface CompanyProduct {
    id: number;
    company_id: number;
    product_id: number;
    discount?: number;
    info_text?: string;
}

export interface Country {
    id: number;
    code: string;
    en: string;
    de: string;
}

export interface Region {
    id: number;
    city: string;
    zip: string;
    federal_state: string;
    country_id: number;
}

export interface AgencyPublishEntity {
    publish_agency_ids?: string;
    publish_excluded_agency_ids?: string;
    agency_id?: number;
}

export interface News extends AgencyPublishEntity {
    ID: number;
    topthema: boolean;
    title: string;
    date: string;
    slug: string;
    sites: string;
    headerImage: string;
    headline: string;
    subheadline: string;
    excerpt: string;
    content: string;
    format: string;
    tags: string;
    autoren: string;
    publish_agency_ids?: string;
    etlnr: number;
    date_publish: string;
    date_expiration: string;
    agency_id?: number;
    agency_name?: string;
    language: string;
    date_modified: string;
    is_created_by_user: boolean;
    top_news_customer: boolean;
}

export interface Event extends AgencyPublishEntity {
    ID: number;
    topthema: boolean;
    title: string;
    date: string;
    slug: string;
    sites: string;
    headerImage: string;
    headline: string;
    subheadline: string;
    excerpt: string;
    content: string;
    autoren: string;
    publish_agency_ids?: string;
    etlnr: number;
    date_publish: string;
    date_expiration: string;
    agency_id?: number;
    veranstaltungsDatum?: string;
    veranstaltungsEnde?: string;
    eventTypes?: string;
    eventLocation?: string;
    agency_name?: string;
    anmeldelink?: string;
    anmeldelink_title?: string;
    date_modified: string;
    is_created_by_user: boolean;
    language: string;
}

export interface Job extends AgencyPublishEntity {
    ID: number;
    title: string;
    city: string;
    state: string;
    street: string;
    zipcode: string;
    slug: string;
    sites: string;
    headerImage: string;
    inhalt: string;
    anstellung: string;
    format: string;
    ap?: string;
    ag?: number;
    publish_agency_ids?: string;
    etlnr?: number;
    date: string;
    date_publish?: string;
    date_expiration: string;
    date_end?: string;
    agency_id?: number;
    agency_name?: string;
    is_created_by_user: boolean;
    language: string;
}
