<h1 mat-dialog-title trans>
    {{ title }}
</h1>

<div mat-dialog-content *ngIf="message != null || itemName != null" trans>
    <p *ngIf="message != null">{{ message }}</p>
    <p *ngIf="itemName != null" class="item-name">{{ itemName }}</p>
</div>

<div mat-dialog-actions>
    <button mat-button (click)="onDismiss()" trans>Cancel</button>
    <button mat-raised-button color="{{confirmColor}}" (click)="onConfirm()" trans>{{ confirmLabel }}</button>
</div>
