export function getRelativeProjectUploadUrl(url: string): string {
    if (url.indexOf('/projects/') > 0) {
        const imagesFolderIndex = url.indexOf('/images/');
        if (imagesFolderIndex !== -1) {
            return url.substring(imagesFolderIndex + 1);
        }
        const filesFolderIndex = url.indexOf('/files/');
        if (filesFolderIndex !== -1) {
            return url.substring(filesFolderIndex + 1);
        }
    }
    return url;
}
