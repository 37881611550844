import {Project} from './Project';

/**
 * Returns the display name of a project. If the project has an agency with an own_id, the agency's own_id will be appended to the name.
 * @param project - the project to get the display name for
 */
export const getProjectDisplayName = (project: Project): string => {
    let displayName = project.name;
    if (project.agency?.own_id != null) {
        displayName += ` [${project.agency.own_id}]`;
    }
    return displayName;
};
