import {Injectable} from '@angular/core';
import {AppHttpClient} from '@common/core/http/app-http-client.service';
import {BackendResponse} from '@common/core/types/backend-response';
import {UserSettings as UserSettingsModel} from '@common/core/types/models/UserSettings';
import {Observable} from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class UserSettings {
    constructor(private http: AppHttpClient) {}

    /**
     * Get the user settings for the user with the specified ID
     */
    public get(id: number): BackendResponse<UserSettingsModel> {
        return this.http.get(`secure/users/${id}/settings`);
    }

    public update(id: number, settings: object): Observable<void> {
        return this.http.put(`secure/users/${id}/settings`, settings);
    }
}
