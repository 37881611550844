import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {LanguageBadgeComponent} from './language-badge.component';
import {MatButtonModule} from '@angular/material/button';

@NgModule({
    declarations: [
        LanguageBadgeComponent,
    ],
    imports: [
        CommonModule,
        MatButtonModule,
    ],
    exports: [
        LanguageBadgeComponent,
    ]
})
export class LanguageBadgeModule {
}
