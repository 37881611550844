<div *ngIf="!(files?.length > 0); else fileAvailable">
    <input type="file" id="filePicker" #fileInput (change)="setFiles(fileInput.files)"
           [accept]="getFileAccept()">
    <div>
        <mat-icon svgIcon="file"></mat-icon>
    </div>
    <div trans>Drag and Drop files here</div>
    <div trans>or</div>
    <label for="filePicker" trans>Choose files</label>
    <span *ngIf="acceptType != null"><span trans>Accepted types</span>: <span
        trans>{{acceptType}}</span></span>
</div>
<ng-template #fileAvailable>
    <div>
        <div class="file-entry" *ngFor="let file of files">
            <span>{{file.name}}</span>
            <button type="button" class="action-button delete-button" mat-icon-button
                    (click)="removeFile(file)">
                <mat-icon svgIcon="delete"></mat-icon>
            </button>
        </div>
    </div>
</ng-template>
