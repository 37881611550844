export function removeIdFromNode(node: HTMLElement, recursive = false): void {
    if (node.dataset.arId) {
        delete node.dataset.arId;
    }
    if (recursive) {
        for (let i = 0; i < node.children.length; i++) {
            const child = node.children[i] as HTMLElement;
            removeIdFromNode(child, true);
        }
    }
}
