import {ChangeDetectionStrategy, Component, EventEmitter, OnDestroy, OnInit, Output} from '@angular/core';
import {ProjectSettingsService} from '../../project-settings.service';
import {BuilderStateService} from '../../../../../html-builder/builder-state.service';
import {FormGroup} from '@angular/forms';
import {CkEditor5} from '@common/text-editor/ckeditor5/CkEditor5';
import {ImageElementService} from '../../../../../html-builder/elements/image-element.service';
import {ProjectUrl} from '../../../project-url.service';
import {stringToBoolean} from '@common/core/utils/string-to-boolean';
import {NotificationModalService} from './notification-modal.service';
import {Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';


@Component({
    selector: 'notification-modal-tab',
    templateUrl: './notification-modal-tab.component.html',
    styleUrls: ['./notification-modal-tab.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NotificationModalTabComponent implements OnInit, OnDestroy {
    @Output() processing = new EventEmitter<boolean>();
    _processing = false;

    private destroyed$ = new Subject();

    constructor(
        public projectSettings: ProjectSettingsService,
        public ckEditor5: CkEditor5,
        private projectUrl: ProjectUrl,
        private state: BuilderStateService,
        private imageElementService: ImageElementService,
        private notificationModalService: NotificationModalService
    ) {
        this.processing
            .pipe(takeUntil(this.destroyed$))
            .subscribe(p => this._processing = p);
    }

    ngOnInit(): void {
        const element = this.notificationModalService.getExistingHtmlElement();
        if (element != null) {
            this.projectSettings.form.controls.notificationModal.patchValue({
                enabled: stringToBoolean(element.dataset?.enabled, false),
                showUntil: element.dataset?.showUntil,
                text: element.querySelector('#vacation-modal-text')?.innerHTML,
                imageSource: element.dataset?.imageSource,
                imageAlt: element.dataset?.imageAlt
            });
        }
    }

    ngOnDestroy(): void {
        this.destroyed$.next();
        this.destroyed$.complete();
    }

    updateSlideImage(): void {
        this.processing.emit(true);
        this.imageElementService.openModal().subscribe((url: string) => {
            this.processing.emit(false);

            if (!url) return;

            this.projectSettings.form.controls.notificationModal.patchValue({
                imageSource: url
            });
        });
    }

    private getImageSource(): string | undefined {
        const result = (this.projectSettings.form.controls.notificationModal as FormGroup).controls.imageSource.value;

        if (result == null || result.length === 0) {
            return undefined;
        }

        return result;
    }

    hasImage(): boolean {
        return this.getImageSource() !== undefined;
    }

    getImageForModal(): string {
        return this.makeUrlAbsolute(this.getImageSource());
    }

    deleteImage() {
        this.projectSettings.form.controls.notificationModal.patchValue({
            image: undefined
        });
    }

    makeUrlAbsolute(imgUrl: string) {
        return `${this.projectUrl.getBaseUrl(this.state.project$.value.model)}/${imgUrl}`;
    }
}
