import {BlockGridWidget, BlockGridWidgetBlockImagePosition, BlockGridWidgetBlockLayout, BlockGridWidgetItem} from './block-grid-widget.model';
import {isNotBlank} from '../../../../shared/util/string-util';

export function extractBlockGridWidget(markupEl: HTMLElement): BlockGridWidget {
    const widget = new BlockGridWidget();

    const configEl = markupEl.querySelector('.sb-block-grid-widget__config') as HTMLElement;
    if (configEl != null) {
        widget.itemHoverOverlayColor = configEl.dataset.itemHoverOverlayColor ?? '';
        widget.gridGap = configEl.dataset.gridGap ?? '';
        widget.linkIndicatorIconColor = configEl.dataset.linkIndicatorIconColor ?? '';
        widget.linkIndicatorBackgroundColor = configEl.dataset.linkIndicatorBackgroundColor ?? '';
    }

    const itemMarkupEls = markupEl.querySelectorAll('.sb-block-grid-widget__item');
    for (let i = 0; i < itemMarkupEls.length; i++) {
        const itemMarkupEl = itemMarkupEls.item(i);
        const item = extractBlockGridWidgetItem(itemMarkupEl as HTMLElement);
        widget.items.push(item);
    }
    return widget;
}

function extractBlockGridWidgetItem(itemMarkupEl: HTMLElement): BlockGridWidgetItem {
    const item = new BlockGridWidgetItem();

    const titleEl = itemMarkupEl.querySelector('.sb-block-grid-widget__item__title') as HTMLElement;
    const descriptionEl = itemMarkupEl.querySelector('.sb-block-grid-widget__item__description') as HTMLElement;
    const imageEl = itemMarkupEl.querySelector('.sb-block-grid-widget__item__image') as HTMLElement;

    item.enabled = readBoolean(itemMarkupEl.dataset.enabled);
    item.layout = readString(itemMarkupEl.dataset.layout) as BlockGridWidgetBlockLayout;
    item.backgroundColor = itemMarkupEl.dataset.backgroundColor;
    item.textColor = itemMarkupEl.dataset.textColor;
    item.href = itemMarkupEl.dataset.href;
    item.hrefNewTab = isNotBlank(itemMarkupEl.dataset.hrefNewTab) ? itemMarkupEl.dataset.hrefNewTab === 'true' : false;
    item.imagePosition = itemMarkupEl.dataset.imagePosition as BlockGridWidgetBlockImagePosition;

    if (titleEl != null) {
        item.title = titleEl?.textContent;
    }

    if (descriptionEl != null) {
        item.description = descriptionEl?.textContent;
    }

    if (imageEl != null) {
        item.imageSrc = imageEl.dataset.src;
        item.imageAlt = imageEl.dataset.alt;
    }

    return item;
}

function readBoolean(datasetValue?: string): boolean {
    return datasetValue !== 'false';
}

function readString(datasetValue?: string): string {
    return datasetValue;
}
