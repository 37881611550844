import {ChangeDetectionStrategy, Component} from '@angular/core';
import {Settings} from '@common/core/config/settings.service';

@Component({
    selector: 'help-button',
    templateUrl: './help-button.component.html',
    styleUrls: ['./help-button.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HelpButtonComponent {

    link?: string;
    tooltip: string;

    constructor(
        settings: Settings,
    ) {
        const helpSettings = settings.getJson('help.pdf');
        this.link = helpSettings?.link;
        this.tooltip = helpSettings?.title ?? 'Help';
    }

}
