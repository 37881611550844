import {Component, Inject, OnInit, Optional} from '@angular/core';
import {OverlayPanelRef} from '@common/core/ui/overlay-panel/overlay-panel-ref';
import {BehaviorSubject} from 'rxjs';
import {FormBuilder} from '@angular/forms';
import {OVERLAY_PANEL_DATA} from '@common/core/ui/overlay-panel/overlay-panel-data';
import {skip} from 'rxjs/operators';
import {BuilderStateService} from '../../builder-state.service';
import {ActiveProject} from '../../projects/active-project';
import {MediaSelectModalComponent, MediaSelectModalData} from '@common/media/media-select-modal/media-select-modal.component';
import {FileEntry, FileEntryType} from '@common/uploads/types/file-entry';
import {Modal} from '@common/core/ui/dialogs/modal.service';
import {emptyStringsToUndefinedInObject} from '@common/core/utils/empty-strings-to-undefined-in-object';
import {endsWithAny, isNotBlank} from '../../../shared/util/string-util';
import {getRelativeProjectUploadUrl} from '@common/uploads/utils/get-relative-project-upload-url';
import {ProjectUrl} from '../../../shared/projects/project-url.service';
import {SUPPORTED_FILE_ENDINGS_ALL} from '@common/core/utils/file-util';
import {isAbsoluteUrl} from '@common/core/utils/is-absolute-url';

const LINK_TYPES = ['none', 'url', 'page', 'anchor', 'download', 'email', 'pdf', 'file'];

export interface LinkEditorValue {
    href: string;
    download?: string;
    target?: string;
    rel?: string;
}

@Component({
    selector: 'link-editor',
    templateUrl: './link-editor.component.html',
    styleUrls: ['./link-editor.component.scss'],
})
export class LinkEditorComponent implements OnInit {
    linkTypes = LINK_TYPES;
    public hrefModel: string;
    anchors$ = new BehaviorSubject<string[]>([]);
    selectedType$ = new BehaviorSubject<string>('url');
    form = this.fb.group({
        href: '',
        download: '',
        target: '',
        subject: '',
        rel: '',
    });

    constructor(
        private projectUrl: ProjectUrl,
        private modal: Modal,
        public state: BuilderStateService,
        private fb: FormBuilder,
        @Inject(OVERLAY_PANEL_DATA)
        @Optional()
        public data: { link?: HTMLLinkElement },
        public activeProject: ActiveProject,
        @Inject(OverlayPanelRef) @Optional() public overlayRef: OverlayPanelRef
    ) {
        if (data.link?.nodeName.toLowerCase() === 'img' && data.link?.parentElement?.nodeName.toLowerCase() === 'a') {
            data.link = data.link.parentElement as HTMLLinkElement;
        }
        this.hrefModel = data.link?.href?.replace(data.link.baseURI, '');

    }

    ngOnInit() {
        this.gatherActivePageAnchors();
        this.hydrateForm();
        this.bindToSelectedTypeChange();
    }

    submit() {
        const value: LinkEditorValue = {
            href: '',
            download: undefined,
            target: undefined,
            rel: undefined,
        };
        if (this.selectedType$.value === 'none') {
            value.href = '';
        } else if (this.selectedType$.value === 'url') {
            let url: string = this.form.value.href ?? '';
            if (!/^([a-zA-Z]+:)/.test(url)) {
                url = 'https://' + url;
            }
            value.href = `${url}`;
            value.target = `${this.form.value.target}`;
            value.rel = `${this.form.value.rel}`;
        } else if (this.selectedType$.value === 'page') {
            value.href = `./${this.form.value.href}`;
            value.target = `${this.form.value.target}`;
        } else if (this.selectedType$.value === 'anchor') {
            value.href = `#${this.form.value.href}`;
        } else if (this.selectedType$.value === 'email') {
            const subjectPart = isNotBlank(this.form.value.subject) ? `?subject=${this.form.value.subject.trim()}` : '';
            value.href = `mailto:${this.form.value.href}${subjectPart}`;
        } else if (this.selectedType$.value === 'download') {
            value.href = `${this.form.value.href}`;
            value.download = `${this.form.value.download}`;
            value.rel = `${this.form.value.rel}`;
        } else if (this.selectedType$.value === 'pdf') {
            value.href = this.hrefModel;
            value.target = '_blank';
        } else if (this.selectedType$.value === 'file') {
            value.href = this.hrefModel;
            value.target = '_blank';
        }
        this.close(emptyStringsToUndefinedInObject(value));
    }

    public openUploadPdfModal(event) {
        return this.openUploadFileModal(event, FileEntryType.pdf);
    }

    public openUploadFileModal(event, filterType?: FileEntryType) {
        event.preventDefault();
        return this.modal.show(MediaSelectModalComponent, {
            filterType,
            multiselect: false,
            showProjects: true,
        } as MediaSelectModalData).afterClosed().subscribe((data?: FileEntry[]) => {
            if (!data) return;

            const media = data[0];

            if (!media) return;

            const relativeUrl = getRelativeProjectUploadUrl(media.url);

            this.hrefModel = relativeUrl;
        });
    }

    close(value?: LinkEditorValue) {
        this.overlayRef.close(value);
    }

    private gatherActivePageAnchors() {
        this.anchors$.next(
            Array.from(this.state.previewDoc.querySelectorAll('*[id]')).map(
                (el: HTMLElement) => el.id
            )
        );
    }

    private bindToSelectedTypeChange() {
        this.selectedType$.pipe(skip(1)).subscribe(() => {
            let href = '';
            if (this.selectedType$.value === 'page') {
                href = this.state.pages$.value[0].name;
            } else if (this.selectedType$.value === 'anchor') {
                href = this.anchors$.value[0];
            }
            this.hrefModel = href;
            this.form.patchValue({
                href
            });
        });
    }

    private hydrateForm() {
        if (!this.data.link) return;
        let href = this.data.link.getAttribute('href') ?? '';
        const target = this.data.link.getAttribute('target') ?? '';
        const rel = this.data.link.getAttribute('rel') ?? '';

        if (this.data.link.getAttribute('download')) {
            this.selectedType$.next('download');
            const fileName = this.data.link.getAttribute('download');
            this.form.patchValue({href, download: fileName, rel});
        } else if (href.startsWith('mailto:')) {
            this.selectedType$.next('email');
            const mailParts = href.split('?subject=');
            href = mailParts[0].replace('mailto:', '');
            const subject = isNotBlank(mailParts[1]) ? mailParts[1].trim() : '';
            this.form.patchValue({href, subject});
        } else if (href.startsWith('#')) {
            this.selectedType$.next('anchor');
            this.form.patchValue({href: href.substring(1)});
        } else if (href === '') {
            this.selectedType$.next('none');
        } else if (this.isPageUrl(href)) {
            this.selectedType$.next('page');
            this.form.patchValue({
                href: href
                    .replace('.html', '')
                    .replace('./', ''),
                target
            });
        } else if (!isAbsoluteUrl(href) && href.endsWith('.pdf')) {
            this.selectedType$.next('pdf');
            this.form.patchValue({href});
        } else if (!isAbsoluteUrl(href) && endsWithAny(href, SUPPORTED_FILE_ENDINGS_ALL)) {
            this.selectedType$.next('file');
            this.form.patchValue({href});
        } else {
            this.selectedType$.next('url');
            this.form.patchValue({href, target, rel});
        }
    }

    private isPageUrl(url: string): boolean {
        const pages = this.state.pages$.value;
        const pageUrls = pages.map(page => page.name);

        for (const pageUrl of pageUrls) {
            if (url.match(new RegExp(`^(\./)?${pageUrl}$`))) {
                return true;
            }
        }

        return false;
    }

    public getFilePreviewHref(url: string): string {
        return `storage/${this.activeProject.getBaseUrl(true)}${url}`;
    }
}
