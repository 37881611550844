<div class="folders" *ngIf="folders?.length > 0">
    <media-grid-folder *ngFor="let folder of folders"
                       [folderName]="folder"
                       (folderClick)="onFolderClick(folder)">
    </media-grid-folder>
</div>
<div class="grid">
    <media-grid-card *ngFor="let file of files"
                     [entry]="file"
                     [selectable]="selectable"
                     [selected]="selection.isSelected(file)"
                     (selectionChange)="onCardSelect($event)">
    </media-grid-card>
</div>
