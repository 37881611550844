import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {Component, Inject} from '@angular/core';
import {ThemePalette} from '@angular/material/core';

export interface ConfirmDialogData {
    title: string;
    message?: string;
    itemName?: string;
    confirmLabel?: string;
    confirmColor?: ThemePalette;
}

export class ConfirmDialogModel {
    constructor(
        public data: ConfirmDialogData,
    ) {}
}

@Component({
    selector: 'app-confirm-dialog',
    templateUrl: './confirm-dialog.component.html',
    styleUrls: ['confirm-dialog.component.scss'],
})
export class ConfirmDialogComponent {

    title: string;
    message: string;
    itemName: string;
    confirmLabel: string;
    confirmColor: ThemePalette;

    constructor(
        public dialogRef: MatDialogRef<ConfirmDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: ConfirmDialogModel
    ) {
        dialogRef.updateSize('200px');
        this.title = data.data.title;
        this.message = data.data.message;
        this.itemName = data.data.itemName;
        this.confirmLabel = data.data.confirmLabel ?? 'Confirm';
        this.confirmColor = data.data.confirmColor ?? 'accent';
    }

    onConfirm(): void {
        this.dialogRef.close(true);
    }

    onDismiss(): void {
        this.dialogRef.close(false);
    }
}
