import {Injectable} from '@angular/core';
import {SlideshowWidgetEditorModalComponent, SlideshowWidgetEditorModalComponentData} from './slideshow-widget-editor-modal/slideshow-widget-editor-modal.component';
import {MatDialogRef} from '@angular/material/dialog/dialog-ref';
import {Modal} from '@common/core/ui/dialogs/modal.service';
import {SlideshowWidget} from '../slideshow-widget.model';

@Injectable({
    providedIn: 'root',
})
export class SlideshowWidgetEditorModal {
    public modalRef: MatDialogRef<SlideshowWidgetEditorModalComponent>;

    constructor(
        public modal: Modal
    ) {}

    open(
        origin: HTMLElement,
        slideshowWidget: SlideshowWidget
    ): MatDialogRef<SlideshowWidgetEditorModalComponent> {
        const data: SlideshowWidgetEditorModalComponentData = {
            slideshowWidget,
        };
        return (this.modalRef = this.modal.open(SlideshowWidgetEditorModalComponent, data));
    }

    close() {
        this.modalRef?.close();
    }
}
