import {Component, Inject, OnInit, Optional} from '@angular/core';
import {BuilderStateService} from '../../../../builder-state.service';
import {CdkDragDrop, moveItemInArray} from '@angular/cdk/drag-drop';
import {ImageElementService} from '../../../image-element.service';
import {ProjectUrl} from '../../../../../shared/projects/project-url.service';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {BlockGridWidget, BlockGridWidgetItem} from '../block-grid-widget.model';

export interface BlockGridWidgetEditorModalComponentData {
    widget: BlockGridWidget;
}

@Component({
    selector: 'block-grid-widget-editor-modal',
    templateUrl: './block-grid-widget-editor-modal.component.html',
    styleUrls: ['./block-grid-widget-editor-modal.component.scss'],
})
export class BlockGridWidgetEditorModalComponent implements OnInit {

    loading = false;
    _processing = false;
    advancedOptionsVisible = false;

    constructor(
        private projectUrl: ProjectUrl,
        public state: BuilderStateService,
        public imageElementService: ImageElementService,
        @Inject(MAT_DIALOG_DATA)
        @Optional()
        public data: BlockGridWidgetEditorModalComponentData,
        @Inject(MatDialogRef) @Optional() public modalRef: MatDialogRef<BlockGridWidgetEditorModalComponent>
    ) {
    }

    ngOnInit() {
        this.data.widget.items = (this.data.widget.items ?? []).map((item: BlockGridWidgetItem) => {
            return {
                ...item,
                layout: item.layout ?? 'x1_y1',
                imagePosition: item.imagePosition ?? 'start',
            };
        });
    }

    submit() {
        this.close(this.data.widget);
    }

    close(value?: BlockGridWidget) {
        this.modalRef.close(value);
    }

    drop(event: CdkDragDrop<string[]>) {
        moveItemInArray(this.data.widget.items, event.previousIndex, event.currentIndex);
    }

    addItem() {
        const newItem = new BlockGridWidgetItem();
        this.data.widget.items.push(newItem);
    }

    removeItem(itemIndex: number) {
        this.data.widget.items.splice(itemIndex, 1);
    }

    canMoveUp(itemIndex: number) {
        return itemIndex > 0;
    }

    moveUp(itemIndex: number) {
        const slide = this.data.widget.items[itemIndex];
        this.data.widget.items[itemIndex] = this.data.widget.items[itemIndex - 1];
        this.data.widget.items[itemIndex - 1] = slide;
    }

    canMoveDown(itemIndex: number) {
        return itemIndex < this.data.widget.items.length - 1;
    }

    moveDown(itemIndex: number) {
        const item = this.data.widget.items[itemIndex];
        this.data.widget.items[itemIndex] = this.data.widget.items[itemIndex + 1];
        this.data.widget.items[itemIndex + 1] = item;
    }

    getImageSrc(item: BlockGridWidgetItem): string | undefined {
        if (item.imageSrc == null || item.imageSrc.length === 0) {
            return undefined;
        }
        return `${this.projectUrl.getBaseUrl(this.state.project$.value.model)}/${item.imageSrc}`;
    }

    updateImage(item: BlockGridWidgetItem) {
        this._processing = true;
        this.imageElementService.openModal().subscribe((url: string) => {
            this._processing = false;
            if (!url) return;
            item.imageSrc = url;
        });
    }

    deleteImage(item: BlockGridWidgetItem) {
        item.imageSrc = undefined;
        item.imageAlt = undefined;
    }

}
