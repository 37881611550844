<div>
    <div class="modal-header">
        <h2 class="modal-title" mat-dialog-title trans>Card Carousel</h2>
        <button type="button" (click)="close()" class="close-button no-style" tabindex="-1">
            <mat-icon svgIcon="close"></mat-icon>
        </button>
    </div>
    <loading-bar *ngIf="loading"></loading-bar>
</div>

<mat-dialog-content>
    <div cdkDropList class="list" (cdkDropListDropped)="drop($event)">
        <div class="list-item" *ngFor="let card of data.widget.cards; let i = index" cdkDrag [cdkDragDisabled]="loading">
            <button cdkDragHandle mat-icon-button class="drag-handle">
                <mat-icon svgIcon="drag-handle" matListIcon></mat-icon>
            </button>

            <div class="col col-grow many-inputs">
                <div class="row" style="align-items: center;">
                    <div class="col col-grow">
                        <mat-slide-toggle [(ngModel)]="card.enabled" trans>
                            Enabled
                        </mat-slide-toggle>
                    </div>
                    <div class="col col-grow slide-actions">
                        <div class="row" style="justify-content: end;">
                            <button type="button" mat-icon-button *ngIf="canMoveUp(i)"
                                    (click)="moveUp(i)" [matTooltip]="'Move up' | trans" [disabled]="_processing">
                                <mat-icon svgIcon="move-up"></mat-icon>
                            </button>
                            <button type="button" mat-icon-button *ngIf="canMoveDown(i)"
                                    (click)="moveDown(i)" [matTooltip]="'Move down' | trans" [disabled]="_processing">
                                <mat-icon svgIcon="move-down"></mat-icon>
                            </button>
                            <button type="button" mat-icon-button
                                    (click)="removeItem(i)" [matTooltip]="'Remove' | trans" [disabled]="_processing">
                                <mat-icon svgIcon="delete"></mat-icon>
                            </button>
                        </div>
                    </div>
                </div>

                <div class="row">
                    <div class="col col-grow many-inputs">
                        <div class="input-container">
                            <label for="title" trans>Title</label>
                            <input type="text" id="title" name="title" [(ngModel)]="card.title" [disabled]="_processing">
                        </div>
                        <div class="input-container">
                            <label for="subtitle" trans>Subtitle</label>
                            <input type="text" id="subtitle" name="subtitle" [(ngModel)]="card.subtitle" [disabled]="_processing">
                        </div>
                        <div class="input-container">
                            <label for="text" trans>Text</label>
                            <textarea type="text" id="text" name="text" rows="3"
                                      [(ngModel)]="card.text" [disabled]="_processing"></textarea>
                        </div>
                    </div>
                    <div class="col many-inputs">
                        <div class="input-container input-container-bg-image">
                            <div class="input-container">
                                <label for="mediaType" trans>Media type</label>
                                <ng-select id="mediaType" name="mediaType"
                                           appearance="outline"
                                           appendTo="body"
                                           [clearable]="false"
                                           [(ngModel)]="card.mediaType"
                                           [ngModelOptions]="{standalone: true}">
                                    <ng-option [value]="''" trans>None</ng-option>
                                    <ng-option [value]="'image'" trans>Image</ng-option>
                                    <ng-option [value]="'video'" trans>Video (Youtube, Vimeo)</ng-option>
                                </ng-select>
                            </div>
                        </div>
                        <div class="input-container" *ngIf="card.mediaType != null && card.mediaType != ''">
                            <label *ngIf="card.mediaType === 'image'" trans>Image</label>
                            <label *ngIf="card.mediaType === 'video'" trans>Thumbnail</label>
                            <div class="image-preview"
                                 *ngIf="getImageSrc(card)"
                                 (click)="!_processing && updateImage(card)"
                                 [matTooltip]="'Change image' | trans">
                                <img [src]="getImageSrc(card)" [alt]="card.imageAlt">
                            </div>
                            <div class="action-buttons">
                                <button type="button" class="action-button" mat-stroked-button color="accent"
                                        (click)="!_processing && updateImage(card)" trans>Select
                                </button>
                                <button type="button" class="action-button delete-button" mat-icon-button
                                        [matTooltip]="'Remove background image' | trans"
                                        *ngIf="getImageSrc(card)"
                                        (click)="!_processing && deleteImage(card)">
                                    <mat-icon svgIcon="delete"></mat-icon>
                                </button>
                            </div>
                        </div>
                        <ng-container *ngIf="getImageSrc(card)">
                            <div class="input-container">
                                <label for="imageAlt" trans>Alt label</label>
                                <input type="text" id="imageAlt" name="imageAlt" [(ngModel)]="card.imageAlt"
                                       [disabled]="_processing">
                            </div>
                        </ng-container>
                        <ng-container *ngIf="card.mediaType === 'video'">
                            <div class="input-container">
                                <label for="videoUrl" trans>Video URL (Youtube, Vimeo)</label>
                                <input type="text" id="videoUrl" name="videoUrl"
                                       [(ngModel)]="card.videoUrl"
                                       (ngModelChange)="videoUrlChanged(card)"
                                       [disabled]="_processing">
                            </div>
                        </ng-container>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <button mat-button (click)="advancedOptionsVisible = !advancedOptionsVisible" trans>Advanced settings</button>

    <div class="row" *ngIf="advancedOptionsVisible">
        <div class="col col-grow many-inputs">
            <div class="input-container">
                <label for="titleColor" trans>Title color</label>
                <color-input id="titleColor" [(ngModel)]="data.widget.titleColor" [disabled]="_processing"></color-input>
            </div>
            <div class="input-container">
                <label for="subtitleColor" trans>Subtitle color</label>
                <color-input id="subtitleColor" [(ngModel)]="data.widget.subtitleColor" [disabled]="_processing"></color-input>
            </div>
            <div class="input-container">
                <label for="textColor" trans>Text color</label>
                <color-input id="textColor" [(ngModel)]="data.widget.textColor" [disabled]="_processing"></color-input>
            </div>
        </div>
        <div class="col col-grow many-inputs">
            <div class="input-container">
                <label for="cardInactiveOverlayColor" trans>Inactive card overlay color</label>
                <color-input id="cardInactiveOverlayColor" [(ngModel)]="data.widget.cardInactiveOverlayColor" [disabled]="_processing"></color-input>
            </div>
            <div class="input-container">
                <label for="mediaPlayButtonColor" trans>Media play button color</label>
                <color-input id="mediaPlayButtonColor" [(ngModel)]="data.widget.mediaPlayButtonColor" [disabled]="_processing"></color-input>
            </div>
            <div class="input-container">
                <label for="controlsColor" trans>Controls color</label>
                <color-input id="controlsColor" [(ngModel)]="data.widget.controlsColor" [disabled]="_processing"></color-input>
            </div>
        </div>
    </div>
</mat-dialog-content>

<mat-dialog-actions>
    <button (click)="addItem()" mat-raised-button class="add-button" [disabled]="loading" trans>Add card</button>
    <button (click)="close()" type="button" mat-button trans>Cancel</button>
    <button (click)="submit()" mat-raised-button color="accent" [disabled]="loading" trans>Apply</button>
</mat-dialog-actions>
