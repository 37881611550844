import {BaseMutation} from '../base-mutation';
import {insertNodeAtIndex} from '../../utils/insert-node-at-index';

export class InsertScript extends BaseMutation {
    constructor(
        protected scriptContent: string,
        protected id: string,
    ) {
        super(null);
    }

    protected executeMutation(doc: Document): boolean {
        const parentEl = doc.querySelector('body');
        const existingEl = doc.querySelector(`script#${this.id}`);

        if (existingEl instanceof HTMLScriptElement) {
            console.log('Replacing existing script');
            existingEl.text = this.scriptContent;
        } else {
            console.log('Inserting new script');
            const el = this.buildElement();
            insertNodeAtIndex(el, parentEl, 99999);
        }

        return true;
    }

    protected undoMutation(doc: Document): boolean {
        const insertedEl = this.findEl(doc);
        if (insertedEl != null) {
            insertedEl.remove();
            return true;
        }
    }

    private buildElement(): HTMLScriptElement {
        const scriptEl = (document.createElement('script')) as HTMLScriptElement;
        scriptEl.id = this.id;
        scriptEl.text = this.scriptContent;
        return scriptEl;
    }
}
