<div>
  <div class="modal-header">
    <h2 class="modal-title" mat-dialog-title trans>Image Gallery</h2>
    <button type="button" (click)="close()" class="close-button no-style" tabindex="-1">
      <mat-icon svgIcon="close"></mat-icon>
    </button>
  </div>
  <loading-bar *ngIf="loading"></loading-bar>
</div>

<mat-dialog-content>
  <div cdkDropList class="list" (cdkDropListDropped)="drop($event)">
    <div class="list-item" *ngFor="let item of data.widget.items; let i = index" cdkDrag [cdkDragDisabled]="loading">
      <button cdkDragHandle mat-icon-button class="drag-handle"><mat-icon svgIcon="drag-handle" matListIcon></mat-icon></button>
      <div class="image-preview" (click)="!loading && updateItemImage(item)" [matTooltip]="'Change image' | trans">
        <img [src]="getImageUrl(item)" [alt]="item.label">
      </div>
      <div class="input-container">
        <input type="text" [(ngModel)]="item.label" [placeholder]="'Description' | trans" [disabled]="loading">
      </div>
      <button class="delete-button" mat-icon-button (click)="removeItem(i)" [disabled]="loading"><mat-icon svgIcon="delete" matListIcon></mat-icon></button>
    </div>
  </div>
</mat-dialog-content>

<mat-dialog-actions>
  <button (click)="addItem()" mat-raised-button class="add-button" [disabled]="loading" trans>Add image</button>
  <button (click)="close()" type="button" mat-button trans>Cancel</button>
  <button (click)="submit()" mat-raised-button color="accent" [disabled]="loading" trans>Apply</button>
</mat-dialog-actions>
