import {ChangeDetectionStrategy, Component, EventEmitter, Input, Output} from '@angular/core';

@Component({
    selector: 'position-grid-control',
    templateUrl: './position-grid-control.component.html',
    styleUrls: ['./position-grid-control.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class PositionGridControlComponent {

    @Input()
    value?: string;

    @Output()
    valueChanged = new EventEmitter<string>();

    setPosition(position: string): void {
        this.valueChanged.emit(position);
    }

}
