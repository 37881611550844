import {ArchitectElement} from '../../architect-element';
import {addIdToNode} from '../../../utils/add-id-to-node';
import {AccordionWidgetItemEl} from './accordion-widget.item';
import {MutationsService} from '../../../mutations/mutations.service';
import {InsertNode} from '../../../mutations/dom/insert-node';


function generateTemplate(): string {
    return `<div class="accordion">` + AccordionWidgetItemEl.buildTemplateAsString() + `</div>`;
}

export class AccordionWidgetEl extends ArchitectElement {
    name = 'Accordion';
    contentCategories = ['flow'];
    html = generateTemplate;
    allowedContent = ['accordion-item'];
    category = 'components';
    icon = 'accordion';
    specificity = 5;
    hiddenClasses = ['accordion'];
    canCopy = false;
    editActions = [{
        name: 'Add Element',
        onClick: (node: HTMLElement) => {
            const newElement = AccordionWidgetItemEl.buildTemplate();
            addIdToNode(newElement);

            this.get(MutationsService).execute(
                new InsertNode(newElement, node.children.length, node.dataset.arId)
            );
        }
    }];

    matcher(node: HTMLElement) {
        return node.closest('.accordion') as HTMLElement;
    }
}
