import {ChangeDetectionStrategy, Component} from '@angular/core';
import {Toast} from '@common/core/ui/toast.service';
import {AppHttpClient} from '@common/core/http/app-http-client.service';

@Component({
    selector: 'clear-cache-button',
    templateUrl: './clear-cache-button.component.html',
    styleUrls: ['./clear-cache-button.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ClearCacheButtonComponent {

    constructor(
        private http: AppHttpClient,
        private toast: Toast,
    ) {
    }

    public clearCache() {
        this.http.post('cache/flush', {})
            .subscribe(() => {
                this.toast.open('Cache cleared.');
            });
    }

}
