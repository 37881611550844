import {Inject, LOCALE_ID, Pipe, PipeTransform} from '@angular/core';
import {DatePipe} from '@angular/common';
import {Settings} from '../../config/settings.service';

@Pipe({
    name: 'formattedDateTime'
})
export class FormattedDateTimePipe implements PipeTransform {
    private readonly format: string;
    private angularPipe: DatePipe;

    /**
     * FormattedDateTimePipe Constructor.
     */
    constructor(private settings: Settings, @Inject(LOCALE_ID) private _locale: string) {
        this.format = this.settings.get('datetimes.format', 'yyyy-MM-dd HH:mm');
        this.angularPipe = new DatePipe(_locale);
    }

    transform(value: any, format?: string, timezone?: string, locale?: string): string | null {
        // slash as separator for date will cause parse errors
        value = (value && typeof value === 'string' ) ? value.replace(/\//g, '-') : value;
        return this.angularPipe.transform(value, format || this.format, timezone, locale);
    }
}
