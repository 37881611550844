import {ChangeDetectionStrategy, Component, forwardRef, OnDestroy, OnInit} from '@angular/core';
import {ControlValueAccessor, FormBuilder, FormGroup, NG_VALUE_ACCESSOR} from '@angular/forms';
import {Subscription} from 'rxjs';

@Component({
    selector: 'link-rel-input',
    templateUrl: './link-rel-input.component.html',
    styleUrls: ['./link-rel-input.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [{
        provide: NG_VALUE_ACCESSOR,
        useExisting: forwardRef(() => LinkRelInputComponent),
        multi: true
    }],
})
export class LinkRelInputComponent implements OnInit, OnDestroy, ControlValueAccessor {

    formGroup: FormGroup;
    formGroupValueChangesSubscription: Subscription;

    private _onChange: (ref: string) => void = () => {};
    private _onTouched: () => void = () => {};

    constructor(
        readonly fb: FormBuilder,
    ) {
        this.formGroup = fb.group({
            nofollow: fb.control([false]),
            noreferrer: fb.control([false]),
        });
    }

    ngOnInit(): void {
        this.formGroupValueChangesSubscription = this.formGroup.valueChanges.subscribe(() => {
            const relValues = [];
            if (this.formGroup.get('nofollow').value) {
                relValues.push('nofollow');
            }
            if (this.formGroup.get('noreferrer').value) {
                relValues.push('noreferrer');
            }
            const rel = relValues.join(' ');
            this._onChange(rel);
            this._onTouched();
        });
    }

    ngOnDestroy() {
        this.formGroupValueChangesSubscription.unsubscribe();
    }

    registerOnChange(fn: any): void {
        this._onChange = fn;
    }

    registerOnTouched(fn: any): void {
        this._onTouched = fn;
    }

    setDisabledState(isDisabled: boolean): void {
        isDisabled ? this.formGroup.disable() : this.formGroup.enable();
    }

    writeValue(ref: string): void {
        this.formGroup.patchValue({
            nofollow: ref?.includes('nofollow'),
            noreferrer: ref?.includes('noreferrer'),
        });
    }
}
