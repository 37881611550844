import {ArchitectElement} from '../../architect-element';
import {MutationsService} from '../../../mutations/mutations.service';
import {ReplaceNodeContent} from '../../../mutations/dom/replace-node-content';
import {extractCardCarouselWidget} from './card-carousel-widget-markup-extractor';
import {generateCardCarouselWidgetMarkup} from './card-carousel-widget-markup-generator';
import {CardCarouselWidget} from './card-carousel-widget.model';
import {CardCarouselWidgetEditor} from './card-carousel-widget-editor.service';

const template = `<div class="sb-card-carousel-widget">
    <div class="sb-card-carousel-widget__card"></div>
</div>`;

export class CardCarouselWidgetEl extends ArchitectElement {
    name = 'Card Carousel';
    contentCategories = ['flow'];
    html = template;
    allowedContent = [];
    category = 'components';
    icon = 'card-carousel';
    specificity = 5;
    hiddenClasses = ['sb-card-carousel-widget'];
    editActions = [
        {
            name: 'Edit',
            onClick: (node: HTMLElement) => {
                const widget = extractCardCarouselWidget(node);
                this.get(CardCarouselWidgetEditor)
                    .open(node, widget)
                    .afterClosed()
                    .subscribe((updatedWidget?: CardCarouselWidget) => {
                        if (updatedWidget != null) {
                            const newInnerHTML = generateCardCarouselWidgetMarkup(updatedWidget);
                            this.get(MutationsService).execute(
                                new ReplaceNodeContent(node, node.innerHTML, newInnerHTML)
                            );
                        }
                    });
            },
        },
    ];

    matcher(node: HTMLElement) {
        return node.closest('.sb-card-carousel-widget') as HTMLElement;
    }
}
