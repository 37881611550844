import {FileEntryType} from '../../uploads/types/file-entry';

export const SUPPORTED_FILE_ENDINGS_IMAGE: string[] = ['jpg', 'jpeg', 'png', 'gif', 'bmp', 'tif', 'tiff', 'svg', 'webp'];
export const SUPPORTED_FILE_ENDINGS_VIDEO: string[] = ['mp4', 'm4p', 'm4v', 'flv', 'ogv', 'mov', 'qt', 'wmv', 'avi', 'avchd', 'flv', 'f4v', 'swf', 'mkv', 'webm', 'mpeg', 'mpg', 'mp2', 'mpe', 'mpv'];
export const SUPPORTED_FILE_ENDINGS_AUDIO: string[] = ['mp3', 'mp4', 'flac', 'm4a', 'wav', 'wma', 'aac'];
export const SUPPORTED_FILE_ENDINGS_PDF: string[] = ['pdf'];
export const SUPPORTED_FILE_ENDINGS_FILE: string[] = ['xls', 'xlsx', 'doc', 'docx', 'ppt', 'pptx'];
export const SUPPORTED_FILE_ENDINGS_ALL: string[] = [
    ...SUPPORTED_FILE_ENDINGS_IMAGE,
    ...SUPPORTED_FILE_ENDINGS_VIDEO,
    ...SUPPORTED_FILE_ENDINGS_AUDIO,
    ...SUPPORTED_FILE_ENDINGS_PDF,
    ...SUPPORTED_FILE_ENDINGS_FILE
];

export function getFileTypeFromPath(path?: string): string {
    if (!path) {
        return FileEntryType.file;
    }

    const fileEnding = getFileEnding(path);

    if (SUPPORTED_FILE_ENDINGS_IMAGE.includes(fileEnding)) {
        return FileEntryType.image;
    } else if (SUPPORTED_FILE_ENDINGS_VIDEO.includes(fileEnding)) {
        return FileEntryType.video;
    } else if (SUPPORTED_FILE_ENDINGS_AUDIO.includes(fileEnding)) {
        return FileEntryType.audio;
    } else if (SUPPORTED_FILE_ENDINGS_PDF.includes(fileEnding)) {
        return FileEntryType.pdf;
    } else if (SUPPORTED_FILE_ENDINGS_FILE.includes(fileEnding)) {
        return FileEntryType.file;
    }

    return FileEntryType.file;
}

export function getFileEnding(path: string): string {
    const dotIndex = path.lastIndexOf('.');
    if (dotIndex === -1) {
        return '';
    }
    return path.substring(dotIndex + 1).toLowerCase();
}

export function extractFileNameOfPath(path: string): string {
    const segments = path.split('/');
    const fileName = segments[segments.length - 1];
    return fileName;
}

export function getFoldersFromPath(path?: string) {
    const segments = (path ?? '').split('/');
    segments.splice(segments.length - 1, 1);
    return segments.join('/') + '/';
}

export function getFolderPathLevel(path: string) {
    const pathSegmentCount = path.split('/').length;
    if (path.startsWith('/')) {
        return pathSegmentCount - 2;
    } else {
        return pathSegmentCount - 1;
    }
}

export function removeTopPathLevels(path: string, levels: number): string {
    let result = path;
    for (let i = 0; i < levels; i++) {
        const index = result.indexOf('/', 1);
        result = result.substring(index);
    }
    return result;
}
