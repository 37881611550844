import {VideoRef, VideoType} from './Video';

export function getVideoUrlById(type: VideoType, videoID: string): string {
    if (type === VideoType.youtube) {
        return 'https://youtube.com/watch?v=' + videoID;
    } else if (type === VideoType.vimeo) {
        return 'https://vimeo.com/' + videoID;
    }
}

export async function getVideoThumbnailUrlById(type: VideoType, videoID: string): Promise<string | undefined> {
    if (type === VideoType.youtube) {
        return `https://img.youtube.com/vi/${videoID}/maxresdefault.jpg`;
    } else if (type === VideoType.vimeo) {
        try {
            const response = await fetch(`https://vimeo.com/api/oembed.json?url=https://vimeo.com/${videoID}`);
            if (!response.ok) {
                return undefined;
            }
            const body = await response.json();
            return body.thumbnail_url;
        } catch (e) {
            return undefined;
        }
    }
}

export function getVideoUrlByUrl(url?: string): string | undefined {
    const videoRef = getVideoRef(url);

    if (videoRef?.type === VideoType.youtube) {
        return `https://youtube.com/watch?v=${videoRef.id}`;
    } else if (videoRef?.type === VideoType.vimeo) {
        return `https://vimeo.com/${videoRef.id}`;
    }

    return undefined;
}

export function getEmbeddedVideoUrl(url?: string): string | undefined {
    const videoRef = getVideoRef(url);

    if (videoRef?.type === VideoType.youtube) {
        return `https://www.youtube-nocookie.com/embed/${videoRef.id}`;
    } else if (videoRef?.type === VideoType.vimeo) {
        return `https://player.vimeo.com/video/${videoRef.id}?dnt=1`;
    }

    return undefined;
}

export function getVideoRef(url?: string): VideoRef | undefined {
    if (url == null) {
        return undefined;
    }

    if (url.includes('youtube.com')) {
        const matches = url.match(/v=([^&#]*)/);
        const videoId = matches != null && matches[1] != null ? matches[1] : undefined;
        if (videoId != null) {
            return {
                type: VideoType.youtube,
                id: videoId,
            };
        }
    } else if (url.includes('youtu.be') || url.includes('youtube-nocookie.com')) {
        const parts = url.split('/');
        const videoId = parts.length > 0 ? parts[parts.length - 1] : undefined;
        if (videoId != null) {
            return {
                type: VideoType.youtube,
                id: videoId,
            };
        }
    } else if (url.includes('vimeo.com')) {
        const parts = url.split('/');
        const videoId = parts.length > 0 ? parts[parts.length - 1] : undefined;
        if (videoId != null) {
            return {
                type: VideoType.vimeo,
                id: videoId,
            };
        }
    }

    return undefined;
}
