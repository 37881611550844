export class DependencyQueueItem<T> {
    private readonly key: string;
    private readonly dependencies: string[];
    private readonly data: T;

    openDependencies: string[];

    constructor(key: string, dependencies: string[] = [], data?: T) {
        this.key = key;
        this.dependencies = dependencies;
        this.data = data;

        this.openDependencies = dependencies.slice();
    }

    public getKey(): string {
        return this.key;
    }

    public getDependencies(): string[] {
        return this.dependencies;
    }

    public getData(): T {
        return this.data;
    }
}

export class DependencyQueue<T = any> {

    private queueOpen: Map<string, DependencyQueueItem<T>> = new Map();
    private queueProcessed: Map<string, DependencyQueueItem<T>> = new Map();

    public add(key: string, dependencies: string[] = [], data?: T): DependencyQueueItem<T> {
        const item = new DependencyQueueItem(key, dependencies, data);
        this.queueOpen.set(key, item);
        return item;
    }

    public getNext(): DependencyQueueItem<T> | undefined {
        for (const item of this.getAllOpen()) {
            if (item.openDependencies.length === 0) {
                return item;
            }
        }

        if (this.queueOpen.size > 0) {
            throw new Error('no next item, but open queue still has items');
        }

        return undefined;
    }


    public markProcessed(queueItem: DependencyQueueItem<T>): void {
        const itemKey = queueItem.getKey();

        for (const item of this.getAllOpen()) {
            const foundIndex = item.openDependencies.findIndex((dependency) => dependency === itemKey);
            if (foundIndex !== -1) {
                // remove from open dependencies
                item.openDependencies.splice(foundIndex, 1);
            }
        }

        // move from open to processed queue
        this.queueOpen.delete(itemKey);
        this.queueProcessed.set(itemKey, queueItem);
    }

    public getAllOpen(): DependencyQueueItem<T>[] {
        return Array.from(this.queueOpen.values());
    }

    public getAllProcessed(): DependencyQueueItem<T>[] {
        return Array.from(this.queueProcessed.values());
    }

    public getOpenItem(key: string): DependencyQueueItem<T> | undefined {
        return this.queueOpen.get(key);
    }

    public getProcessedItem(key: string): DependencyQueueItem<T> | undefined {
        return this.queueProcessed.get(key);
    }
}
