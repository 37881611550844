import {ChangeDetectionStrategy, Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {ContentBlock} from '../../../shared/builder-types';
import {Project} from '../../../shared/projects/Project';
import {BehaviorSubject} from 'rxjs';
import {SelectionModel} from '@common/core/utils/SelectionModel';

interface ProjectChooserModalComponentData {
    project?: Project;
    contentBlocks?: Array<ContentBlock>;
    projects?: Project[];
}

@Component({
    selector: 'project-chooser-modal',
    templateUrl: './project-chooser-modal.component.html',
    styleUrls: ['./project-chooser-modal.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProjectChooserModalComponent implements OnInit {
    public loading$ = new BehaviorSubject(false);
    public selectionModel = new SelectionModel([], 'id');
    public allSelected = false;

    constructor(
        public dialogRef: MatDialogRef<ProjectChooserModalComponent>,
        @Inject(MAT_DIALOG_DATA) public data: ProjectChooserModalComponentData,
    ) {}

    ngOnInit() {
        this.loading$.next(true);
    }

    public getProjects() {
        return this.data.projects.filter(project => project.id !== this.data.project.id);
    }

    public async confirm() {
        this.close(this.selectionModel.values());
    }

    public close(data?: any) {
        this.dialogRef.close(data);
    }

    public selectAll() {
        this.allSelected = !this.allSelected;

        for (const project of this.getProjects()) {
            if (! this.allSelected) {
                if (this.selectionModel.isSelected(project)) {
                    this.selectionModel.toggle(project);
                }
            } else {
                if (! this.selectionModel.isSelected(project)) {
                    this.selectionModel.toggle(project);
                }
            }
        }
    }
}
