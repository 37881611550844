import {Component, ChangeDetectionStrategy, Input} from '@angular/core';

@Component({
  selector: 'alert',
  templateUrl: './alert.component.html',
  styleUrls: ['./alert.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AlertComponent {

  @Input()
  type: 'warning' | 'info' = 'warning';

}
