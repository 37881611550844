import {GalleryWidget, GalleryWidgetItem} from './gallery-widget.model';

export function extractGalleryWidget(markup: HTMLElement): GalleryWidget {
    const galleryWidget = new GalleryWidget();
    const childs = markup.querySelectorAll('.sb-gallery-widget__item');
    for (let i = 0; i < childs.length; i++) {
        const child = childs.item(i);
        const img = child.getElementsByTagName('img').item(0);
        const imgSrc = img.getAttribute('src');
        const imgAlt = img.getAttribute('alt') ?? '';
        const galleryWidgetItem = new GalleryWidgetItem(imgSrc, imgAlt);
        galleryWidget.items.push(galleryWidgetItem);
    }
    return galleryWidget;
}
