import {Component, Inject} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {FileEntry, FileEntryType} from '@common/uploads/types/file-entry';

export interface FileEntryUploadModalData {
    files?: File[];
    endpoint?: string;
    acceptType?: FileEntryType;
    ownershipType?: string;
    ownershipId?: string;
}

@Component({
    selector: 'file-entry-upload-modal',
    templateUrl: './file-entry-upload-modal.component.html',
    styleUrls: ['./file-entry-upload-modal.component.scss'],
})
export class FileEntryUploadModalComponent {

    constructor(
        private dialogRef: MatDialogRef<FileEntryUploadModalComponent>,
        @Inject(MAT_DIALOG_DATA) public data: FileEntryUploadModalData,
    ) {
    }

    /**
     * Close the modal.
     */
    public close(data?: FileEntry[]) {
        this.dialogRef.close(data);
    }

    filesUploaded(data?: FileEntry[]) {
        this.close(data);
    }
}
