import {Component, OnInit, ChangeDetectionStrategy, HostBinding} from '@angular/core';
import {Settings} from '@common/core/config/settings.service';

@Component({
  selector: 'system-alert',
  templateUrl: './system-alert.component.html',
  styleUrls: ['./system-alert.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SystemAlertComponent implements OnInit {

  @HostBinding('class.enabled') enabled: boolean;
  message: string;

  constructor(private readonly settings: Settings) { }

  ngOnInit(): void {
    const all = this.settings.getAll();
    this.enabled = this.settings.get('system.alert.enabled', false);
    this.message = this.settings.get('system.alert.message', '');
  }

}
