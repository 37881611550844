import {ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, Input, OnInit} from '@angular/core';
import {ColorpickerPanelComponent} from '../color-picker/colorpicker-panel.component';
import {RIGHT_POSITION} from '../overlay-panel/positions/right-position';
import {OverlayPanel} from '../overlay-panel/overlay-panel.service';
import {ControlValueAccessor, FormControl, NG_VALUE_ACCESSOR} from '@angular/forms';

@Component({
    selector: 'color-input',
    templateUrl: './color-input.component.html',
    styleUrls: ['./color-input.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [{
        provide: NG_VALUE_ACCESSOR,
        useExisting: ColorInputComponent,
        multi: true,
    }]
})
export class ColorInputComponent implements ControlValueAccessor, OnInit {

    @Input()
    public disabled = false;

    public colorInputFormControl = new FormControl();
    _onChangeFn: (value: string) => void = () => {};
    _onTouchedFn: () => void = () => {};

    constructor(
        public cd: ChangeDetectorRef,
        public panel: OverlayPanel,
    ) {
    }

    ngOnInit(): void {
        this.colorInputFormControl.valueChanges.subscribe(value => {
            this._onChangeFn(value);
            this._onTouchedFn();
            this.cd.markForCheck();
        });
    }

    public writeValue(value: number) {
        this.colorInputFormControl.setValue(value);
    }

    public registerOnChange(fn: (value: string) => void) {
        this._onChangeFn = fn;
    }

    public registerOnTouched(fn: () => void) {
        this._onTouchedFn = fn;
    }

    openColorPicker(
        inputEl: HTMLInputElement,
    ) {
        return this.panel
            .open(ColorpickerPanelComponent, {
                position: RIGHT_POSITION,
                origin: new ElementRef(inputEl),
                data: {
                    color: this.colorInputFormControl.value
                },
            })
            .afterClosed().subscribe((color: string) => {
                this.colorInputFormControl.setValue(color);
            });
    }
}
