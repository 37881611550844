import {ArchitectElement} from '../../elements/architect-element';

export enum ContextType {
    Selected = 'selected',
    Hover = 'hover',
}

export interface HoverContext {
    /**
     * The first/main architect element that matched the node
     */
    el: ArchitectElement;

    /**
     * The node that was matched for the main architect element
     */
    node: HTMLElement;

    /**
     * All architect elements that matched the node
     */
    els: ArchitectElement[];
}

export interface SelectedContext extends HoverContext {
    path: { node: HTMLElement; name: string }[];
}
