import {ContentBlockInspector} from './content-block-inspector';
import {htmlToElementOrText} from "@common/core/utils/html-to-element-or-text";

export class ContentBlockComparator {

    public static equal(contentA: string, contentB: string, activeBlockKeys: string[]): boolean {
        // if an inner block is different, it is still the same
        let processedA = ContentBlockComparator.clearInnerBlocks(contentA, activeBlockKeys);
        let processedB = ContentBlockComparator.clearInnerBlocks(contentB, activeBlockKeys);

        // if i18n blocks changes, it is still the same
        processedA = ContentBlockComparator.clearI18nBlocks(processedA);
        processedB = ContentBlockComparator.clearI18nBlocks(processedB);

        // if the formatting (e.g. intention) changed, it should still be the same
        processedA = ContentBlockComparator.clearFormattingWhiteSpace(processedA);
        processedB = ContentBlockComparator.clearFormattingWhiteSpace(processedB);

        return processedA === processedB;
    }

    private static clearInnerBlocks(content: string, activeKeyBlocks: string[]): string {
        // remove each outer block content from bottom-up

        const outerBlocks = ContentBlockInspector.getOuterBlocks(content, activeKeyBlocks);

        const reverseSorted = outerBlocks.sort((a, b) => {
            return b.contentEndIndex - a.contentEndIndex;
        });

        const processedContent = reverseSorted.reduce((processedContent2, blockMatch) => {
            const beforeBlockContent = processedContent2.substr(0, blockMatch.contentStartIndex);
            const afterBlockContent = processedContent2.substr(blockMatch.contentEndIndex);
            return beforeBlockContent + afterBlockContent;
        }, content);

        return processedContent;
    }

    private static clearI18nBlocks(content: string): string {
        const contentElement = htmlToElementOrText(content);
        if (contentElement instanceof Text) {
            return content;
        } else {
            ContentBlockComparator.clearI18nBlocksIncludingChilds(contentElement);
            return contentElement.outerHTML;
        }
    }

    private static clearI18nBlocksIncludingChilds(contentElement: Element): void {
        if (contentElement.hasAttribute('data-esb-i18n')) {
            contentElement.innerHTML = '';
        }

        for (let i = 0; i < contentElement.children.length; i++) {
            this.clearI18nBlocksIncludingChilds(contentElement.children[i]);
        }
    }

    private static clearFormattingWhiteSpace(content: string): string {
        return content
            .split('\n')
            .map(line => line.trim())
            .join('');
    }
}
