<button type="button"
        class="no-style position-box"
        [class.active]="value === 'top left'"
        (click)="setPosition('top left')"
></button>
<button type="button"
        class="no-style position-box"
        [class.active]="value === 'top center'"
        (click)="setPosition('top center')"
></button>
<button type="button"
        class="no-style position-box"
        [class.active]="value === 'top right'"
        (click)="setPosition('top right')"
></button>
<button type="button"
        class="no-style position-box"
        [class.active]="value === 'center left'"
        (click)="setPosition('center left')"
></button>
<button type="button"
        class="no-style position-box"
        [class.active]="value === 'center center'"
        (click)="setPosition('center center')"
></button>
<button type="button"
        class="no-style position-box"
        [class.active]="value === 'center right'"
        (click)="setPosition('center right')"
></button>
<button type="button"
        class="no-style position-box"
        [class.active]="value === 'bottom left'"
        (click)="setPosition('bottom left')"
></button>
<button type="button"
        class="no-style position-box"
        [class.active]="value === 'bottom center'"
        (click)="setPosition('bottom center')"
></button>
<button type="button"
        class="no-style position-box"
        [class.active]="value === 'bottom right'"
        (click)="setPosition('bottom right')"
></button>
