export function isNotBlank(value?: string | null): boolean {
    return value != null && value.trim().length > 0;
}

export function getValidValue(value: string | undefined | null, defaultValue?: string, validValues?: string[]) {
    return value != null && value.trim().length > 0 && (validValues == null || validValues.includes(value)) ? value : defaultValue;
}

export function endsWithAny(value: string, anyValues: string[]): boolean {
    return anyValues.some(aValue => value.endsWith(aValue));
}
