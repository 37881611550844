import {Agency} from '../builder-types';

/**
 * Get the display name for an agency
 * @param agency - the agency to get the display name for
 */
export const getAgencyDisplayName = (agency: Agency): string => {
    let displayName = agency.name;
    if (agency.own_id != null) {
        displayName += ` (${agency.own_id})`;
    }
    return displayName;
};

/**
 * Get the display name for a published agency
 * @param item - the item to get the published agency name for
 */
export const getPublishedAgencyName = (item: any): string => {
    if (item.agency != null) {
        const ownId = item.agency.agency_metadata?.own_id;
        if (ownId != null) {
            return `${item.agency.name} (${item.agency.own_id})`;
        } else {
            return item.agency.name;
        }
    } else if (item.agency_id != null) {
        return item.agency_name;
    } else {
        if (item.publish_agency_ids != null) {
            return item.publish_agency_ids;
        } else {
            return 'Global';
        }
    }
};
