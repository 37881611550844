import {Injectable} from '@angular/core';
import {AppHttpClient} from '@common/core/http/app-http-client.service';
import {Observable} from 'rxjs';
import {ContentBlock, ContentBlockGlobalUpdateRequest, ContentBlockKeyRef} from '../builder-types';
import {BackendDataResponse} from '@common/core/types/backend-data-response';

export const CONTENT_BLOCK_REVISIONS_ENDPOINT = (contentBlockId) => `content-blocks/${contentBlockId}/revisions`;

@Injectable({
    providedIn: 'root'
})
export class ContentBlocks {
    constructor(private http: AppHttpClient) {}

    public byProject(projectId: number): BackendDataResponse<ContentBlock> {
        return this.http.get(`content-blocks/${projectId}/by-project`);
    }

    public updateProjects(originProjectId: number, targetProjectIds: Array<number>,
                          contentBlockRefs: Array<ContentBlockKeyRef>): Observable<any> {
        return this.http.post('content-blocks/update-projects', {
            originProjectId,
            targetProjectIds,
            contentBlockRefs
        } as ContentBlockGlobalUpdateRequest);
    }
}
