import {Injectable} from '@angular/core';
import {
    TestimonialWidgetEditorModalComponent,
    TestimonialWidgetEditorModalComponentData
} from './testimonial-widget-editor-modal/testimonial-widget-editor-modal.component';
import {MatDialogRef} from '@angular/material/dialog/dialog-ref';
import {Modal} from '@common/core/ui/dialogs/modal.service';
import {TestimonialWidget} from '../testimonial-widget.model';

@Injectable({
    providedIn: 'root',
})
export class TestimonialWidgetEditorModal {
    public modalRef: MatDialogRef<TestimonialWidgetEditorModalComponent>;

    constructor(
        public modal: Modal
    ) {}

    open(
        origin: HTMLElement,
        testimonialWidget: TestimonialWidget
    ): MatDialogRef<TestimonialWidgetEditorModalComponent> {
        const data: TestimonialWidgetEditorModalComponentData = {
            testimonialWidget,
        };
        return (this.modalRef = this.modal.open(TestimonialWidgetEditorModalComponent, data));
    }

    close() {
        this.modalRef?.close();
    }
}
