import {NativeDateAdapter} from '@angular/material/core';
import format from 'date-fns/format';

// source https://github.com/angular/components/issues/5041#issuecomment-307752968
export class GermanDateAdapter extends NativeDateAdapter {
    parse(value: any): Date | null {
        if ((typeof value === 'string') && (value.indexOf('.') > -1)) {
            const str = value.split('.');
            if (str.length < 2 || isNaN(+str[0]) || isNaN(+str[1]) || isNaN(+str[2])) {
                return null;
            }
            return new Date(Number(str[2]), Number(str[1]) - 1, Number(str[0]), 12);
        }
        const timestamp = typeof value === 'number' ? value : Date.parse(value);
        return isNaN(timestamp) ? null : new Date(timestamp);
    }

    format(date: Date, displayFormat: object): string {
        return format(date, 'dd.MM.yyyy');
    }
}
