import {ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnInit} from '@angular/core';
import {Agency} from '../../../app/shared/builder-types';
import {Project} from '../../../app/shared/projects/Project';
import {FileEntry, FileEntryType} from '@common/uploads/types/file-entry';
import {SelectionModel} from '@angular/cdk/collections';
import {CurrentUser} from '@common/auth/current-user';
import {Projects} from '../../../app/shared/projects/projects.service';
import {AgenciesApi} from '../../../app/shared/agencies/agencies.service';
import {MatTabChangeEvent} from '@angular/material/tabs';
import {
    OWNERSHIP_ID_PERMISSION_AGENCY,
    OWNERSHIP_ID_PERMISSION_GLOBAL,
    OWNERSHIP_ID_PERMISSION_SYSTEM,
    OWNERSHIP_TYPE_AGENCY,
    OWNERSHIP_TYPE_PERMISSION
} from '@common/uploads/types/ownership';
import {BuilderStateService} from '../../../app/html-builder/builder-state.service';

@Component({
    selector: 'media-index',
    templateUrl: './media-index.component.html',
    styleUrls: ['./media-index.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class MediaIndexComponent implements OnInit {
    OWNERSHIP_TYPE_AGENCY = OWNERSHIP_TYPE_AGENCY;
    OWNERSHIP_TYPE_PERMISSION = OWNERSHIP_TYPE_PERMISSION;
    OWNERSHIP_ID_PERMISSION_AGENCY = OWNERSHIP_ID_PERMISSION_AGENCY;
    OWNERSHIP_ID_PERMISSION_GLOBAL = OWNERSHIP_ID_PERMISSION_GLOBAL;
    OWNERSHIP_ID_PERMISSION_SYSTEM = OWNERSHIP_ID_PERMISSION_SYSTEM;

    @Input() filterType?: FileEntryType;
    @Input() selectMode = false;
    @Input() multiselect = false;
    @Input() showProjects = true;
    @Input() showAgencies = true;

    loading = false;
    agencies?: Agency[];
    projects?: Project[];

    private loadedTabsSet = new Set<string>([]);
    public selectedItems?: Partial<FileEntry>[];
    private selection: SelectionModel<Partial<FileEntry>>;
    private selectionLabel: string;
    selectedAgency?: Agency;
    selectedProject?: Project;

    constructor(
        private cd: ChangeDetectorRef,
        private currentUser: CurrentUser,
        private state: BuilderStateService,
        private projectsService: Projects,
        private agencyService: AgenciesApi,
    ) {
    }

    ngOnInit() {
        this.loading = true;

        let currentProjectId;
        let currentAgencyId;
        const activeProject = this.state.project$.value;
        if (activeProject != null) {
            currentProjectId = activeProject.model.id;
            currentAgencyId = activeProject.model.agency_id;
        } else {
            currentAgencyId = this.currentUser.getModel().agency_id;
        }

        let initialTab;

        if (this.showProjects) {
            initialTab = 'projects';
            this.projectsService.listSimpleAll().subscribe(response => {
                this.projects = response.pagination.data;
                if (this.projects.length > 0) {
                    const currentProject = this.projects.find((project) => project.id === currentProjectId);
                    this.selectedProject = currentProject ?? this.projects[0];
                    this.cd.markForCheck();
                }
                if (initialTab === 'projects') {
                    this.loading = false;
                }
            });
        }
        if (this.showAgencies) {
            initialTab = initialTab ?? 'agencies';
            this.agencyService.all().subscribe(response => {
                this.agencies = response.pagination.data;
                if (this.agencies.length > 0) {
                    const currentAgency = this.agencies.find((agency) => agency.id === currentAgencyId);
                    this.selectedAgency = currentAgency ?? this.agencies[0];
                    this.cd.markForCheck();
                }
                if (initialTab === 'agencies') {
                    this.loading = false;
                }
            });
        }

        if (initialTab == null) {
            initialTab = 'global';
            this.loading = false;
        }

        this.loadedTabsSet.add(initialTab);
    }

    hasSelectedItem() {
        return this.selectedItems?.length > 0;
    }

    filesSelectionChanged(selectionLabel?: string, $event?: SelectionModel<Partial<FileEntry>>) {
        if (this.selectionLabel !== selectionLabel && this.selection != null) {
            this.selection.clear();
        }

        this.selectionLabel = selectionLabel;
        this.selection = $event;

        this.selectedItems = $event?.selected;
    }

    isMultiselect(): boolean {
        return this.multiselect ?? true;
    }

    onTabChange($event: MatTabChangeEvent) {
        const tabLabel = $event.tab.textLabel;
        this.loadedTabsSet.add(tabLabel);
    }

    isTabLoaded(tabLabel: string) {
        return this.loadedTabsSet.has(tabLabel);
    }

    clearFilesSelection() {
        this.filesSelectionChanged(undefined, undefined);
    }

    onAgencyChange($event: Agency) {
        // rebuild component hack, because it is not supported by the component itself
        this.selectedAgency = null;
        setTimeout(() => {
            this.selectedAgency = $event;
            this.cd.markForCheck();
        });

        this.clearFilesSelection();
    }

    onProjectChange($event: Project) {
        this.clearFilesSelection();
    }

    getProjectMode() {
        return this.selectMode ? 'select' : this.currentUser.hasPermission('media.project.delete') ? 'manage' : 'view';
    }

    getAgencyMode() {
        return this.selectMode ? 'select' : this.currentUser.hasPermission(OWNERSHIP_ID_PERMISSION_AGENCY) ? 'manage' : 'view';
    }

    getGlobalMode() {
        return this.selectMode ? 'select' : this.currentUser.hasPermission(OWNERSHIP_ID_PERMISSION_GLOBAL) ? 'manage' : 'view';
    }

    getSystemMode() {
        return this.selectMode ? 'select' : this.currentUser.hasPermission(OWNERSHIP_ID_PERMISSION_SYSTEM) ? 'manage' : 'view';
    }

    canUploadToProject(projectId: number): boolean {
        return true;
    }

    canUploadToAgency(agencyId: number): boolean {
        return this.currentUser.hasPermission(OWNERSHIP_ID_PERMISSION_AGENCY);
    }

    canUploadToGlobal(): boolean {
        return this.currentUser.hasPermission(OWNERSHIP_ID_PERMISSION_GLOBAL);
    }

    canUploadToSystem(): boolean {
        return this.currentUser.hasPermission(OWNERSHIP_ID_PERMISSION_SYSTEM);
    }
}
