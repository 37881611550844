/**
 * Convert HTML string to DOM element
 * @param content HTML string
 */
export function htmlToElementOrText(content: string): Element | Text {
    const tmpContainer = window.document.createElement('div');
    tmpContainer.innerHTML = content;
    const element: Element | undefined = tmpContainer.children[0];

    if (element == null) {
        return window.document.createTextNode(content);
    }

    return element;
}
