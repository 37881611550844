<mat-progress-bar mode="indeterminate" color="accent"
                  *ngIf="dataSource.loading$ | async"></mat-progress-bar>
<div class="data-table-panel">
    <div class="data-table-header" *ngIf="!hideHeader">
        <div class="header-inner default"
             [class.hidden]="dataSource.anyRowsSelected() && selectedActions.children.length">
            <div class="left">
                <ng-content select="[headerActions]"></ng-content>
            </div>

            <div class="right">
                <ng-container *ngIf="dataSource.config.filters?.length">
                    <mat-chip-list aria-label="Active Filters" class="filter-chip-list">
                        <mat-chip *ngFor="let filter of dataSource.activeFilters$ | async"
                                  [removable]="true" [selectable]="false"
                                  (removed)="dataSource.removeFilter(filter.column)">
                            {{filter.name}}
                            <mat-icon matChipRemove svgIcon="cancel"></mat-icon>
                        </mat-chip>
                    </mat-chip-list>
                    <button (click)="openFiltersPanel()" class="filter-button"
                            [class.active]="filterPanelRef" #filterButton mat-icon-button>
                        <mat-icon svgIcon="filter-list"></mat-icon>
                    </button>
                </ng-container>
                <ng-container *ngIf="supportedViewTypes?.length > 1">
                    <mat-button-toggle-group [value]="viewType"
                                             (change)="viewType = $event.value"
                                             class="view-chooser">
                        <mat-button-toggle
                            *ngFor="let viewType of supportedViewTypes"
                            [value]="viewType"
                            title="{{viewType}}">
                            <ng-container [ngSwitch]="viewType">
                                <ng-container *ngSwitchCase="ViewTypes.Table">
                                    <mat-icon svgIcon="view-list-outline"></mat-icon>
                                </ng-container>
                                <ng-container *ngSwitchCase="ViewTypes.Grid">
                                    <mat-icon svgIcon="view-grid-outline"></mat-icon>
                                </ng-container>
                                <ng-container *ngSwitchDefault>
                                    {{viewType}}
                                </ng-container>
                            </ng-container>
                        </mat-button-toggle>
                    </mat-button-toggle-group>
                </ng-container>
                <ng-content select="[headerFilters]"></ng-content>
                <div *ngIf="searchEnabled" class="input-container input-container-flexible">
                    <input type="text" class="search-input"
                           placeholder="Search {{ itemsName }}" trans-placeholder
                           [formControl]="dataSource.searchControl">
                    <mat-icon svgIcon="search"></mat-icon>
                </div>
            </div>
        </div>

        <div class="header-inner selected"
             [class.hidden]="!dataSource.anyRowsSelected() || !selectedActions.children.length">
            <div class="left">{{dataSource.selectedRows.selected.length}} <span
                class="text" trans>{{itemsName}} Selected</span></div>
            <div class="right" #selectedActions>
                <ng-content select="[headerSelectedActions]"></ng-content>
            </div>
        </div>

        <ng-content select="[headerContent]"></ng-content>
    </div>

    <mat-table [dataSource]="dataSource" [class.empty]="dataSource.noResults$ | async"
               [class.hidden]="viewType !== ViewTypes.Table"
               matSort>
        <ng-container matColumnDef="select">
            <mat-header-cell *matHeaderCellDef>
                <mat-checkbox (change)="$event ? dataSource.toggleAllRows() : null"
                              [checked]="dataSource.allRowsSelected()"
                              [indeterminate]="dataSource.anyRowsSelected() && ! dataSource.allRowsSelected()">
                </mat-checkbox>
            </mat-header-cell>
            <mat-cell *matCellDef="let row">
                <mat-checkbox (click)="$event.stopPropagation()"
                              (change)="$event ? dataSource.selectedRows.toggle(row) : null"
                              [checked]="dataSource.selectedRows.isSelected(row)">
                </mat-checkbox>
            </mat-cell>
        </ng-container>

        <ng-content select="[contentTableView]"></ng-content>
        <ng-content></ng-content>

        <mat-header-row *matHeaderRowDef="columns"></mat-header-row>
        <mat-row *matRowDef="let myRowData; columns: columns"></mat-row>
    </mat-table>

    <div class="grid" [class.hidden]="viewType !== ViewTypes.Grid">
        <ng-container *ngFor="let row of dataSource.getData()">
            <ng-template #selectTpl>
                <mat-checkbox (click)="$event.stopPropagation()"
                              (change)="$event ? dataSource.selectedRows.toggle(row) : null"
                              [checked]="dataSource.selectedRows.isSelected(row)">
                </mat-checkbox>
            </ng-template>
            <div
                *ngTemplateOutlet="gridViewTile; context: {$implicit: row, selectTpl: selectTpl}"></div>
            <div *ngIf="!gridViewTile">
                No template defined.
            </div>
        </ng-container>
    </div>

    <ng-container *ngIf="dataSource.noResults$ | async">
        <div class="no-results-message-container" #noResults>
            <ng-content select="no-results-message"></ng-content>
        </div>
        <no-results-message *ngIf="!noResults.children.length">
            <span primary-text trans>Nothing To Display.</span>
            <span secondary-text trans>Seems like no {{itemsName}}
                have been created yet.</span>
        </no-results-message>
    </ng-container>

    <mat-paginator [class.hidden]="!dataSource.config.dataPaginator" [pageSize]="15"
                   [pageSizeOptions]="[10, 15, 20, 50, 100]"></mat-paginator>
</div>
