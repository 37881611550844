import {Component, Inject, Optional, ViewChild} from '@angular/core';
import {BuilderStateService} from '../../../../../builder-state.service';
import {TestimonialWidget} from '../../testimonial-widget.model';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {TestimonialWidgetEditorComponent} from '../../testimonial-widget-editor/testimonial-widget-editor.component';

export interface TestimonialWidgetEditorModalComponentData {
    testimonialWidget: TestimonialWidget;
}

@Component({
    selector: 'testimonial-widget-editor-modal',
    templateUrl: './testimonial-widget-editor-modal.component.html',
    styleUrls: ['./testimonial-widget-editor-modal.component.scss'],
})
export class TestimonialWidgetEditorModalComponent {

    processing = false;

    @ViewChild(TestimonialWidgetEditorComponent)
    testimonialWidgetEditorComponent: TestimonialWidgetEditorComponent;

    constructor(
        public state: BuilderStateService,
        @Inject(MAT_DIALOG_DATA)
        @Optional()
        public data: TestimonialWidgetEditorModalComponentData,
        @Inject(MatDialogRef)
        @Optional()
        public modalRef: MatDialogRef<TestimonialWidgetEditorModalComponent>
    ) {
    }

    submit(): void {
        this.close(this.data.testimonialWidget);
    }

    close(value?: TestimonialWidget): void {
        this.modalRef.close(value);
    }

    addTestimonial(): void {
        this.testimonialWidgetEditorComponent.addTestimonial();
    }
}
