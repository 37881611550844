import {
    ChangeDetectionStrategy,
    Component,
    ElementRef,
    EventEmitter,
    HostBinding,
    HostListener,
    Input,
    Output,
    ViewChild
} from '@angular/core';

@Component({
    selector: 'file-drop-container',
    templateUrl: './file-drop-container.component.html',
    styleUrls: ['./file-drop-container.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class FileDropContainerComponent {

    @HostBinding('class.files-over') filesOver: boolean;

    @Input() enabled = true;

    @Output()
    filesDropped = new EventEmitter<File[]>();

    @ViewChild('dropOverlay') dropOverlay: ElementRef;
    @ViewChild('dropOverlayPlaceholder') dropOverlayPlaceholder: ElementRef;

    @HostListener('dragover', ['$event'])
    onDragOver($event) {
        $event.preventDefault();
        $event.stopPropagation();
        if (this.enabled) {
            this.filesOver = true;
        }
    }

    @HostListener('dragleave', ['$event'])
    onDragLeave($event) {
        if ($event.fromElement !== this.dropOverlay.nativeElement &&
            $event.fromElement !== this.dropOverlayPlaceholder.nativeElement) {
            $event.preventDefault();
            $event.stopPropagation();
            this.filesOver = false;
        }
    }

    @HostListener('drop', ['$event'])
    onDrop($event) {
        $event.preventDefault();
        $event.stopPropagation();
        this.filesOver = false;

        const files = [];
        for (let i = 0; i < $event.dataTransfer.files.length; i++) {
            files.push($event.dataTransfer.files.item(i));
        }

        if (this.enabled) {
            this.filesDropped.emit(files);
        }
    }

}
