import {Injectable} from '@angular/core';
import {AppHttpClient} from '@common/core/http/app-http-client.service';
import {BuilderProject, FtpDetails} from '../builder-types';
import {Project} from './Project';
import {PaginatedBackendResponse} from '@common/core/types/pagination/paginated-backend-response';
import {BackendResponse} from '@common/core/types/backend-response';

@Injectable({
    providedIn: 'root'
})
export class Projects {
    static BASE_URI = 'projects';

    constructor(private http: AppHttpClient) {
    }

    list(params?: { user_id?: number, per_page?: number }): PaginatedBackendResponse<Project> {
        return this.http.get(Projects.BASE_URI, params);
    }

    public simple(params?: { user_id?: number, per_page?: number, template?: string }): PaginatedBackendResponse<Project> {
        return this.http.get(`${Projects.BASE_URI}/simple`, params);
    }

    public listSimpleAll(params?: { user_id?: number, template?: string }): PaginatedBackendResponse<Project> {
        return this.http.get('projects/simple-all', params);
    }

    public copyBackgroundImage(id: number, index: number) {
        return this.http.get(`${Projects.BASE_URI}/${id}/background-copy/${index}`);
    }

    public copyTextureImage(id: number, index: number) {
        return this.http.get(`${Projects.BASE_URI}/${id}/textures-copy/${index}`);
    }

    get(id: number): BackendResponse<{ project: BuilderProject }> {
        return this.http.get(`${Projects.BASE_URI}/${id}`);
    }

    create(params: object): BackendResponse<{ project: BuilderProject }> {
        return this.http.post(Projects.BASE_URI, params);
    }

    public update(id: number, params: object): BackendResponse<{ project: BuilderProject }> {
        return this.http.put(`${Projects.BASE_URI}/${id}`, params);
    }

    public toggleState(id: number, published: boolean): BackendResponse<{ project: Project }> {
        return this.http.put(`${Projects.BASE_URI}/${id}`, {published});
    }

    public delete(params: { ids: number[] }): BackendResponse<void> {
        return this.http.delete(`${Projects.BASE_URI}`, params);
    }

    public generateThumbnail(projectId: number, dataUrl: string): BackendResponse<void> {
        return this.http.post(`${Projects.BASE_URI}/${projectId}/generate-thumbnail`, {dataUrl});
    }

    exportToFtp(projectId: number, params: FtpDetails): BackendResponse<{ project: BuilderProject }> {
        return this.http.post(`${Projects.BASE_URI}/${projectId}/export/ftp`, params);
    }

    public listMedia(projectId: number): BackendResponse<{ files: { filePath: string, lastModified: number }[] }> {
        return this.http.get(`${Projects.BASE_URI}/${projectId}/media`);
    }

    public addMedia(projectId: number, url: string): BackendResponse<{ url: string }> {
        return this.http.post(`${Projects.BASE_URI}/${projectId}/media`, {
            url
        });
    }

    public deleteMedia(projectId: number, paths: string[]): BackendResponse<void> {
        return this.http.delete(`${Projects.BASE_URI}/${projectId}/media`, {
            paths
        });
    }

    public deletePage(projectId: number, pageName: string) {
        return this.http.delete(`${Projects.BASE_URI}/${projectId}/page`, {
            pageName
        });
    }

    public duplicatePage(projectId: number, pageName: string): BackendResponse<{ url: string }> {
        return this.http.post(`${Projects.BASE_URI}/${projectId}/page/duplicate`, {
            pageName
        });
    }
}
