import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {LoadingBarComponent} from './loading-bar.component';
import {MatProgressBarModule} from '@angular/material/progress-bar';

@NgModule({
    declarations: [
        LoadingBarComponent
    ],
    exports: [
        LoadingBarComponent
    ],
    imports: [
        CommonModule,
        MatProgressBarModule
    ]
})
export class LoadingBarModule {
}
