<form
        (ngSubmit)="projectSettings.submitForm()"
        [formGroup]="projectSettings.form"
        ngNativeValidate
        class="many-inputs">
    <div class="tab-content">
        <ng-container formGroupName="notificationModal">
            <div class="setting-toggle-container">
                <mat-slide-toggle color="accent" formControlName="enabled" trans>
                    Enable notification popup
                </mat-slide-toggle>
                <p trans>Enables or disables displaying an alert when a user visits the website, e.g. for vacations.</p>
            </div>

            <div class="input-container">
                <label for="showUntil" trans>Show popup until (excl.):</label>
                <input matInput type="date"
                       formControlName="showUntil"
                       id="showUntil">
            </div>

            <div class="input-container input-container-image">
                <label for="notification-image-preview" trans>Header Image:</label>
                <loading-bar *ngIf="_processing"></loading-bar>
                <div class="image-preview"
                     *ngIf="hasImage()"
                     (click)="!_processing && updateSlideImage()"
                     [matTooltip]="'Change image' | trans">
                    <img [src]="getImageForModal()" alt="Header">
                </div>
                <button type="button"
                        id="notification-image-preview"
                        class="action-button"
                        mat-stroked-button
                        color="accent"
                        (click)="!_processing && updateSlideImage()" trans>
                    Select
                </button>
                <button type="button" class="action-button delete-button" mat-icon-button
                        [matTooltip]="'Remove background image' | trans"
                        *ngIf="getImageForModal()"
                        (click)="!_processing && deleteImage()">
                    <mat-icon svgIcon="delete"></mat-icon>
                </button>
            </div>

            <div class="input-container">
                <label for="text" trans>Image Alt:</label>
                <input matInput type="text"
                       formControlName="imageAlt"
                       id="imageAlt">
            </div>

            <div class="input-container">
                <label for="text" trans>Inhalt:</label>
                <ckeditor id="text"
                          formControlName="text"
                          [editor]="ckEditor5.Editor"
                          [config]="ckEditor5.config"></ckeditor>
            </div>
        </ng-container>
    </div>
    <div class="settings-modal-actions">
        <button
                type="submit"
                mat-raised-button
                color="accent"
                [disabled]="projectSettings.loading$ | async"
                trans>
            Save
        </button>
    </div>
</form>
