import {Component, EventEmitter, HostBinding, HostListener, Input, Output} from '@angular/core';
import {UploadInputTypes} from '@common/uploads/upload-input-config';
import {FileEntryType} from '@common/uploads/types/file-entry';
import {getFileEnding, getFileTypeFromPath} from '@common/core/utils/file-util';
import {Toast} from '@common/core/ui/toast.service';

@Component({
    selector: 'file-chooser-control',
    templateUrl: './file-chooser-control.component.html',
    styleUrls: ['./file-chooser-control.component.scss'],
})
export class FileChooserControlComponent {

    @HostBinding('class.files-over') filesOver: boolean;

    @Input() files?: File[];
    @Input() acceptType?: FileEntryType;

    @Output() filesChange = new EventEmitter<File[] | undefined>();

    constructor(
        private toast: Toast,
    ) {
    }

    @HostListener('dragover', ['$event'])
    onDragOver($event) {
        $event.preventDefault();
        $event.stopPropagation();
        this.filesOver = true;
    }

    @HostListener('dragleave', ['$event'])
    onDragLeave($event) {
        $event.preventDefault();
        $event.stopPropagation();
        this.filesOver = false;
    }

    @HostListener('drop', ['$event'])
    onDrop($event) {
        $event.preventDefault();
        $event.stopPropagation();
        this.filesOver = false;

        this.setFiles($event.dataTransfer.files);
    }

    public setFiles(files?: FileList) {
        const validFiles = [];
        const invalidFileTypes = new Set<string>();

        for (let i = 0; i < files?.length; i++) {
            const file = files.item(i);

            let isValid = true;
            if (this.acceptType != null) {
                const fileType = getFileTypeFromPath(file.name);
                if (this.acceptType !== fileType) {
                    isValid = false;
                    invalidFileTypes.add(getFileEnding(file.name));
                }
            }

            if (isValid) {
                validFiles.push(file);
            }
        }

        if (invalidFileTypes.size > 0) {
            this.toast.open('Invalid file type: ' + [...invalidFileTypes].join(', '));
        }

        if (validFiles?.length > 0) {
            this.files = validFiles;
        } else {
            this.files = undefined;
        }
        this.filesChange.emit(this.files);
    }

    getFileAccept() {
        if (this.acceptType == null) {
            return undefined;
        }

        switch (this.acceptType) {
            case FileEntryType.image:
                return UploadInputTypes.image;
            case FileEntryType.video:
                return UploadInputTypes.video;
            case FileEntryType.audio:
                return UploadInputTypes.audio;
            case FileEntryType.pdf:
                return UploadInputTypes.pdf;
        }

        return undefined;
    }

    removeFile(file: File) {
        const idx = this.files.findIndex(f => f === file);
        this.files.splice(idx, 1);
    }
}
