import {Component, EventEmitter, Input, Output} from '@angular/core';
import {FileEntry} from '@common/uploads/types/file-entry';

@Component({
    selector: 'media-grid-card',
    templateUrl: './media-grid-card.component.html',
    styleUrls: ['./media-grid-card.component.scss']
})
export class MediaGridCardComponent {
    @Input() entry: Partial<FileEntry>;
    @Input() selected = false;
    @Input() selectable = false;

    @Output() selectionChange = new EventEmitter<Partial<FileEntry>>();

    onSelectionChange() {
        this.selectionChange.emit(this.entry);
    }
}
