import {ArchitectElement} from '../../architect-element';
import {InlineTextEditor} from '../../../overlays/inline-text-editor/inline-text-editor.service';

export class AccordionWidgetBodyEl extends ArchitectElement {
    name = 'Accordion Body';
    contentCategories = ['accordion-body'];
    allowedContent = ['flow'];
    category = null;
    canDrag = false;
    canCopy = false;
    canDelete = false;
    specificity = 7;
    hiddenClasses = ['pt-3', 'pb-2', 'accordeon-body'];
    editActions = [{
        name: 'Edit Body',
        onClick: (node: HTMLElement) => {
            this.get(InlineTextEditor).open(node);
        }
    }];

    matcher(node: HTMLElement) {
        return node.closest('.accordeon-body') as HTMLElement;
    }
}
