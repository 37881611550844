export function nodeOrAnyParentEditable(node: HTMLElement): boolean {
    if (node?.hasAttribute('contenteditable')) {
        return true;
    }

    const parent = node?.parentElement;
    if (parent != null) {
        return nodeOrAnyParentEditable(parent);
    }

    return false;
}
