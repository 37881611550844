import {Injectable} from '@angular/core';
import {AppHttpClient} from '@common/core/http/app-http-client.service';
import {Observable} from 'rxjs';
import {Agency, AgencyMetadata, AgencyOpeningHoursVariant, AgencyTeamMember, OpeningHour} from '../builder-types';
import {PaginatedBackendResponse} from '@common/core/types/pagination/paginated-backend-response';

@Injectable({
    providedIn: 'root'
})
export class AgenciesApi {
    static BASE_URI = 'agencies';

    constructor(private http: AppHttpClient) {
    }

    public all(params: object = {}): PaginatedBackendResponse<Agency> {
        return this.http.get(`${AgenciesApi.BASE_URI}/all`, params);
    }

    public allByGroup(params: object = {}): PaginatedBackendResponse<Agency> {
        return this.http.get(`${AgenciesApi.BASE_URI}/group`, params);
    }

    public allImprintData(params: object = {}): PaginatedBackendResponse<AgencyMetadata> {
        return this.http.get('agencies_metadata', params);
    }

    public allMembers(agencyId: number, params: object = {}): PaginatedBackendResponse<AgencyTeamMember> {
        return this.http.get(`${AgenciesApi.BASE_URI}/members/${agencyId}`, params);
    }

    public createMember(agencyId: string, params: object): Observable<{ item: AgencyTeamMember }> {
        return this.http.post(`${AgenciesApi.BASE_URI}/members/${agencyId}`, params);
    }

    public updateMember(id: number, params: object): Observable<{ item: AgencyTeamMember }> {
        return this.http.put(`${AgenciesApi.BASE_URI}/members/${id.toString()}`, params);
    }

    public archiveMembers(agencyId: string, ids: number[]): Observable<any> {
        return this.http.post(`${AgenciesApi.BASE_URI}/members/${agencyId}/archive`, {ids});
    }

    public restoreMembers(agencyId: string, ids: number[]): Observable<any> {
        return this.http.post(`${AgenciesApi.BASE_URI}/members/${agencyId}/restore`, {ids});
    }

    public deleteMembers(agencyId: string, ids: number[]): Observable<any> {
        return this.http.delete(`${AgenciesApi.BASE_URI}/members/${agencyId}`, {ids});
    }

    public get(id: number): Observable<{ agency: Agency }> {
        return this.http.get(`${AgenciesApi.BASE_URI}/${id}`);
    }

    public search(search: string): Observable<{ agency: Agency }> {
        return this.http.get(`${AgenciesApi.BASE_URI}/search/${search}`);
    }

    public create(params: object): Observable<{ agency: Agency }> {
        return this.http.post(AgenciesApi.BASE_URI, params);
    }

    public createWebsite(id: number, params: object): Observable<{ agency: Agency }> {
        return this.http.post(`${AgenciesApi.BASE_URI}/${id}/init-website`, params);
    }

    public createWebsites(): Observable<{ agency: Agency }> {
        return this.http.post(`${AgenciesApi.BASE_URI}/init-websites`, {});
    }

    public update(id: number, params: object): Observable<{ agency: Agency }> {
        return this.http.put(`${AgenciesApi.BASE_URI}/${id}`, params);
    }

    public updateImprint(id: number, params: object): Observable<{ agencyMetaData: AgencyMetadata }> {
        return this.http.put(`${AgenciesApi.BASE_URI}/metadata/${id}`, params);
    }

    public updateSettings(id: number, params: object): Observable<{ agency: Agency }> {
        return this.http.put(`${AgenciesApi.BASE_URI}/${id}/settings`, params);
    }

    public getOpeningHours(agencyId: number, variant: AgencyOpeningHoursVariant): Observable<{ openingHours: OpeningHour[] }> {
        return this.http.get(`${AgenciesApi.BASE_URI}/${agencyId}/opening-hours?variant=${variant}`);
    }

    public updateOpeningHours(agencyId: number, variant: AgencyOpeningHoursVariant, params: object): Observable<{ agency: Agency }> {
        return this.http.put(`${AgenciesApi.BASE_URI}/${agencyId}/opening-hours?variant=${variant}`, params);
    }

    public delete(ids: number[]): Observable<any> {
        return this.http.delete(AgenciesApi.BASE_URI, {ids});
    }

    public matchingToggleActive(teamMemberId: number): Observable<{ agency: Agency }> {
        return this.http.put(`${AgenciesApi.BASE_URI}/members/${teamMemberId}/matching/toggle-active`);
    }

    public matchingSendLoginEmail(teamMemberId: number): Observable<{ agency: Agency }> {
        return this.http.post(`${AgenciesApi.BASE_URI}/members/${teamMemberId}/matching/send-login-email`);
    }
}
