import {ArchitectElControl, ArchitectElement, ArchitectElementAction, EditableProp, ElControlType} from './architect-element';
import {InlineTextEditor} from '../overlays/inline-text-editor/inline-text-editor.service';
import {CurrentUser} from '@common/auth/current-user';
import {MutationsService} from '../mutations/mutations.service';
import {ModifyAttributes} from '../mutations/attributes/modify-attributes';
import {I18nWidgetService} from './definitions/i18n-widget/i18n-widget-editor.service';
import {Injector} from "@angular/core";

export abstract class ArchitectTextEl extends ArchitectElement {
    canEdit: EditableProp[] = [
        EditableProp.Padding,
        EditableProp.Margin,
        EditableProp.Border,
        EditableProp.Attributes,
        EditableProp.Shadow,
        EditableProp.Background,
        EditableProp.Text,
    ];
    editActions: ArchitectElementAction[] = [{
        id: 'action.text.edit',
        name: 'Edit Text',
        onClick: (node: HTMLElement) => {
            this.get(InlineTextEditor).open(node);
        },
    }];

    constructor(protected injector: Injector) {
        super(injector);

        const currentUser = injector.get(CurrentUser);

        if (currentUser.hasPermission('templates.update_i18n')) {
            this.controls.push(new ArchitectElControl(this.injector, {
                label: 'i18n key',
                type: ElControlType.Input,
                inputType: 'text',
                defaultValue(node: HTMLInputElement) {
                    return node.getAttribute('data-esb-i18n') ?? '';
                },
                onChange(node: HTMLElement, value: string) {
                    this.get(MutationsService).execute(
                        new ModifyAttributes(node, {
                            'data-esb-i18n': value !== '' ? value : null,
                        })
                    );
                },
            }));

            this.editActions.push({
                id: 'action.i18n.edit',
                name: 'Edit i18n',
                onClick: async (node: HTMLElement) => {
                    const i18nKey = node.getAttribute('data-esb-i18n') ?? '';
                    (await this.get(I18nWidgetService)
                        .open(node, i18nKey));
                },
                isEnabled: (node: HTMLElement): boolean => {
                    return node.getAttribute('data-esb-i18n') != null;
                },
                preventActionIds: ['action.text.edit'],
            });
        }
    }
}
