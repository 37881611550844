import {Injectable, Injector} from '@angular/core';
import {Translations} from '@common/core/translations/translations.service';
import {ArchitectElement} from './architect-element';
import {BehaviorSubject} from 'rxjs';
import {BuilderContentService} from '../builder-content.service';
import {nodeOrAnyParentEditable} from '../utils/node-or-any-parent-editable';
import {DefinedElementsService} from './defined-elements.service';
import {CustomElementsService} from './custom-elements.service';
import {isNotBlank} from '../../shared/util/string-util';
import {DivContainerEl} from './definitions/base/base-div';

export interface ElementsNodeMatchResult {
    el: ArchitectElement;
    els: ArchitectElement[];
    node: HTMLElement;
}

@Injectable({
    providedIn: 'root',
})
export class Elements {
    elements: ArchitectElement[] = [];
    categories$ = new BehaviorSubject<string[]>([]);

    constructor(
        private i18n: Translations,
        private injector: Injector,
        private builderContentService: BuilderContentService,
        private definedElementsService: DefinedElementsService,
        private customElementsService: CustomElementsService,
    ) {
    }

    public getElements(): ArchitectElement[] {
        return this.elements;
    }

    getDisplayName(el: ArchitectElement, node: HTMLElement) {
        if (!el) return;

        if (el instanceof DivContainerEl) {
            if (node.id) {
                return node.id;
            } else if (node.classList[0]) {
                return node.classList[0];
            } else {
                return el.name;
            }
        } else {
            return el.name;
        }
    }

    canInsert(parentNode: HTMLElement, child: ArchitectElement): boolean {
        if (parentNode.nodeName === 'BODY') return true;
        if (parentNode.nodeName === 'HTML') return false;

        const parentEl = this.match(parentNode)?.el;
        if (!parentEl) return;

        if (!this.builderContentService.isEditable(parentNode)) {
            return false;
        }

        // check by architect element types
        if (parentEl.allowedEls.length) {
            return parentEl.allowedEls.some(el => child instanceof el);
        }

        // check by html content category
        if (parentEl.allowedContent && child.contentCategories) {
            return child.contentCategories.some(t =>
                parentEl.allowedContent.includes(t)
            );
        }
    }

    /**
     * Returns a list of architect element matches for a given node
     * @param node node to match architect elements
     */
    match(node: HTMLElement): ElementsNodeMatchResult | null {
        if (!node?.nodeName) return null;
        if (nodeOrAnyParentEditable(node)) return null;

        // get all matches for a given node
        const matches: { el: ArchitectElement, node: HTMLElement }[] = [];
        for (const el of this.elements) {
            const response = el.matcher?.(node);
            if (!!response) {
                matches.push({
                    el,
                    node: response === true ? node : response
                });
            }
        }

        // check if we have architect elements that are blocking
        const blockingMatches = matches.filter(match => {
            return match.el.blocksContent === true;
        });

        const matchesToConsider = blockingMatches.length > 0 ? blockingMatches : matches;

        if (matchesToConsider.length > 0) {
            // we take the first match, which is also the match with the highest priority/specificity, because it is sorted on init()
            const firstMatch = matchesToConsider[0];
            return {
                el: firstMatch.el,
                node: firstMatch.node,

                // all matches that have the same node, so when we select an element we can show all possible actions
                els: matches
                    .filter(match => match.node === firstMatch.node)
                    .map(match => match.el),
            };
        }

        return null;
    }

    async init() {
        const definedElements = this.definedElementsService.getAll();
        const customElements = await this.customElementsService.fetchCustomEls();

        // first match custom elements, then bootstrap, then base ones
        this.registerElements([...definedElements, ...customElements]);
    }

    registerElements(els: any) {
        this.elements = els
            .map((el: any) => {
                return this.initEl(el);
            })
            .sort((a, b) => (a.specificity < b.specificity ? 1 : -1));

        const categories = this.getUniqueCategories(this.elements);
        this.categories$.next(categories);
    }

    private initEl(el: any) {
        if (typeof el === 'function') {
            el = new el(this.injector);
        }

        // translate name
        el.name = this.i18n.t(el.name);

        return el;
    }

    private getUniqueCategories(elements: ArchitectElement[]): string[] {
        const categoriesSet = elements
            .filter(el => isNotBlank(el.category))
            .reduce((aggr, el) => {
                aggr.add(el.category);
                return aggr;
            }, new Set<string>());
        return [...categoriesSet];
    }
}
