import {Injectable} from '@angular/core';
import {
    BlockGridWidgetEditorModalComponent,
    BlockGridWidgetEditorModalComponentData
} from './block-grid-widget-editor-modal/block-grid-widget-editor-modal.component';
import {MatDialogRef} from '@angular/material/dialog/dialog-ref';
import {Modal} from '@common/core/ui/dialogs/modal.service';
import {BlockGridWidget} from './block-grid-widget.model';

@Injectable({
    providedIn: 'root',
})
export class BlockGridWidgetEditor {
    public modalRef: MatDialogRef<BlockGridWidgetEditorModalComponent>;

    constructor(
        public modal: Modal
    ) {
    }

    open(
        origin: HTMLElement,
        widget: BlockGridWidget
    ): MatDialogRef<BlockGridWidgetEditorModalComponent> {
        const data: BlockGridWidgetEditorModalComponentData = {
            widget,
        };
        this.modalRef = this.modal.open(BlockGridWidgetEditorModalComponent, data);
        return this.modalRef;
    }

    close() {
        this.modalRef?.close();
    }
}
