import {Component, Inject, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from '@angular/material/dialog';
import {BehaviorSubject, combineLatest, concat, defer, Observable, of} from 'rxjs';
import {Toast} from '@common/core/ui/toast.service';
import {TemplatesApi} from '../templates-api.service';
import {I18nEntry, Language} from '../../builder-types';
import {FormControl} from '@angular/forms';
import {map, startWith, take, takeUntil} from 'rxjs/operators';
import {InfoDialogComponent, InfoDialogModel} from '../../info-dialog/info-dialog.component';
import {Modal} from '@common/core/ui/dialogs/modal.service';
import {ConfirmDialogComponent, ConfirmDialogModel} from '../../confirm-dialog/confirm-dialog.component';
import {randomString} from '@common/core/utils/random-string';
import {CkEditor5} from '@common/text-editor/ckeditor5/CkEditor5';
import * as ckEditorUtils from '@common/text-editor/ckeditor5/ckeditor5.util';

export interface TemplateI18nModalData {
    templateName: string;
    currentLanguage?: Language;
    searchTerm?: string;
}

@Component({
    selector: 'template-i18n-modal',
    templateUrl: './template-i18n-modal.component.html',
    styleUrls: ['./template-i18n-modal.component.scss'],
})
export class TemplateI18nModalComponent implements OnInit, OnChanges {

    loading = false;
    errors = {};
    searchTermFormControl = new FormControl('');
    visibleBodies = new Set<string>();

    defaultLanguage: string = null;
    languages: string[] = [];

    i18nEntries$ = new BehaviorSubject<I18nEntry[]>([]);
    i18nEntriesCount$ = this.i18nEntries$.pipe(map(entries => entries.length));
    searchTerm$: Observable<string> = concat(
        defer(() => of(this.searchTermFormControl.value)),
        this.searchTermFormControl.valueChanges
    );
    searchTermExists$ = this.searchTerm$.pipe(map(searchTerm => searchTerm != null && searchTerm.length > 0));
    filteredI18nEntries$ = combineLatest([
        this.i18nEntries$.pipe(startWith([] as I18nEntry[])),
        this.searchTerm$,
    ]).pipe(
        map(([entries, searchTerm]) => {
            if (searchTerm == null || searchTerm.length === 0) {
                return entries;
            }

            const searchTermNormalized = searchTerm.toLowerCase();

            return entries.filter(entry => {
                return (entry.key != null && entry.key.toLowerCase().includes(searchTermNormalized))
                    || (entry.values != null && Object.values(entry.values)
                            .filter(value => value != null)
                            .some(value => value.toLowerCase().includes(searchTermNormalized))
                    );
            });
        }),
    );
    filteredI18nEntriesCount$ = this.filteredI18nEntries$.pipe(map(entries => entries.length));
    isFiltering$ = this.searchTerm$.pipe(map(searchTerm => searchTerm.length > 0));
    createFromSearchTerm$ = combineLatest([
        this.searchTermExists$,
        this.filteredI18nEntriesCount$,
    ]).pipe(
        map(([searchTermExists, filteredI18nEntriesCount]) => {
            return searchTermExists && filteredI18nEntriesCount === 0;
        }),
    );

    constructor(
        private dialogRef: MatDialogRef<TemplateI18nModalComponent>,
        @Inject(MAT_DIALOG_DATA) public data: TemplateI18nModalData,
        private templatesApi: TemplatesApi,
        private toast: Toast,
        private modal: Modal,
        private dialog: MatDialog,
        public ckEditor5: CkEditor5,
    ) {
        dialogRef.disableClose = true;
    }

    ngOnInit(): void {
        this.searchTermFormControl.setValue(this.data.searchTerm ?? '');

        this.loading = true;
        this.templatesApi.get(this.data.templateName).pipe(take(1)).subscribe(response => {
            const template = response.template;
            const templateConfig = template.config;
            const templateBundleConfig = template.config.bundle_config;

            this.defaultLanguage = templateBundleConfig.default_language;

            this.languages = [];
            this.languages.push(this.defaultLanguage);
            if (this.data.currentLanguage != null && this.defaultLanguage !== this.data.currentLanguage) {
                this.languages.push(this.data.currentLanguage);
            }
            const otherLanguages = templateBundleConfig.languages.filter(lang => !this.languages.includes(lang));
            this.languages = this.languages.concat(otherLanguages);

            // Example:
            // const i18nEntries: I18nEntry[] = [
            //     {
            //         key: 'KEY1',
            //         values: {
            //             de: 'bar',
            //             en: '', // fill missing languages
            //             es: '', // do not delete other languages
            //         }
            //     }, {
            //         key: 'KEY2',
            //         values: {
            //             de: 'bar',
            //             en: 'bar-en',
            //         },
            //     },
            // ];

            this.i18nEntries$.next(templateBundleConfig.i18n ?? []);

            this.loading = false;
        });

        // open entry if only one entry is found
        this.filteredI18nEntries$.pipe(
            takeUntil(this.dialogRef.afterClosed()),
        ).subscribe(entries => {
            if (entries.length === 1) {
                this.visibleBodies.add(entries[0].key);
            }
        });
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes.data) {
            this.searchTermFormControl.setValue(changes.data.currentValue.searchTerm ?? '');
        }
    }

    setKey(i18nEntry: I18nEntry, newKey: string): void {
        const oldKey = i18nEntry.key;
        i18nEntry.key = newKey;

        // since the key changes, we need to keep the toggle state of the key
        if (this.visibleBodies.has(oldKey)) {
            this.visibleBodies.delete(oldKey);
            this.visibleBodies.add(newKey);
        }
    }

    setValue(i18nEntry: I18nEntry, language: string, value: string): void {
        i18nEntry.values[language] = value;
    }

    confirm() {
        const dialogData = new ConfirmDialogModel({
            title: 'Changes affect multiple projects',
            message: 'This edits the global translation file. Changes affect ALL projects that use the same template. Are you sure?',
            confirmLabel: 'Confirm'
        });
        this.dialog.open(ConfirmDialogComponent, {data: dialogData}).afterClosed().subscribe(confirmed => {
            if (confirmed) {
                this.loading = true;
                this.i18nEntries$.pipe(
                    take(1),
                ).subscribe(i18nEntries => {
                    const cleanedI18nEntries = this.cleanEntries(i18nEntries);
                    this.templatesApi.updateI18n(this.data.templateName, cleanedI18nEntries).subscribe(response => {
                        this.dialogRef.close(response);
                        this.toast.open('i18n entries have been updated');
                        this.loading = false;
                        this.modal.open(InfoDialogComponent, new InfoDialogModel({
                            message: `The change(s) to the language file were successful. We update ALL projects in the background that use this template. This process can take several minutes. Please be patient until the changes are applied to all projects.`,
                        }));
                    }, response => {
                        this.errors = response.errors;
                        this.loading = false;
                    });
                });
            }
        });
    }

    close() {
        this.dialogRef.close();
    }

    addEntry() {
        const newEntry = {
            key: randomString(12),
            values: this.languages.reduce((translation: any, language) => {
                translation[language] = '';
                return translation;
            }, {}),
        };
        this.i18nEntries$.next([...this.i18nEntries$.value, newEntry]);

        this.searchTermFormControl.setValue(newEntry.key);
    }

    deleteEntry(entry: I18nEntry) {
        const dialogData = new ConfirmDialogModel({
            title: 'Confirm',
            message: 'Are you sure you want to remove the entry from the language file?',
            confirmLabel: 'Delete'
        });
        this.dialog.open(ConfirmDialogComponent, {data: dialogData}).afterClosed().subscribe(confirmed => {
            if (confirmed) {
                const entries = this.i18nEntries$.value.filter(e => e !== entry);
                this.i18nEntries$.next(entries);
            }
        });
    }

    createFromSearchTerm(): boolean {
        const searchTerm = this.searchTermFormControl.value;
        if (searchTerm == null || searchTerm.length === 0) {
            return false;
        }

        const newEntry = {
            key: searchTerm,
            values: this.languages.reduce((translation: any, language) => {
                translation[language] = '';
                return translation;
            }, {}),
        };
        this.i18nEntries$.next([...this.i18nEntries$.value, newEntry]);

        return false;
    }

    public toggleBody(key: string): void {
        if (this.visibleBodies.has(key)) {
            this.visibleBodies.delete(key);
        } else {
            this.visibleBodies.add(key);
        }
    }

    public isBodyVisible(key: string): boolean {
        return this.visibleBodies.has(key);
    }

    getDisplayValue(i18nEntry: I18nEntry, defaultLanguage: string) {
        return ckEditorUtils.cleanupParagraphs(i18nEntry.values[defaultLanguage] ?? '');
    }

    private cleanEntries(i18nEntries: I18nEntry[]): I18nEntry[] {
        return i18nEntries.map(i18nEntry => {
            return {
                key: i18nEntry.key,
                values: i18nEntry.values != null
                    ? Object.keys(i18nEntry.values).reduce((aggr, lang) => {
                        const value = i18nEntry.values[lang];
                        aggr[lang] = ckEditorUtils.cleanupParagraphs(value);
                        return aggr;
                    }, {})
                    : i18nEntry.values,
            };
        });
    }
}
