import {Component, Inject, Optional, ViewChild} from '@angular/core';
import {BuilderStateService} from '../../../../../builder-state.service';
import {SlideshowWidget} from '../../slideshow-widget.model';
import {ProjectUrl} from '../../../../../../shared/projects/project-url.service';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {SlideshowWidgetEditorComponent} from '../../slideshow-widget-editor/slideshow-widget-editor.component';

export interface SlideshowWidgetEditorModalComponentData {
    slideshowWidget: SlideshowWidget;
}

@Component({
    selector: 'slideshow-widget-editor-modal',
    templateUrl: './slideshow-widget-editor-modal.component.html',
    styleUrls: ['./slideshow-widget-editor-modal.component.scss'],
})
export class SlideshowWidgetEditorModalComponent {

    processing = false;

    @ViewChild(SlideshowWidgetEditorComponent)
    slideshowWidgetEditorComponent: SlideshowWidgetEditorComponent;

    constructor(
        private projectUrl: ProjectUrl,
        public state: BuilderStateService,
        @Inject(MAT_DIALOG_DATA)
        @Optional()
        public data: SlideshowWidgetEditorModalComponentData,
        @Inject(MatDialogRef) @Optional() public modalRef: MatDialogRef<SlideshowWidgetEditorModalComponent>
    ) {
    }

    submit() {
        this.close(this.data.slideshowWidget);
    }

    close(value?: SlideshowWidget) {
        this.modalRef.close(value);
    }

    addSlide() {
        this.slideshowWidgetEditorComponent.addSlide();
    }

    slideImgUrlTransformerFn = (imgUrl: string) => {
        return `${this.projectUrl.getBaseUrl(this.state.project$.value.model)}/${imgUrl}`;
    }
}
