import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {Toast} from '@common/core/ui/toast.service';
import {Themes} from '../themes/themes.service';
import {Project} from '../projects/Project';
import {Projects} from '../projects/projects.service';
import {Agency, BuilderTemplate} from '../builder-types';
import {TemplatesApi} from '../templates/templates-api.service';
import {BehaviorSubject, forkJoin} from 'rxjs';
import {Theme} from '../themes/Theme';
import {randomString} from '@common/core/utils/random-string';
import {DomHelpers} from '../dom-helpers.service';
import {Router} from '@angular/router';
import {slugifyString} from '@common/core/utils/slugify-string';
import {AgenciesApi} from '../agencies/agencies.service';
import {UploadApiConfig} from '@common/uploads/types/upload-api-config';
import {UploadUri} from '@common/uploads/types/upload-uri.enum';
import {FormControl} from '@angular/forms';
import {debounceTime, distinctUntilChanged, startWith, switchMap} from 'rxjs/operators';
import {AvatarUploadValidator} from '@common/uploads/validation/avatar-upload-validator';
import {ProjectBundle} from '../projects/ProjectBundle';
import {ProjectCreateService} from '../projects/project-create.service';
import {isMultiLanguageTemplate} from "../templates/templates-util";

const DEFAULT_FRAMEWORK = 'bootstrap-3';

export interface CrupdateProjectModalData {
    project?: Project;
    projectBundle?: ProjectBundle;
    templateName?: string;
    language?: string;
    showExtraConfig?: boolean;
}

@Component({
    selector: 'crupdate-project-modal',
    templateUrl: './crupdate-project-modal.component.html',
    styleUrls: ['./crupdate-project-modal.component.scss'],
})
export class CrupdateProjectModalComponent implements OnInit {
    public themes: Theme[] = [];
    public templates: BuilderTemplate[] = [];
    public loading = false;
    public model: Partial<Project> = {users: []};
    public updating = false;
    public errors: any = {};
    public agencyControl = new FormControl();
    public filteredAgencies: Agency[];
    public languages$ = new BehaviorSubject([null]);

    constructor(
        private dialogRef: MatDialogRef<CrupdateProjectModalComponent>,
        @Inject(MAT_DIALOG_DATA) public data: CrupdateProjectModalData,
        public projects: Projects,
        public projectCreateService: ProjectCreateService,
        private toast: Toast,
        private themesApi: Themes,
        private templatesApi: TemplatesApi,
        private router: Router,
        private agencyService: AgenciesApi,
        private avatarValidator: AvatarUploadValidator,
    ) {
    }

    ngOnInit() {
        this.updating = !!this.data.project;

        if (this.data.showExtraConfig) {
            this.getInitialData();
        }

        this.hydrateModel();

        this.agencyControl.valueChanges
            .pipe(
                startWith(''),
                debounceTime(400),
                distinctUntilChanged(),
                switchMap(value =>
                    (typeof value === 'string' && value !== '') ?
                        this.agencyService.search(value) :
                        this.filteredAgencies = []
                )
            ).subscribe(data => {
            this.filteredAgencies = data.agencies === undefined ? [] : data.agencies;
        });
    }

    public async confirm() {
        this.loading = true;
        let request;

        this.model.slug = slugifyString(this.model.name);

        if (this.updating) {
            request = this.projects.update(this.data.project.id, this.model);
        } else {
            this.model.template = this.data.templateName || this.model.template;
            this.model.language = this.data.language || this.model.language;
            request = this.projectCreateService.create(this.model);
        }

        request.subscribe(response => {
            this.close(response.project);
            const action = this.updating ? 'updated' : 'created';
            this.toast.open('Project has been ' + action);
            this.loading = false;
        }, response => {
            this.errors = response.errors;
            this.loading = false;
        });
    }

    public close(data?: any) {
        this.dialogRef.close(data);
    }

    displayAgency(value?: Agency) {
        return value ? value.name : '';
    }

    private hydrateModel() {
        this.errors = {};

        this.model = {
            name: this.data.projectBundle ? `${this.data.projectBundle.name}-${this.data.language}` : '',
            pages: [],
            template: this.data.templateName ?? this.data.projectBundle?.template ?? null,
            language: this.data.language || null,
            framework: !this.data.templateName ? DEFAULT_FRAMEWORK : null,
            uuid: randomString(36),
            users: [],
            agency: null,
            published: true,
            agency_id: this.data.projectBundle?.agency_id,
            project_bundle_id: this.data.projectBundle?.id ?? null,
        };

        if (this.data.project) {
            this.model.id = this.data.project.id;
            this.model.name = this.data.project.name;
            this.model.framework = null;
            this.model.theme = null;
            this.model.users = this.data.project.users;
            this.model.template = this.data.project.template;
            this.model.language = this.data.project.language;
            this.model.slug = this.data.project.slug;
            this.model.agency_id = this.data.project.agency_id;
            this.model.icon = this.data.project.icon;
            this.model.agency = this.data.project.agency;
            this.model.published = this.data.project.published;
        }
    }

    private getInitialData() {
        forkJoin(
            this.themesApi.all(),
            this.templatesApi.all(),
        ).subscribe(response => {
            this.themes = response[0].themes;
            this.templates = response[1].pagination.data;
        });
    }

    public insideAdmin(): boolean {
        return this.router.url.indexOf('admin') > -1;
    }

    public faviconUploadConfig(): UploadApiConfig {
        const uri = this.data.project ?
            `projects/${this.data.project.id}/favicon` :
            UploadUri.Image;
        return {
            uri,
            validator: this.avatarValidator,
        };
    }

    templateChanged(templateName: string) {
        const template = this.templates.find((t) => t.name === templateName);
        if (isMultiLanguageTemplate(template) && template.config.languages.length > 0) {
            this.languages$.next(template.config.languages);
            this.model.language = template.config.default_language;
        } else {
            this.languages$.next([null]);
            this.model.language = null;
        }
    }
}
