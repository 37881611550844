import {Injectable} from '@angular/core';
import {AppHttpClient} from '@common/core/http/app-http-client.service';
import {Observable} from 'rxjs';
import {BuilderTemplate, BuilderTemplateConfig, I18nEntry} from '../builder-types';
import {PaginatedBackendResponse} from '@common/core/types/pagination/paginated-backend-response';
import {PaginationParams} from '@common/core/types/pagination/pagination-params';

@Injectable({
    providedIn: 'root'
})
export class TemplatesApi {
    public static BASE_URI = 'templates';

    constructor(private http: AppHttpClient) {}

    /**
     * Get all available templates.
     */
    public all(params: PaginationParams = {}): PaginatedBackendResponse<BuilderTemplate> {
        return this.http.get(TemplatesApi.BASE_URI, params);
    }

    /**
     * Get template by specified id.
     */
    public get(name: string, language?: string): Observable<{template: BuilderTemplate}> {
        if (language != null) {
            return this.http.get(`${TemplatesApi.BASE_URI}/${name}/lang/${language}`);
        } else {
            return this.http.get(`${TemplatesApi.BASE_URI}/${name}`);
        }
    }

    public getConfig(name: string): Observable<{config: BuilderTemplateConfig}> {
        return this.http.get(`${TemplatesApi.BASE_URI}/${name}/config`);
    }

    /**
     * Create a new template.
     */
    public create(params: object): Observable<{template: BuilderTemplate}> {
        return this.http.post(TemplatesApi.BASE_URI, params);
    }

    /**
     * Update specified template.
     */
    public update(name: string, params: object): Observable<{template: BuilderTemplate}> {
        return this.http.put(`${TemplatesApi.BASE_URI}/${name}`, params);
    }

    /**
     * Update i18n entries of a template.
     */
    public updateI18n(name: string, i18n: I18nEntry[]): Observable<void> {
        return this.http.put(`${TemplatesApi.BASE_URI}/${name}/i18n`, {
            i18n,
        });
    }

    /**
     * Delete specified templates.
     */
    public delete(names: string[]): Observable<any> {
        return this.http.delete(TemplatesApi.BASE_URI, {names});
    }
}
