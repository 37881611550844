import {CardCarouselWidget, CardCarouselWidgetCard} from './card-carousel-widget.model';
import {isNotBlank} from '../../../../shared/util/string-util';

export function generateCardCarouselWidgetMarkup(widget: CardCarouselWidget): string {
    const buildConfigHtml = (titleColor, subtitleColor, textColor, cardInactiveOverlayColor, controlsColor, mediaPlayButtonColor) => `<div class="sb-card-carousel-widget__config" data-title-color="${titleColor ?? ''}" data-subtitle-color="${subtitleColor ?? ''}" data-text-color="${textColor ?? ''}" data-card-inactive-overlay-color="${cardInactiveOverlayColor ?? ''}" data-controls-color="${controlsColor ?? ''}" data-media-play-button-color="${mediaPlayButtonColor ?? ''}"></div>`;

    const elements = [];

    elements.push(buildConfigHtml(
        widget.titleColor,
        widget.subtitleColor,
        widget.textColor,
        widget.cardInactiveOverlayColor,
        widget.controlsColor,
        widget.mediaPlayButtonColor
    ));

    widget.cards
        .map(card => generateCardCarouselWidgetCardMarkup(card))
        .forEach(markMarkup => elements.push(markMarkup));

    return elements.join('\n');
}

export function generateCardCarouselWidgetCardMarkup(card: CardCarouselWidgetCard): string {
    const buildTitleHtml = (title) => `<p class="sb-card-carousel-widget__card__title">${title ?? ''}</p>`;
    const buildSubtitleHtml = (subtitle) => `<p class="sb-card-carousel-widget__card__subtitle">${subtitle ?? ''}</p>`;
    const buildTextHtml = (text) => `<p class="sb-card-carousel-widget__card__text">${text ?? ''}</p>`;
    const buildImageHtml = (src, alt) => `<div class="sb-card-carousel-widget__card__image" data-src="${src ?? ''}" data-alt="${alt ?? ''}"></div>`;
    const buildVideoHtml = (src, url) => `<div class="sb-card-carousel-widget__card__video" data-src="${src ?? ''}" data-url="${url ?? ''}"></div>`;

    const elements = [];

    let dataMediaType = '';
    if (isNotBlank(card.mediaType)) {
        dataMediaType = `data-media-type="${card.mediaType ?? ''}"`;
    }

    elements.push(`<div class="sb-card-carousel-widget__card" data-enabled="${card.enabled ?? 'true'}" ${dataMediaType}>`);

    if (isNotBlank(card.title)) {
        elements.push(buildTitleHtml(card.title));
    }

    if (isNotBlank(card.subtitle)) {
        elements.push(buildSubtitleHtml(card.subtitle));
    }

    if (isNotBlank(card.text)) {
        elements.push(buildTextHtml(card.text));
    }

    if (isNotBlank(card.imageSrc)) {
        elements.push(buildImageHtml(card.imageSrc, card.imageAlt));
    }

    if (isNotBlank(card.videoSrc)) {
        elements.push(buildVideoHtml(card.videoSrc, card.videoUrl));
    }

    elements.push(`</div>`);

    return elements.join('\n');
}

