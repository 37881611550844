import {Injectable} from '@angular/core';
import {BuilderPage, BuilderProject, ContentBlock, ContentBlockKeyRef, ContentBlockUpdate} from '../../shared/builder-types';
import {ContentBlocks} from '../../shared/content-blocks/content-blocks.service';
import {ProjectChooserModalComponent} from './project-chooser-modal/project-chooser-modal.component';
import {BuilderStateService} from '../builder-state.service';
import {Modal} from '@common/core/ui/dialogs/modal.service';
import {Projects} from '../../shared/projects/projects.service';
import {addIdToNode} from '../utils/add-id-to-node';
import {getNodesBetweenCommentBlock} from '../utils/node-iterator';
import {distinctUntilChanged, filter, map, tap} from 'rxjs/operators';
import {EMPTY} from 'rxjs';
import {Project} from '../../shared/projects/Project';
import {ContentBlockChangeDetector} from './content-block-change-detector';
import {ContentBlockUpdateProcessor} from './content-block-update-processor';
import {ContentBlockApplier} from './content-block-applier';

@Injectable({
    providedIn: 'root'
})
export class ContentBlockService {
    private contentBlocks: ContentBlock[] = [];
    private contentBlocksUpdates: Map<string, ContentBlockUpdate> = new Map();

    constructor(
        private contentBlocksApi: ContentBlocks,
        private projects: Projects,
        private state: BuilderStateService,
        private modal: Modal
    ) {
        state.project$.pipe(
            filter(project => project != null),
            distinctUntilChanged((p: BuilderProject, q: BuilderProject) => p?.model?.id === q?.model?.id),
        ).subscribe(() => {
            this.initialize();
        });
    }

    public initialize() {
        this.loadContentBlocks().subscribe();
    }

    public async save() {
        await this.saveGlobalContentBlocks();
        return this.loadContentBlocks().toPromise();
    }

    private async saveGlobalContentBlocks() {
        return new Promise<void>(resolve => {
            const project = this.state.project$.value;
            this.projects.listSimpleAll({template: project.model.template})
                .pipe(
                    map(projectsResponse => projectsResponse.pagination.data)
                ).subscribe(projects => {
                const contentBlocksUpdates = this.getContentBlockUpdates();
                const globalContentBlocks = contentBlocksUpdates.filter(block => block.is_local === false);
                if (globalContentBlocks.length > 0) {
                    this.modal.show(ProjectChooserModalComponent, {
                        project: this.state.project$.value.model,
                        contentBlocks: contentBlocksUpdates,
                        projects
                    }).afterClosed().subscribe((selectedProjects: Project[]) => {
                        if (!selectedProjects) {
                            resolve();
                            return;
                        }

                        const selectedProjectIds = selectedProjects.map(selectedProject => selectedProject.id);
                        const contentBlockReferences = globalContentBlocks.map((contentBlockUpdate) => {
                            const contentBlockReference: ContentBlockKeyRef = {
                                content_key: contentBlockUpdate.content_key,
                            };
                            return contentBlockReference;
                        });

                        this.contentBlocksApi.updateProjects(project.model.id, selectedProjectIds, contentBlockReferences).subscribe();
                        resolve();
                    });
                } else {
                    resolve();
                }
            });
        });
    }

    public checkContentBlocks() {
        const docHtml = this.state.activePage$.value.doc.documentElement.outerHTML;

        const updateDetector = new ContentBlockChangeDetector();
        const detectedChanges = updateDetector.detectChanges(docHtml, this.contentBlocks, this.contentBlocksUpdates);

        for (const change of detectedChanges) {
            const existingUpdate = this.contentBlocksUpdates.get(change.contentBlock.content_key);
            if (existingUpdate == null) {
                this.contentBlocksUpdates.set(change.contentBlock.content_key, {
                    content_key: change.contentBlock.content_key,
                    content_block_id: change.contentBlock.content_block.id,
                    content: change.newContent,
                    is_local: change.contentBlock.is_local,
                });
            } else {
                existingUpdate.content = change.newContent;
            }
        }

        const updateProcessor = new ContentBlockUpdateProcessor();
        const contentBlockUpdates = updateProcessor.createOrUpdateContentBlockUpdates(this.contentBlocks, Array.from(this.contentBlocksUpdates.values()));

        for (const change of contentBlockUpdates) {
            const existingUpdate = this.contentBlocksUpdates.get(change.content_key);
            if (existingUpdate == null) {
                this.contentBlocksUpdates.set(change.content_key, {
                    content_key: change.content_key,
                    content_block_id: change.content_block_id,
                    content: change.content,
                    is_local: change.is_local,
                });
            } else {
                existingUpdate.content = change.content;
            }
        }

        return this;
    }

    private getContentBlockByKey(contentBlockKey: string): ContentBlock | null {
        return this.contentBlocks.find(contentBlock => contentBlock.content_key === contentBlockKey) ?? null;
    }

    private loadContentBlocks() {
        const project = this.state.project$.value;
        if (project?.model?.id == null) {
            return EMPTY;
        }

        return this.contentBlocksApi.byProject(this.state.project$.value.model.id)
            .pipe(tap(contentBlocksResponse => {
                return this.contentBlocks = contentBlocksResponse.data;
            }));
    }

    public applyAllContentBlocks(content: string): string {
        let processedContent = content;
        for (const contentBlock of this.contentBlocks) {
            processedContent = ContentBlockApplier.applyBlockForHtml(processedContent, contentBlock.content_key, contentBlock.content);
        }
        for (const contentBlockUpdate of this.getContentBlockUpdates()) {
            processedContent = ContentBlockApplier.applyBlockForHtml(processedContent, contentBlockUpdate.content_key, contentBlockUpdate.content);
        }
        return processedContent;
    }

    public updateContentBlocks(page?: BuilderPage) {
        for (const contentBlock of this.getContentBlockUpdates()) {
            this.updateContentBlock(contentBlock, page);
        }

        return this;
    }

    public updateContentBlock(contentBlockOrUpdate: ContentBlock | ContentBlockUpdate, page?: BuilderPage) {
        if (page == null) {
            page = this.state.activePage$.value;
        }

        const pageDocument = page.doc.documentElement;
        const pageDocumentBody = pageDocument.getElementsByTagName('body')[0];

        const updatedBodyHTML = ContentBlockApplier.applyBlockForHtml(pageDocumentBody.innerHTML, contentBlockOrUpdate.content_key, contentBlockOrUpdate.content);
        pageDocumentBody.innerHTML = updatedBodyHTML;

        const nodes = getNodesBetweenCommentBlock(
            pageDocument,
            `start-data-block='${contentBlockOrUpdate.content_key}`,
            `end-data-block='${contentBlockOrUpdate.content_key}`
        );
        for (const node of nodes) {
            addIdToNode(node, true);
        }
    }

    public getContentBlock(node: HTMLElement | Element): ContentBlock | null {
        if (!node) {
            return null;
        }
        // console.log('getContentBlock - node', node);
        const contentBlockElement = node.classList.contains('content-block') ? node : node.closest('.content-block');

        if (contentBlockElement == null) {
            return null;
        }
        // console.log('data-block', contentBlockElement.getAttribute('data-block'));

        return this.getContentBlockByKey(contentBlockElement.getAttribute('data-block'));
    }

    public getContentBlockUpdates(): ContentBlockUpdate[] {
        return Array.from(this.contentBlocksUpdates.values());
    }
}
