<div class="modal-header">
    <h2 mat-dialog-title trans>Edit translations (i18n)</h2>

    <div class="flex flex-row gap-4 mr-3">

        <div class="input-container">
            <input type="text"
                   matInput
                   placeholder="Filter"
                   [formControl]="searchTermFormControl"
                   [disabled]="loading"
                   (focus)="$event.target.select()">
        </div>

        <div class="flex flex-row gap-2 items-center">
            <span>Total:</span>
            <span *ngIf="isFiltering$ | async">{{ filteredI18nEntriesCount$ | async }}</span>
            <span *ngIf="isFiltering$ | async"> / </span>
            <span>{{ i18nEntriesCount$ | async }}</span>
        </div>

    </div>

    <button type="button" (click)="close()" class="close-button no-style">
        <mat-icon svgIcon="close"></mat-icon>
    </button>
</div>

<mat-dialog-content>
    <mat-progress-bar mode="indeterminate" color="accent" *ngIf="loading"></mat-progress-bar>

    <div *ngFor="let i18nEntry of filteredI18nEntries$ | async"
         class="item"
         [class.body-visible]="isBodyVisible(i18nEntry.key)">

        <div class="item-header flex flex-row" (click)="toggleBody(i18nEntry.key)">
            <div class="flex flex-col flex-grow">
                <span>{{ getDisplayValue(i18nEntry, defaultLanguage) }}</span>
                <small class="text-muted">{{ i18nEntry.key }}</small>
            </div>
            <div>
                <button (click)="deleteEntry(i18nEntry)" mat-icon-button
                        [disabled]="loading"
                        [matTooltip]="'Delete entry' | trans">
                    <mat-icon svgIcon="delete"></mat-icon>
                </button>
            </div>
        </div>

        <div class="item-body flex flex-col" *ngIf="isBodyVisible(i18nEntry.key)">

            <!--            <div class="text-right">Key</div>-->
            <!--            <div>-->
            <!--                <div class="input-container">-->
            <!--                    <input type="text"-->
            <!--                           matInput-->
            <!--                           [ngModel]="i18nEntry.key"-->
            <!--                           (ngModelChange)="setKey(i18nEntry, $event)"-->
            <!--                           [disabled]="loading">-->
            <!--                </div>-->
            <!--            </div>-->

            <ng-container *ngFor="let language of languages">
                <div class="text-right">
                    <language-badge [default]="language === defaultLanguage" [active]="language === data.currentLanguage">{{ language }}
                    </language-badge>
                </div>
                <div>
                    <div class="input-container">
                        <ckeditor [editor]="ckEditor5.Editor"
                                  [config]="ckEditor5.configMinimal"
                                  [ngModel]="i18nEntry.values[language]"
                                  (ngModelChange)="setValue(i18nEntry, language, $event)"
                                  [disabled]="loading"
                        ></ckeditor>
                    </div>
                </div>
            </ng-container>

        </div>

    </div>

    <div style="padding-left: 24px">
        <button (click)="addEntry()" mat-icon-button
                [matTooltip]="'Add entry' | trans"
                [disabled]="loading">
            <mat-icon svgIcon="add"></mat-icon>
        </button>
    </div>

    <div *ngIf="createFromSearchTerm$ | async" class="mt-2 mb-2">
        <a (click)="createFromSearchTerm()" href=""><span trans>Create entry with key:</span> {{ searchTerm$ | async }}</a>
    </div>
</mat-dialog-content>

<mat-dialog-actions class="buttons right mat-dialog-actions">
    <button (click)="close()" type="button" mat-button trans>Cancel</button>
    <button (click)="confirm()" mat-raised-button color="accent" [disabled]="loading" trans>Save</button>
</mat-dialog-actions>
