import {Component, EventEmitter, Input, OnDestroy, OnInit, Output, TemplateRef} from '@angular/core';
import {duplicateSlide, SlideshowWidget, SlideshowWidgetSlide} from '../slideshow-widget.model';
import {CdkDragDrop, moveItemInArray} from '@angular/cdk/drag-drop';
import {ImageElementService} from '../../../image-element.service';
import {ComponentSubscriptionService} from '@common/core/utils/component-subscription-service';
import {ConfirmModalComponent} from '@common/core/ui/confirm-modal/confirm-modal.component';
import {Modal} from '@common/core/ui/dialogs/modal.service';
import {Settings} from '@common/core/config/settings.service';
import {OverlayPanel} from '@common/core/ui/overlay-panel/overlay-panel.service';
import {CurrentUser} from '@common/auth/current-user';
import {randomString} from '@common/core/utils/random-string';

@Component({
    selector: 'slideshow-widget-editor',
    templateUrl: './slideshow-widget-editor.component.html',
    styleUrls: ['./slideshow-widget-editor.component.scss'],
})
export class SlideshowWidgetEditorComponent implements OnInit, OnDestroy {

    @Input() slideshowWidget: SlideshowWidget;
    @Input() slideshowSettingsEditable = true;
    @Input() positionEditable = true;
    @Input() slideImgUrlTransformerFn?: (url: string) => string;
    @Input() slideImgSelectorTemplate?: TemplateRef<any> | null = null;
    @Output() processing = new EventEmitter<boolean>();

    townsignEnabled = false;

    _processing = false;
    _subscriptions = new ComponentSubscriptionService();

    constructor(
        public imageElementService: ImageElementService,
        public modal: Modal,
        public panel: OverlayPanel,
        public settings: Settings,
        public currentUser: CurrentUser,
    ) {
        this._subscriptions.add(this.processing.subscribe(p => this._processing = p));
        this.townsignEnabled = settings.get('builder.enable_townsign_widget', false);
    }

    ngOnInit() {
        this.migrate();
    }

    /**
     * Some properties are added later on and therefore are not available or set when loaded from the database yet.
     * Here we set proper default values for these cases.
     */
    migrate() {
        this.slideshowWidget.slides = (this.slideshowWidget.slides ?? []).map((slide: SlideshowWidgetSlide) => {
            return {
                ...slide,
                id: slide.id ?? randomString(8),
                hrefTarget: slide.hrefTarget ?? '_blank',
                buttonTarget: slide.buttonTarget ?? '',
                listItems: slide.listItems ?? [],
                bgPosition: slide.bgPosition ?? 'center center',
                bgImageMobilePositionAbove: slide.bgImageMobilePositionAbove ?? false,
                townsignMobileVisible: slide.townsignMobileVisible ?? true,
            } as SlideshowWidgetSlide;
        });
    }

    ngOnDestroy(): void {
        this._subscriptions.unsubscribe();
    }

    drop(event: CdkDragDrop<string[]>) {
        moveItemInArray(this.slideshowWidget.slides, event.previousIndex, event.currentIndex);
    }

    getBgImageSrc(slide: SlideshowWidgetSlide): string | undefined {
        if (slide.bgImageSrc == null || slide.bgImageSrc.length === 0) {
            return undefined;
        }
        if (this.slideImgUrlTransformerFn != null) {
            return this.slideImgUrlTransformerFn(slide.bgImageSrc);
        }
        return slide.bgImageSrc;
    }

    updateSlideImage(slide: SlideshowWidgetSlide) {
        this.processing.emit(true);
        this.imageElementService.openModal().subscribe((url: string) => {

            this.processing.emit(false);

            if (!url) return;

            slide.bgImageSrc = url;
        });
    }

    deleteSlideImage(item: SlideshowWidgetSlide) {
        item.bgImageSrc = undefined;
        item.bgImageAlt = undefined;
    }

    addSlide() {
        const newSlide = new SlideshowWidgetSlide();
        this.slideshowWidget.slides.push(newSlide);
    }

    duplicateSlide(slideIndex: number): void {
        const newSlide = duplicateSlide(this.slideshowWidget.slides[slideIndex]);
        this.slideshowWidget.slides.splice(slideIndex + 1, 0, newSlide);
    }

    removeSlide(slideIndex: number): void {
        this.modal.open(ConfirmModalComponent, {
            title: 'Remove slide',
            body: 'Are you sure you want to remove this slide?',
            ok: 'Remove',
        }).afterClosed().subscribe(confirmed => {
            if (!confirmed) return;

            this.slideshowWidget.slides.splice(slideIndex, 1);
        });
    }

    isNumber(n: number | string): boolean {
        return Number.isInteger(n);
    }

    canMoveSlideUp(slideIndex: number) {
        return slideIndex > 0;
    }

    moveSlideUp(slideIndex: number) {
        const slide = this.slideshowWidget.slides[slideIndex];
        this.slideshowWidget.slides[slideIndex] = this.slideshowWidget.slides[slideIndex - 1];
        this.slideshowWidget.slides[slideIndex - 1] = slide;
    }

    canMoveSlideDown(slideIndex: number) {
        return slideIndex < this.slideshowWidget.slides.length - 1;
    }

    moveSlideDown(slideIndex: number) {
        const slide = this.slideshowWidget.slides[slideIndex];
        this.slideshowWidget.slides[slideIndex] = this.slideshowWidget.slides[slideIndex + 1];
        this.slideshowWidget.slides[slideIndex + 1] = slide;
    }

}
