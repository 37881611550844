import {Injector, ProviderToken} from '@angular/core';
import {getValidActions} from './element.util';
import {BuilderSidebarPanel} from "../sidebar/builder-sidebar-panel";

export enum EditableProp {
    Padding = 'padding',
    Margin = 'margin',
    Border = 'border',
    Text = 'text',
    Attributes = 'attributes',
    Shadow = 'shadow',
    Background = 'background',
}

export interface ArchitectElControlConfig {
    label: string;
    type: ElControlType;
    defaultValue: string | ((node: HTMLElement) => string);
    inputType?: 'text' | 'number';
    options?: { key: string; value: string }[];
    onChange?: (node: HTMLElement, value: string) => void;
}

export class ArchitectElControl implements Partial<ArchitectElControlConfig> {
    label: string;

    protected get<T>(token: ProviderToken<T>): T {
        return this.injector.get(token);
    }

    constructor(
        protected injector: Injector,
        config: ArchitectElControlConfig
    ) {
        Object.entries(config).forEach(([key, value]) => {
            this[key] = value;
        });
    }
}

export enum ElControlType {
    Select = 'select',
    Input = 'input',
}

export interface ArchitectElementAction {
    /**
     * Identifier of an action. This is used, to prevent to show an action multiple times.
     */
    id?: string;

    /**
     * The name of the action. This is displayed to the user.
     */
    name: string;

    /**
     * The function to execute when the action is clicked
     * @param node The node that the action was clicked on
     */
    onClick: (node: HTMLElement) => void;

    /**
     * Determines if the action is enabled for the given node. If not provided, the action is always enabled.
     * @param node The node to check
     */
    isEnabled?: (node: HTMLElement) => boolean;

    /**
     * List of action ids that will be prevented to be enabled, if this action is enabled. This is useful if an action is "overriding" another action.
     */
    preventActionIds?: string[];
}

/**
 * Base class for all architect elements
 */
export abstract class ArchitectElement {
    abstract name: string;

    /**
     * Icon that is shown in the panel.
     */
    icon?: string;

    category?: string;

    /**
     * HTML Content that is used, when the element is dropped/inserted into the builder.
     */
    html?: string | (() => string);
    css?: string; // do not use this, since this triggers a save-action. put the css instead in edsitebuilder-ui-elements
    hiddenClasses?: string[] = [];
    specificity = 0;

    /**
     * If set to the true, all elements below this element will be blocked.
     * Such architect elements should use node.closest(.) in the matcher function, so that the element matches, if a child node is selected.
     */
    blocksContent = false;

    hiddenElement = false;

    editActions: ArchitectElementAction[] = [];

    canEdit: EditableProp[] = [
        EditableProp.Padding,
        EditableProp.Margin,
        EditableProp.Border,
        EditableProp.Attributes,
        EditableProp.Shadow,
        EditableProp.Background,
    ];
    defaultInspectorPanel = BuilderSidebarPanel.Inspector;
    canDrag = true;
    canCopy = true;
    canDelete = true;
    controls: any[] = [];
    resizable = true;
    contextMenu = true;
    contentCategories = ['flow'];
    allowedContent: string[] = ['flow'];
    allowedEls: typeof ArchitectElement[] = [];

    /**
     * Checks, if the architect element is active for the given node.
     * If it is for the node itself, the 'true' is returned.
     * If it matches, because of another element (e.g. some parent), the matching parent element is returned.
     * If it does not match, 'false' is returned
     * @param node node to check for a match
     */
    abstract matcher(node: HTMLElement): boolean | HTMLElement;

    /**
     * Returns a provider by the given token. This is useful to get a service within an element method.
     * @param token token of the provider
     * @protected
     */
    protected get<T>(token: ProviderToken<T>): T {
        return this.injector.get(token);
    }

    /**
     * Base constructor
     * @param injector Injector
     */
    constructor(protected injector: Injector) {
    }

    /**
     * Returns the enabled actions for the given node
     * @param node The node to check
     */
    getEnabledActions(node: HTMLElement): ArchitectElementAction[] {
        const allActions = this.editActions
            .filter(action => action.isEnabled == null || action.isEnabled(node));
        const validActions = getValidActions(allActions);
        return validActions;
    }
}
