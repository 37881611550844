import {Injectable} from '@angular/core';
import {Settings} from '@common/core/config/settings.service';
import {CurrentUser} from '@common/auth/current-user';
import {Project} from './Project';
import {slugifyString} from '@common/core/utils/slugify-string';

@Injectable({
    providedIn: 'root',
})
export class ProjectUrl {
    constructor(private settings: Settings, private currentUser: CurrentUser) {}

    getBaseUrl(project: Project, relative: boolean = false): string {
        const uri = `projects/${this.getProjectUserId(project)}/${
            project.uuid
        }/`;

        if (relative) return uri;

        return `${this.settings.getBaseUrl()}/storage/${uri}`;
    }

    getProductionUrl(project: Project, destination?: string): string {
        if (!destination) {
            destination = project.settings.publishDestination as string;
        }
        if (destination === 'customDomain' && project.domain) {
            return project.domain.host;
        } else if (destination === 'subdomain') {
            return this.getSubdomainUrl(project);
        } else {
            return this.getDefaultUrl(project);
        }
    }

    getDefaultUrl(project: Project): string {
        const base = this.settings.getBaseUrl(true);
        return `${base}/sites/${project.slug}`.replace(/\/$/, '');
    }

    getSubdomainUrl(project: Project): string {
        const base = this.settings.getBaseUrl(true);
        // strip pathname from url if generating subdomain
        const parsed = new URL(base);
        return `${parsed.protocol}//${project.slug}.${parsed.host}`.replace(
            /\/$/,
            ''
        );
    }

    private getProjectUserId(project: Project): number | string {
        if (!project.users || !project.users.length) {
            return this.currentUser.get('id');
        }
        return project.users[0].id;
    }

    /**
     * Get production site url for specified project.
     */
    public getSiteUrl(project: Project, forcePublished: boolean = true): string {
        if (project.slug && project.published && forcePublished) {
            return 'https://web01.comesio.solutions/' + project.slug;
        }
        let base = this.settings.getBaseUrl(true);
        // const projectName = slugifyString(project.name);
        const projectName = slugifyString(project.name) + '-' + project.agency_id;
        let url;

        if (project.domain) {
            return project.domain.host;
        } else if (this.settings.get('builder.enable_subdomains')) {
            // strip pathname from url if generating subdomain
            const parsed = new URL(base);
            url = `${parsed.protocol}//${projectName}.${parsed.host}`;
        } else {
            if (!base.endsWith('/')) {
                base += '/';
            }
            url = base + 'sites/' + projectName;
        }

        return url.replace(/\/$/, '');
    }

    /**
     * Get local site url for specified project.
     */
    public getSitePreviewUrl(project: Project): string {
        let base = this.settings.getBaseUrl(true);

        if (!base.endsWith('/')) {
            base += '/';
        }
        const url = base + 'sites/' + project.slug_preview;

        return url.replace(/\/$/, '');
    }
}
