import {Injectable} from '@angular/core';
import {Params, Router} from '@angular/router';
import {BehaviorSubject, Observable, of, ReplaySubject, Subscription} from 'rxjs';
import {map, switchMap, take, tap} from 'rxjs/operators';
import {PaginationResponse} from '@common/core/types/pagination/pagination-response';
import {AppHttpClient} from '@common/core/http/app-http-client.service';
import {PaginatedBackendResponse} from '@common/core/types/pagination/paginated-backend-response';
import {PaginationParams} from '@common/core/types/pagination/pagination-params';
import {LocalStorage} from '@common/core/services/local-storage.service';

@Injectable({
    providedIn: 'root',
})
export class Paginator<T> {
    private data?: T[];

    private params$ = new BehaviorSubject<PaginationParams>({});
    private backendUri: string;
    private lastResponse$ = new ReplaySubject<PaginationResponse<T>>(1);
    private subscription: Subscription;
    private initiated = false;
    public perPageCacheKey: string = null;
    public paginatedOnce$ = new BehaviorSubject<boolean>(false);

    get params(): PaginationParams {
        return this.params$.value;
    }

    // might not want to update query params sometimes
    // if data table is only smaller part of the page
    public dontUpdateQueryParams = false;
    public loading$ = new BehaviorSubject(false);
    public response$ = new BehaviorSubject<{ pagination: PaginationResponse<T>, [key: string]: any }>(null);

    public get pagination$(): Observable<PaginationResponse<T>> {
        return this.lastResponse$.asObservable();
    }

    public get noResults$() {
        // only return TRUE if data has already been
        // loaded from backend and there were no results
        return this.pagination$.pipe(map(p => !!p.data && p.data.length === 0));
    }

    get currentPage(): number {
        return this.response$.value?.pagination?.current_page;
    }

    constructor(
        private router: Router,
        private http: AppHttpClient,
        private localStorage?: LocalStorage,
    ) {
    }

    public setData(data: T[]) {
        this.data = data;
        this.paginate({perPage: this.response$?.value?.pagination?.per_page ?? 10});
    }

    public paginate(userParams: object = {}, url?: string, initialData?: PaginationResponse<T>): Observable<PaginationResponse<T>> {
        // only use query params on first pagination, so query params can be removed via user params
        const queryParams = !this.subscription ? this.currentQueryParams() : {};
        const paginationParams = this.response$.value ? {
            perPage: this.response$.value.pagination.per_page,
            page: this.response$.value.pagination.current_page
        } : {};
        this.params$.next({...queryParams, ...userParams});
        if (!this.initiated) {
            this.init(url, initialData);
        }

        // prevent multiple subscriptions
        return this.pagination$.pipe(take(1));
    }

    public setPagination(pagination: PaginationResponse<T>) {
        this.lastResponse$.next(pagination);
        this.paginatedOnce$.next(true);
    }

    public currentQueryParams(): Params {
        return this.router.routerState.root.snapshot.queryParams;
    }

    private init(uri: string, initialData?: PaginationResponse<T>) {
        this.backendUri = uri;
        this.subscription = this.params$.pipe(
            switchMap(params => {
                this.loading$.next(true);
                const firstPagination = !this.paginatedOnce$.value;
                if (firstPagination && this.perPageCacheKey && this.localStorage.get(this.perPageCacheKey)) {
                    params = {perPage: this.localStorage.get(this.perPageCacheKey), ...params};
                }

                // if we got initial pagination response (of 1st page)
                // return that instead of making 1st page http request
                const request = !this.paginatedOnce$.value && initialData ?
                    of({pagination: initialData}) :
                    this.backendUri != null ?
                        this.http.get(this.backendUri, params) :
                        of(this.localPagination(this.data, params));

                return request.pipe(
                    // can't use "finalize" here as it will complete after loading$.next(true)
                    // call above, which will prevent loading bar from showing
                    // if pagination request is cancelled and new one is queued
                    tap(() => {
                        this.updateQueryParams(params);
                        this.loading$.next(false);
                        this.paginatedOnce$.next(true);
                    }, () => {
                        this.loading$.next(false);
                        this.paginatedOnce$.next(true);
                    })
                ) as PaginatedBackendResponse<T>;
            })
        ).subscribe(response => {
            this.lastResponse$.next(response.pagination);
            this.response$.next(response);
        });

        this.initiated = true;
    }

    private updateQueryParams(params = {}) {
        if (this.dontUpdateQueryParams) return;
        this.router.navigate([], {queryParams: params, replaceUrl: true});
    }

    public destroy() {
        this.subscription && this.subscription.unsubscribe();
    }


    public canLoadPrevPage(): boolean {
        const data = this.response$.value?.pagination;
        if (data) {
            return !!data.prev_cursor || (this.currentPage > 1);
        }
        return false;
    }

    public canLoadNextPage(): boolean {
        const data = this.response$.value?.pagination;
        if (data) {
            return !!data.next_cursor || (this.currentPage < data.last_page);
        }
        return false;
    }

    public nextPage() {
        const current = this.response$.value.pagination.current_page || 0;
        this.paginate({
            ...this.params$.value,
            page: current + 1,
            cursor: this.response$.value.pagination.next_cursor,
        });
    }

    public previousPage() {
        const current = this.response$.value.pagination.current_page;
        this.paginate({
            ...this.params$.value,
            page: (current - 1) || 1,
            cursor: this.response$.value.pagination.prev_cursor,
        });
    }

    public changePerPage(newPerPage: number) {
        if (newPerPage !== this.params$.value?.perPage) {
            if (this.perPageCacheKey) {
                this.localStorage.set(this.perPageCacheKey, newPerPage);
            }
            this.paginate({
                ...this.params$.value,
                perPage: newPerPage
            });
        }
    }

    private localPagination(data: T[] = [], params: PaginationParams): { pagination: PaginationResponse<T> } {
        const page = params.page ?? 1;
        const perPage = params.perPage ?? 10;
        const from = (page - 1) * perPage;
        const to = (page - 1) * perPage + perPage;
        const paginationResponse: PaginationResponse<T> = {
            data: data.slice(from, to),
            from,
            to,
            total: data.length,
            per_page: perPage,
            current_page: page,
            last_page: Math.max(Math.ceil(data.length / perPage), 1),
        };
        return {
            pagination: paginationResponse
        };
    }
}
