import {Injectable} from '@angular/core';
import {BehaviorSubject, Observable} from 'rxjs';
import {UserSettingsService} from '@common/auth/user-settings.service';

@Injectable({
    providedIn: 'root',
})
export class ColorpickerPresetsService {
    private _colors$: BehaviorSubject<string[]> = new BehaviorSubject([]);
    private readonly colorPresetsSettingsName: string = 'colorPresets';

    constructor(
        private settings: UserSettingsService
    ) {
        this.fetchColorsFromBackend();
    }

    public save(colors: string[]): void {
        this.settings.save(this.colorPresetsSettingsName, colors);
    }

    public get colors$(): Observable<string[]> {
        return this._colors$;
    }

    private fetchColorsFromBackend() {
        this.settings.get(this.colorPresetsSettingsName)
            .subscribe({next: it => this._colors$.next(it ?? [])});
    }
}
