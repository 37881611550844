<div class="modal-header">
    <h2 class="modal-title" mat-dialog-title *ngIf="updating" trans>Update Project</h2>
    <h2 class="modal-title" mat-dialog-title *ngIf="!updating" trans>Create Project</h2>
    <button type="button" (click)="close()" class="close-button" mat-icon-button tabindex="-1">
        <mat-icon svgIcon="close"></mat-icon>
    </button>
</div>

<form autocomplete="off" (ngSubmit)="confirm()" ngNativeValidate>
    <mat-dialog-content class="many-inputs" *ngIf="!data.projectBundle">
        <div class="input-container">
            <label for="name" trans>Name</label>
            <input type="text" id="name" name="name" [(ngModel)]="model.name" required>
            <div class="error" *ngIf="errors.name">{{errors.name}}</div>
        </div>

        <div class="input-container" *ngIf="model.id">
            <label for="project-icon" trans>Favicon hochladen</label>
            <upload-image-control [uploadConfig]="faviconUploadConfig()" class="inherit-size" id="project-icon" name="project-icon" [(ngModel)]="model.icon" style="height: 150px;width: 150px;"></upload-image-control>
        </div>

        <ng-container *ngIf="data.showExtraConfig">
            <div class="input-container" *ngIf="!updating">
                <select-user-input [(ngModel)]="model.users[0]" name="project-owner"></select-user-input>
            </div>

            <div class="input-container">
                <label for="agency_id" trans>Agency</label>
                <mat-form-field class="width-full" *ngIf="!model.id">
                    <input id="agency_id" matInput [formControl]="agencyControl" name="agency_id"
                           [matAutocomplete]="agencyAuto" [(ngModel)]="model.agency">
                    <mat-autocomplete #agencyAuto="matAutocomplete" [displayWith]="displayAgency.bind(this)">
                        <mat-option *ngFor="let agency of filteredAgencies"
                                    [value]="agency">
                            {{agency.name}} {{agency.own_id ? '(' + agency.own_id + ')' : ''}}
                        </mat-option>
                    </mat-autocomplete>
                </mat-form-field>
                <input *ngIf="model.id" type="text" [value]="model.agency.name" disabled>
            </div>

            <div class="input-container">
                <label for="template" trans>Template</label>
                <select id="template" name="template" [(ngModel)]="model.template" (ngModelChange)="templateChanged(model.template)">
                    <option [ngValue]="null" trans>None</option>
                    <option [ngValue]="template.name" *ngFor="let template of templates">{{template.name}}</option>
                </select>
                <div class="error" *ngIf="errors.template">{{errors.template}}</div>
            </div>

            <div class="input-container">
                <label for="language" trans>Language</label>
                <select id="language" name="language" [(ngModel)]="model.language">
                    <ng-container *ngFor="let language of languages$ | async">
                        <option *ngIf="language; else defaultLanguage" [ngValue]="language">{{language}}</option>
                        <ng-template #defaultLanguage>
                            <option [ngValue]="null" trans>Default</option>
                        </ng-template>
                    </ng-container>
                </select>
                <div class="error" *ngIf="errors.language">{{errors.language}}</div>
            </div>

            <div class="input-container">
                <label for="published" trans>Published</label>
                <select id="published" name="published" [(ngModel)]="model.published">
                    <option [ngValue]="true" trans>Yes</option>
                    <option [ngValue]="false" trans>No</option>
                </select>
                <div class="error" *ngIf="errors.published">{{errors.published}}</div>
            </div>

        </ng-container>
    </mat-dialog-content>

    <mat-dialog-actions class="buttons right">
        <button (click)="close()" type="button" mat-button class="cancel-button" trans>Cancel</button>
        <button type="submit" class="button primary" mat-raised-button color="accent" [disabled]="loading">
            <span *ngIf="updating" trans>Update</span>
            <span *ngIf="!updating" trans>Create</span>
        </button>
    </mat-dialog-actions>
</form>
