import {ContentBlock, ContentBlockUpdate} from '../../shared/builder-types';
import {ContentBlockComparator} from './content-block-comparator';
import {removeArIdsFromString} from '../utils/remove-ar-ids-from-string';

export interface ContentBlockChange {
    contentBlock: ContentBlock;
    newContent: string;
}

export class ContentBlockChangeDetector {

    public detectChanges(docHtml: string, currentContentBlocks: ContentBlock[], currentChanges: Map<string, ContentBlockUpdate>): ContentBlockChange[] {
        const changes: ContentBlockChange[] = [];

        const cleanedDocHtml = this.cleanupEditorContent(docHtml);
        const activeBlockKeys: string[] = currentContentBlocks.map(contentBlock => contentBlock.content_key);

        for (const currentContentBlock of currentContentBlocks) {
            const contentKey = currentContentBlock.content_key;

            const regex = new RegExp(`<!--start-data-block='${contentKey}'-->(.*?)<!--end-data-block='${contentKey}'-->`, 'ms');
            const cleanedContentBlocksInHtml = cleanedDocHtml.match(regex);
            if (cleanedContentBlocksInHtml == null) {
                continue;
            }

            const cleanedContentBlockInHtml = cleanedContentBlocksInHtml[1];
            if (cleanedContentBlockInHtml == null) {
                continue;
            }

            let changeDetected = false;
            if (!currentContentBlock.content) {
                // backend content block is empty
                changeDetected = true;
            }

            const preparedContentBlockContentInHtml = this.prepareContentBlockContentForComparison(cleanedContentBlockInHtml);

            if (!changeDetected) {
                // content block content changed from original
                const preparedContentBlockContent = this.prepareContentBlockContentForComparison(currentContentBlock.content);
                const contentEqual = ContentBlockComparator
                    .equal(preparedContentBlockContentInHtml, preparedContentBlockContent, activeBlockKeys);
                if (!contentEqual) {
                    changeDetected = true;
                }
            }
            if (!changeDetected && currentChanges.has(contentKey)) {
                // already changed content block changed
                const changedContentBlockContent = currentChanges.get(contentKey).content;
                const contentEqual = ContentBlockComparator
                    .equal(preparedContentBlockContentInHtml, changedContentBlockContent, activeBlockKeys);
                if (!contentEqual) {
                    changeDetected = true;
                }
            }

            if (changeDetected) {
                changes.push({
                    contentBlock: currentContentBlock,
                    newContent: cleanedContentBlockInHtml,
                });
            }
        }

        return changes;
    }

    private cleanupEditorContent(content?: string) {
        if (content == null) {
            return content;
        }

        content = removeArIdsFromString(content);

        return content;
    }

    private prepareContentBlockContentForComparison(content: string): string {
        let preparedContent = content ?? '';
        preparedContent = removeArIdsFromString(preparedContent);
        return preparedContent;
    }
}
