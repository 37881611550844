import {ArchitectTextEl} from '../../architect-text-element';

export class DivContainerEl extends ArchitectTextEl {
    name = 'div container';
    html = '<div></div>';
    contentCategories = ['flow'];
    allowedContent = ['flow'];
    category = 'layout';
    icon = 'crop-landscape';

    matcher(node: HTMLElement) {
        return node.nodeName === 'DIV';
    }
}
