import {Injectable} from '@angular/core';
import {
    CardCarouselWidgetEditorModalComponent,
    CardCarouselWidgetEditorModalComponentData
} from './card-carousel-widget-editor-modal/card-carousel-widget-editor-modal.component';
import {MatDialogRef} from '@angular/material/dialog/dialog-ref';
import {Modal} from '@common/core/ui/dialogs/modal.service';
import {CardCarouselWidget} from './card-carousel-widget.model';

@Injectable({
    providedIn: 'root',
})
export class CardCarouselWidgetEditor {
    public modalRef: MatDialogRef<CardCarouselWidgetEditorModalComponent>;

    constructor(
        public modal: Modal
    ) {
    }

    open(
        origin: HTMLElement,
        widget: CardCarouselWidget
    ): MatDialogRef<CardCarouselWidgetEditorModalComponent> {
        const data: CardCarouselWidgetEditorModalComponentData = {
            widget,
        };
        this.modalRef = this.modal.open(CardCarouselWidgetEditorModalComponent, data);
        return this.modalRef;
    }

    close() {
        this.modalRef?.close();
    }
}
