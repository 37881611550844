<mat-tab-group (selectedTabChange)="onTabChange($event)" animationDuration="0">
    <mat-tab label="projects" *ngIf="showProjects">
        <ng-template mat-tab-label>
            <span trans>Project</span>
        </ng-template>
        <loading-indicator [isVisible]="loading" class="overlay overlay-partial"></loading-indicator>
        <ng-container *ngIf="isTabLoaded('projects') && !loading">
            <div class="tab-header-controls" *ngIf="projects?.length > 1">
                <ng-select (ngModelChange)="onProjectChange($event)"
                           [(ngModel)]="selectedProject"
                           [clearable]="false"
                           appendTo="body"
                           appearance="outline">
                    <ng-option *ngFor="let project of projects"
                               [value]="project">
                        {{ project | projectDisplayName }}
                    </ng-option>
                </ng-select>
            </div>
            <project-media *ngIf="selectedProject"
                           [uploadEnabled]="canUploadToProject(selectedProject.id)"
                           [title]="selectedProject.name"
                           [projectId]="selectedProject.id"
                           [filterType]="filterType"
                           [mode]="getProjectMode()"
                           [multiselect]="isMultiselect()"
                           (selectionChange)="filesSelectionChanged('projects-' + selectedProject.id, $event)"></project-media>
            <div *ngIf="!selectedProject" trans>No project loaded.</div>
        </ng-container>
    </mat-tab>
    <mat-tab label="agencies" *ngIf="showAgencies">
        <ng-template mat-tab-label>
            <span trans>Agency</span>
        </ng-template>
        <loading-indicator [isVisible]="loading" class="overlay overlay-partial"></loading-indicator>
        <ng-container *ngIf="isTabLoaded('agencies') && !loading">
            <div class="tab-header-controls" *ngIf="agencies?.length > 1">
                <ng-select (ngModelChange)="onAgencyChange($event)"
                           [ngModel]="selectedAgency"
                           [clearable]="false"
                           appendTo="body"
                           appearance="outline">
                    <ng-option *ngFor="let agency of agencies"
                               [value]="agency">
                        {{ agency | agencyDisplayName }}
                    </ng-option>
                </ng-select>
            </div>
            <file-entry-index *ngIf="selectedAgency"
                              [endpoint]="'media/agency/' + selectedAgency.id"
                              [ownershipType]="OWNERSHIP_TYPE_AGENCY"
                              [ownershipId]="'' + selectedAgency.id"
                              [uploadEnabled]="canUploadToAgency(selectedAgency.id)"
                              [title]="selectedAgency.name"
                              [filterType]="filterType"
                              [mode]="getAgencyMode()"
                              [multiselect]="isMultiselect()"
                              [deletePermission]="OWNERSHIP_ID_PERMISSION_AGENCY"
                              (filesSelectionChange)="filesSelectionChanged('agencies-' + selectedAgency.id, $event)"
            ></file-entry-index>
            <div *ngIf="!selectedAgency" trans>No agency loaded.</div>
        </ng-container>
    </mat-tab>
    <mat-tab label="global">
        <ng-template mat-tab-label>
            <span trans>Editorial</span>
        </ng-template>
        <loading-indicator [isVisible]="loading" class="overlay overlay-partial"></loading-indicator>
        <ng-container *ngIf="isTabLoaded('global') && !loading">
            <file-entry-index endpoint="media/global"
                              [ownershipType]="OWNERSHIP_TYPE_PERMISSION"
                              [ownershipId]="OWNERSHIP_ID_PERMISSION_GLOBAL"
                              [uploadEnabled]="canUploadToGlobal()"
                              [title]="'Editorial' | trans"
                              [filterType]="filterType"
                              [mode]="getGlobalMode()"
                              [multiselect]="isMultiselect()"
                              [deletePermission]="OWNERSHIP_ID_PERMISSION_GLOBAL"
                              (filesSelectionChange)="filesSelectionChanged('global', $event)"
            ></file-entry-index>
        </ng-container>
    </mat-tab>
    <mat-tab label="system">
        <ng-template mat-tab-label>
            <span trans>System</span>
        </ng-template>
        <loading-indicator [isVisible]="loading" class="overlay overlay-partial"></loading-indicator>
        <ng-container *ngIf="isTabLoaded('system') && !loading">
            <file-entry-index endpoint="media/system"
                              [ownershipType]="OWNERSHIP_TYPE_PERMISSION"
                              [ownershipId]="OWNERSHIP_ID_PERMISSION_SYSTEM"
                              [uploadEnabled]="canUploadToSystem()"
                              [title]="'System' | trans"
                              [filterType]="filterType"
                              [mode]="getSystemMode()"
                              [multiselect]="isMultiselect()"
                              [deletePermission]="OWNERSHIP_ID_PERMISSION_SYSTEM"
                              (filesSelectionChange)="filesSelectionChanged('system', $event)"
            ></file-entry-index>
        </ng-container>
    </mat-tab>
</mat-tab-group>
