export function isNodeEnclosedByNodeWithCssClass(node: HTMLElement | undefined, cssClass: string): boolean {
    if (node == null) {
        return false;
    }

    if (node.classList && node.classList.contains(cssClass)) {
        return true;
    }

    return node.parentNode != null && isNodeEnclosedByNodeWithCssClass(node.parentNode as HTMLElement, cssClass);
}
