import {NgModule} from '@angular/core';
import {MaterialModule} from '../material.module';
import {CommonModule} from '@angular/common';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {CrupdateProjectModalComponent} from './crupdate-project-modal/crupdate-project-modal.component';
import {TranslationsModule} from '@common/core/translations/translations.module';
import {SelectUserInputModule} from '@common/core/ui/select-user-input/select-user-input.module';
import {UploadImageControlModule} from '@common/shared/form-controls/upload-image-control/upload-image-control.module';
import {UploadExcelControlModule} from '@common/shared/form-controls/upload-excel-control/upload-excel-control.module';
import {ProjectChooserModalComponent} from '../html-builder/content-blocks/project-chooser-modal/project-chooser-modal.component';
import {MatAutocompleteModule} from '@angular/material/autocomplete';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatInputModule} from '@angular/material/input';
import {MatTabsModule} from '@angular/material/tabs';
import {SlugControlModule} from '@common/shared/form-controls/slug-control/slug-control.module';
import {SvgImageModule} from '@common/core/ui/svg-image/svg-image.module';
import {NoResultsMessageModule} from '@common/core/ui/no-results-message/no-results-message.module';
import {ProjectSettingsModalComponent} from './projects/project-settings-modal/project-settings-modal.component';
import {SiteUrlTabComponent} from './projects/project-settings-modal/tabs/site-url-tab/site-url-tab.component';
import {ConfirmDialogComponent} from './confirm-dialog/confirm-dialog.component';
import {
    ContentBlockChooserPanelComponent
} from '../html-builder/sidebar/inspector-panel/content-block-panel/content-block-chooser/content-block-chooser-panel.component';
import {DataTableModule} from '@common/shared/data-table/data-table.module';
import {MatExpansionModule} from '@angular/material/expansion';
import {NgSelectModule} from '@ng-select/ng-select';
import {DatatableModule} from '@common/datatable/datatable.module';
import {FormatPipesModule} from '@common/core/ui/format-pipes/format-pipes.module';
import {RouterModule} from '@angular/router';
import {ProjectBundlePageMappingsModalComponent} from './projects/project-bundle-page-mappings-modal/project-bundle-page-mappings-modal.component';
import {LanguageBadgeModule} from '@common/shared/language-badge/language-badge.module';
import {AgencyDisplayNamePipe} from './agencies/agency-display-name.pipe';
import {PublishedAgencyNamePipe} from './agencies/published-agency-name.pipe';
import {ProjectDisplayNamePipe} from './projects/project-display-name.pipe';
import {NotificationModalTabComponent} from './projects/project-settings-modal/tabs/notification-modal-tab/notification-modal-tab.component';
import {CKEditorModule} from '@ckeditor/ckeditor5-angular';
import {LoadingBarModule} from '@common/core/ui/loading-bar/loading-bar.module';
import {MatBadgeModule} from '@angular/material/badge';
import {TemplateI18nModalComponent} from './templates/template-i18n-modal/template-i18n-modal.component';
import {InfoDialogComponent} from './info-dialog/info-dialog.component';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        MaterialModule,
        TranslationsModule,
        SelectUserInputModule,
        ReactiveFormsModule,
        UploadImageControlModule,
        UploadExcelControlModule,
        MatAutocompleteModule,
        MatFormFieldModule,
        MatInputModule,
        NgSelectModule,
        MatTabsModule,
        SlugControlModule,
        SvgImageModule,
        NoResultsMessageModule,
        DataTableModule,
        MatExpansionModule,
        DatatableModule,
        FormatPipesModule,
        RouterModule,
        MatExpansionModule,
        LanguageBadgeModule,
        CKEditorModule,
        LoadingBarModule,
        MatBadgeModule,
    ],
    exports: [
        AgencyDisplayNamePipe,
        ProjectDisplayNamePipe,
        PublishedAgencyNamePipe
    ],
    declarations: [
        CrupdateProjectModalComponent,
        ProjectChooserModalComponent,
        ProjectSettingsModalComponent,
        SiteUrlTabComponent,
        NotificationModalTabComponent,
        ConfirmDialogComponent,
        InfoDialogComponent,
        ContentBlockChooserPanelComponent,
        ProjectBundlePageMappingsModalComponent,
        TemplateI18nModalComponent,
        AgencyDisplayNamePipe,
        PublishedAgencyNamePipe,
        ProjectDisplayNamePipe,
    ]
})
export class SharedModule {
}
