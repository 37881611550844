import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {SystemAlertComponent} from './system-alert.component';
import {AlertModule} from '@common/shared/alert/alert.module';

@NgModule({
    imports: [
        CommonModule,
        AlertModule
    ],
    declarations: [
        SystemAlertComponent,
    ],
    exports: [
        SystemAlertComponent,
    ]
})
export class SystemAlertModule {}
