import {Injectable} from '@angular/core';
import {BuilderTemplate} from '../builder-types';
import {Project} from './Project';
import {DomHelpers} from '../dom-helpers.service';
import {PageDocument} from '../page-document.ts';
import {Projects} from './projects.service';
import {Toast} from '@common/core/ui/toast.service';
import {Themes} from '../themes/themes.service';
import {TemplatesApi} from '../templates/templates-api.service';
import {from} from 'rxjs';
import {concatMap} from 'rxjs/operators';
import {ProjectBundle} from './ProjectBundle';

@Injectable({
    providedIn: 'root'
})
export class ProjectCreateService {

    private pageDocument = new PageDocument();

    constructor(
        public projects: Projects,
        private toast: Toast,
        private themesApi: Themes,
        private templatesApi: TemplatesApi,
    ) {
    }

    public createFromBundle(projectBundle: ProjectBundle, language?: string) {
        const projectModel: Partial<Project> = {
            project_bundle_id: projectBundle.id,
            name: `${projectBundle.name}`,
            language,
            template: `${projectBundle.template}`,
            agency_id: projectBundle.agency_id,
            users: projectBundle.users,
            published: projectBundle.published,
        };

        return this.create(projectModel);
    }

    public create(projectModel: Partial<Project>) {
        return from(this.getNewProjectPayload(projectModel)).pipe(
            concatMap(((payload: object) => this.projects.create(payload)))
        );
    }

    private getNewProjectPayload(model: Partial<Project>) {
        if (model.template) {
            return this.createProjectFromTemplate(model);
        } else {
            return this.createBlankProject(model);
        }
    }

    private createProjectFromTemplate(model: Partial<Project>): Promise<any> {
        return new Promise(resolve => {
            const params = model as any;

            this.templatesApi.get(model.template, model.language).subscribe(response => {
                params.template = response.template;
                params.framework = response.template.config.framework;
                params.pages = this.transformTemplatePages(response.template);
                params.agency_id = model.agency?.id ?? params.agency_id;
                delete params.template.pages;
                delete params.agency;
                resolve(params);
            });
        });
    }

    private transformTemplatePages(template: BuilderTemplate) {
        return template.pages.map(page => {
            return {
                name: page.name,
                html: DomHelpers.removeBaseUrl(this.pageDocument.generate(page.html, template).getOuterHtml()),
            };
        });
    }

    private createBlankProject(model: Partial<Project>) {
        const params = model as any;

        params.pages.push({
            name: 'index',
            html: DomHelpers.removeBaseUrl(this.pageDocument.generate().getOuterHtml()),
        });
        params.agency_id = model.agency?.id ?? params.agency_id;
        delete params.agency;

        return params;
    }
}
