import {Injectable} from '@angular/core';
import * as html2canvas from 'html2canvas';
import {Toast} from '@common/core/ui/toast.service';
import {LocalStorage} from '@common/core/services/local-storage.service';
import {ProjectUrl} from '../../shared/projects/project-url.service';
import {Projects} from '../../shared/projects/projects.service';
import {BuilderPage, BuilderProject, BuilderProjectBundleInfo} from '../../shared/builder-types';
import {debounceTime} from 'rxjs/operators';
import {BuilderStateService} from '../builder-state.service';
import {MutationsService} from '../mutations/mutations.service';
import {Settings} from '@common/core/config/settings.service';
import {CurrentUser} from '@common/auth/current-user';
import {ContentBlockService} from '../content-blocks/content-block.service';
import {ConfirmDialogComponent, ConfirmDialogModel} from '../../shared/confirm-dialog/confirm-dialog.component';
import {MatDialog} from '@angular/material/dialog';
import {ProjectBundles} from '../../shared/projects/project-bundles.service';
import {getPageHtml} from '../utils/get-page-html';
import {removeArIdsFromString} from '../utils/remove-ar-ids-from-string';
import {createDocFromHtml} from '../utils/create-doc-from-html';
import {addIdToPages} from '../utils/add-id-to-pages';
import {addBundleInfoToPages} from '../utils/add-bundle-info-to-pages';

@Injectable({
    providedIn: 'root',
})
export class ActiveProject {
    saving = false;
    private unsaved = false;

    constructor(
        private settings: Settings,
        public projectUrl: ProjectUrl,
        private projects: Projects,
        private projectBundles: ProjectBundles,
        private toast: Toast,
        private localStorage: LocalStorage,
        private state: BuilderStateService,
        private mutations: MutationsService,
        private contentBlockService: ContentBlockService,
        private currentUser: CurrentUser,
        private dialog: MatDialog,
        private projectBundleApi: ProjectBundles,
    ) {
        this.bindToBuilderDocumentChangeEvent();

        this.state.project$.subscribe(project => {
            this.loadProjectBundle(project);
        });

        this.mutations.executed$.subscribe(() => {
            this.unsaved = true;
        });
    }

    save(
        options: { thumbnail?: boolean, payload?: object, publish?: boolean } = {}
    ): Promise<{ project: BuilderProject } | void> {
        const {
            thumbnail = true,
            payload = {},
            publish = false,
        } = options;

        this.saving = true;

        if (thumbnail) {
            this.createThumbnail();
        }

        const project = this.state.project$.value;

        this.contentBlockService.checkContentBlocks();

        const updatePayload = {
            name: project.model.name,
            css: project.css,
            js: project.js,
            ...payload,
            pages: this.state.pages$.value.map(page => {
                const pageHtml = this.processPageHtmlBeforeSave(getPageHtml(page));
                return {
                    name: page.name,
                    html: pageHtml,
                };
            }),
            contentBlocks: this.contentBlockService.getContentBlockUpdates(),
            publish,
        };

        return this.projects
            .update(project.model.id, updatePayload)
            .toPromise()
            .then(async response => {
                    await this.contentBlockService.save();

                    this.unsaved = false;
                    this.state.project$.next(response.project);

                    // we update pages, since they could be different, if they got updates from content blocks
                    this.setPages(response.project.pages, true);

                    this.toast.open('Project saved');
                    return response;
                }
            )
            .catch(() => {
                this.toast.open('Could not save project');
            })
            .finally(() => {
                this.saving = false;
            });
    }

    getBaseUrl(relative = false): string {
        if (!this.state.project$.value) return '';
        return this.projectUrl.getBaseUrl(
            this.state.project$.value.model,
            relative
        );
    }

    getProductionUrl() {
        return this.projectUrl.getProductionUrl(
            this.state.project$.value.model
        );
    }

    public getSiteUrl() {
        return this.projectUrl.getSiteUrl(this.state.project$.value.model, false);
    }

    public getSitePreviewUrl() {
        return this.projectUrl.getSitePreviewUrl(this.state.project$.value.model);
    }

    private bindToBuilderDocumentChangeEvent() {
        this.mutations.executed$.pipe(debounceTime(1000)).subscribe(() => {
            if (this.localStorage.get('settings.autoSave')) {
                this.save({thumbnail: false});
            }
        });
    }

    setActivePageByName(pageName?: string, refresh: boolean = false) {
        const page = this.state.pages$.value.find(curr => curr.name === pageName);
        this.setActivePage(page?.id, refresh);
    }

    setActivePage(pageId?: string, refresh: boolean = false) {
        // get current active page (id maybe changed, so we search the current pages by name)
        let nextPage = this.state.pages$.value.find((page) => {
            return page.name === this.state.activePage$.value?.name;
        });
        // or requested page
        if (pageId != null && (nextPage == null || nextPage.id !== pageId)) {
            nextPage = this.state.pages$.value.find((page) => {
                return page.id === pageId;
            });
        }
        // check access
        if (nextPage != null && this.isPageDisabled(nextPage.name)) {
            nextPage = null;
        }
        // or index page
        if (nextPage == null) {
            nextPage = this.state.pages$.value.find((page) => {
                return page.name === 'index';
            });
        }
        // or first page
        if (nextPage == null) {
            nextPage = this.state.pages$.value[0];
        }

        if (this.state.activePage$.value?.id === nextPage.id && !refresh) {
            return;
        }

        if (nextPage) {
            if (!nextPage.doc || refresh) {
                nextPage.doc = createDocFromHtml(nextPage.html);
            }
            this.state.activePage$.next(nextPage);
        }
    }

    refreshActivePage() {
        this.setActivePage(this.state.activePage$.value.id, true);
    }

    setProject(project: BuilderProject, initialPageName?: string) {
        this.state.project$.next(project);
        this.setPages(project.pages, true, initialPageName);
    }

    setProjectBundle(projectBundle: BuilderProjectBundleInfo) {
        this.state.projectBundle$.next(projectBundle);
        this.updatePages();
    }

    setPages(pages: BuilderPage[], refresh: boolean = false, initialPageName?: string) {
        pages = addIdToPages(pages);
        pages = addBundleInfoToPages(pages, this.state.projectBundle$.value, this.state.project$.value.model.language);
        this.state.pages$.next(addIdToPages(pages));

        this.setActivePageByName(initialPageName, refresh);
    }

    private updatePages() {
        this.setPages(this.state.pages$.value);
    }

    addPage(page: BuilderPage) {
        this.setPages([page, ...this.state.pages$.value], false, page.name);
    }

    public isPageDisabled(pageName: string) {
        let isDisabled = false;

        const adminPages = JSON.parse(this.settings.get('admin.pages') || '[]');
        const adminRoles = JSON.parse(this.settings.get('admin.page.roles') || '[]');
        const hiddenPages = JSON.parse(this.settings.get('admin.page.general.hidden') || '[]');

        const isAdminRole = this.currentUser.hasAnyRole(adminRoles);

        if (hiddenPages.includes(pageName)) {
            isDisabled = true; // Seite in hiddenPages ist immer deaktiviert
        } else {
            if (adminPages.includes(pageName)) {
                if (!isAdminRole) {
                    isDisabled = true; // Seite in adminPages ist deaktiviert, es sei denn, der Benutzer hat eine adminRole
                }
            }
        }

        return isDisabled;
    }


    private createThumbnail() {
        const base = document.createElement('base');
        base.href = this.getBaseUrl();
        if (!this.state.previewDoc.head.querySelector('base')) {
            this.state.previewDoc.head.prepend(base);
        }
        const rect =
            this.state.previewDoc.documentElement.getBoundingClientRect();
        (html2canvas as any)(this.state.previewDoc.documentElement, {
            height: rect.height,
            width: rect.width,
        }).then(canvas => {
            base.remove();
            this.projects
                .generateThumbnail(
                    this.state.project$.value.model.id,
                    canvas.toDataURL('image/png')
                )
                .subscribe(
                    () => {
                    },
                    () => {
                    }
                );
        });
    }

    public updateBuilderDocumentHtml() {
        // return this.builderDocument.update({
        //     html: this.getActivePage().html,
        // });
    }

    switchProject(projectId: number, pageName?: string) {
        const switchToProject = () => {
            let url = `/design/${projectId}`;

            if (pageName != null) {
                url += `?page=${pageName}`;
            } else {

            }

            window.location.href = url;
        };

        if (this.state.project$.value.model.id !== projectId) {
            if (this.unsaved) {
                const dialogData = new ConfirmDialogModel({
                    title: 'Project has unsaved changes',
                    message: `Do you want to discard the changes and switch the project?`,
                    confirmLabel: 'Discard and switch'
                });
                const dialogRef = this.dialog.open(ConfirmDialogComponent, {data: dialogData});

                dialogRef.afterClosed().subscribe(confirmed => {
                    if (confirmed) {
                        switchToProject();
                    }
                });
            } else {
                switchToProject();
            }
        } else {
            // we don't switch, if the project we want to switch to is already the current project
        }
    }

    public loadProjectBundle(project?: BuilderProject) {
        if (project == null) {
            return;
        }

        const projectBundleId = project.model.project_bundle_id;
        if (projectBundleId == null) {
            return;
        }

        this.projectBundleApi.get(projectBundleId)
            .subscribe((response => {
                this.setProjectBundle(response);
            }));
    }

    renamePageMapping(pageNameFrom: string, pageNameTo: string) {
        const project = this.state.project$.value;
        const language = project.model.language;

        const projectBundleInfo = this.state.projectBundle$.value;
        const projectBundle = projectBundleInfo?.projectBundle;

        if (language == null || projectBundle == null) {
            return;
        }

        const pageMappings = JSON.parse(projectBundle.page_mappings) as Array<any>;

        const currentMapping = pageMappings.find(pageMapping => pageMapping[language] === pageNameFrom);

        if (currentMapping == null) {
            return;
        }

        currentMapping[language] = pageNameTo;

        return this.savePageMappings(pageMappings);
    }

    duplicatePageMapping(pageName: string) {
        const project = this.state.project$.value;
        const language = project.model.language;

        const projectBundleInfo = this.state.projectBundle$.value;
        const projectBundle = projectBundleInfo?.projectBundle;

        if (language == null || projectBundle == null) {
            return;
        }

        const pageMappings = JSON.parse(projectBundle.page_mappings) as Array<any>;

        const currentMapping = pageMappings.find(pageMapping => pageMapping[language] === pageName);

        if (currentMapping == null) {
            return;
        }

        const newMapping = Object.keys(currentMapping).reduce((mapping: object, mappingLanguage: string) => {
            mapping[mappingLanguage] = currentMapping[mappingLanguage] + '-copy';
            return mapping;
        }, {});

        pageMappings.push(newMapping);

        return this.savePageMappings(pageMappings);
    }

    private savePageMappings(pageMappings) {
        const projectBundleInfo = this.state.projectBundle$.value;
        const projectBundle = projectBundleInfo?.projectBundle;

        if (projectBundle == null) {
            return;
        }

        const pageMappingsJson = JSON.stringify(pageMappings);
        return this.projectBundles.updatePageMappings(projectBundle.id, {
            page_mappings: pageMappingsJson
        });
    }

    public generatePageName(originalPageName: string, postfix: string = ''): string {
        const pageNames = this.state.pages$.value.map(page => page.name);
        let newPageName = originalPageName + postfix;

        let index = 2;
        while (pageNames.includes(newPageName)) {
            newPageName = originalPageName + postfix + index;
            index++;
        }

        return newPageName;
    }

    private processPageHtmlBeforeSave(pageHtml: string): string {
        if (!pageHtml.startsWith('<!DOCTYPE html>')) {
            pageHtml = `<!DOCTYPE html>\n${pageHtml}`;
        }

        pageHtml = removeArIdsFromString(pageHtml);

        return pageHtml;
    }
}
