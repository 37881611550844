import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {MatButtonModule} from '@angular/material/button';
import {TranslationsModule} from '@common/core/translations/translations.module';
import {HelpButtonComponent} from './help-button/help-button.component';
import {MatTooltipModule} from '@angular/material/tooltip';
import {MatIconModule} from '@angular/material/icon';


@NgModule({
    declarations: [
        HelpButtonComponent,
    ],
    imports: [
        CommonModule,
        MatIconModule,
        MatTooltipModule,
        TranslationsModule,
        MatButtonModule,
    ],
    exports: [
        HelpButtonComponent,
    ]
})
export class HelpModule {
}
