import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {Component, Inject} from '@angular/core';

interface InfoDialogData {
    title?: string;
    message: string;
}

export class InfoDialogModel {
    constructor(
        public data: InfoDialogData,
    ) {
    }
}

@Component({
    selector: 'app-info-dialog',
    templateUrl: './info-dialog.component.html',
    styleUrls: ['./info-dialog.component.scss'],
})
export class InfoDialogComponent {

    title: string;
    message: string;

    constructor(
        public dialogRef: MatDialogRef<InfoDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: InfoDialogModel
    ) {
        this.title = data.data.title ?? 'Information';
        this.message = data.data.message;
    }

    onDismiss(): void {
        this.dialogRef.close(false);
    }
}
