import {ArchitectElement} from '../architect-element';
import {hasClass} from '../../utils/has-class';

const template = `<header class="image-header">
    <h3>Herzlich Willkommen!</h3>
    <h1>Ein großes Bild als Vorlage</h1>
    <p><a class="btn btn-primary btn-lg" role="button">Erfahren Sie mehr</a></p>
</header>`;

export class ImageHeaderEl extends ArchitectElement {
    name = 'image header';
    contentCategories = ['flow'];
    html = template;
    allowedContent = ['flow'];
    category = 'components';
    icon = 'image';
    specificity = 5;
    matcher(node: HTMLElement) {
        return hasClass(node, 'image-header');
    }
}
