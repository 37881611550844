import {ArchitectElControl, ArchitectElement, ElControlType} from '../../architect-element';
import {MutationsService} from '../../../mutations/mutations.service';
import {ModifyAttributes} from '../../../mutations/attributes/modify-attributes';
import {I18nWidgetService} from './i18n-widget-editor.service';
import {Injector} from '@angular/core';
import {CurrentUser} from '@common/auth/current-user';

export class I18nWidgetEl extends ArchitectElement {
    name = 'Internationalization (i18n)';
    allowedContent = [];
    specificity = 5;
    hiddenElement = true;
    blocksContent = true;
    controls = [
        new ArchitectElControl(this.injector, {
            label: 'i18n key',
            type: ElControlType.Input,
            inputType: 'text',
            defaultValue(node: HTMLInputElement) {
                return node.getAttribute('data-esb-i18n') ?? '';
            },
            onChange(node: HTMLElement, value: string) {
                this.get(MutationsService).execute(
                    new ModifyAttributes(node, {
                        'data-esb-i18n': value,
                    })
                );
            },
        }),
    ];

    constructor(protected injector: Injector) {
        super(injector);

        const currentUser = injector.get(CurrentUser);

        if (currentUser.hasPermission('templates.update_i18n')) {
            this.editActions.push({
                id: 'action.i18n.edit',
                name: 'Edit i18n',
                onClick: async (node: HTMLElement) => {
                    const i18nKey = node.getAttribute('data-esb-i18n') ?? '';
                    (await this.get(I18nWidgetService)
                        .open(node, i18nKey));
                },
            });
        } else {
            this.hiddenElement = true;
        }
    }

    matcher(node: HTMLElement) {
        return node.closest('[data-esb-i18n]') as HTMLElement;
    }
}
