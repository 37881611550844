import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {MatButtonModule} from '@angular/material/button';
import {TranslationsModule} from '@common/core/translations/translations.module';
import {ClearCacheButtonComponent} from './clear-cache-button/clear-cache-button.component';
import {MatTooltipModule} from '@angular/material/tooltip';
import {MatIconModule} from '@angular/material/icon';


@NgModule({
    declarations: [
        ClearCacheButtonComponent,
    ],
    imports: [
        CommonModule,
        MatIconModule,
        MatTooltipModule,
        TranslationsModule,
        MatButtonModule,
    ],
    exports: [
        ClearCacheButtonComponent,
    ]
})
export class CacheModule {
}
