/**
 * Converts an input string to its corresponding propert boolean value, with e.g. 'false' or 'no' becoming false.
 * If no corresponding boolean value can be detemined, the defaultValue is returned.
 */
export function stringToBoolean(input: string, defaultValue?: boolean): boolean {
    if (input?.toLowerCase() === 'true' || input?.toLowerCase() === 'yes') {
        return true;
    }

    if (input?.toLowerCase() === 'false' || input?.toLowerCase() === 'no') {
        return false;
    }

    return defaultValue;
}
