import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    Input,
    OnChanges, OnDestroy,
    OnInit,
    SimpleChanges
} from '@angular/core';

@Component({
    selector: 'img-dimensions[src]',
    templateUrl: './img-dimensions.component.html',
    styleUrls: ['./img-dimensions.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class ImgDimensionsComponent implements OnInit, OnChanges, OnDestroy {

    @Input()
    src: string;

    imgEl?: HTMLImageElement;
    content: string;

    constructor(private readonly cd: ChangeDetectorRef) {
    }

    ngOnInit(): void {
        this.updateDimensions();
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes.src) {
            this.updateDimensions();
        }
    }

    ngOnDestroy() {
        this.removeImgEl();
    }

    private updateDimensions() {
        this.removeImgEl();
        this.updateContent('');

        if (this.src){
            // https://stackoverflow.com/questions/5633264/javascript-get-image-dimensions
            const imgEl = document.createElement('img');
            imgEl.onload = (event) => {
                const width = imgEl.naturalWidth;
                const height = imgEl.naturalHeight;
                this.updateContent(`${width} x ${height}`);

                // remove after we retrieved the data
                this.removeImgEl();
            };
            imgEl.src = this.src;
            document.body.appendChild(imgEl);
            this.imgEl = imgEl;
        }
    }

    private removeImgEl() {
        if (this.imgEl != null) {
            document.body.removeChild(this.imgEl);
            this.imgEl = undefined;
        }
    }

    private updateContent(content: string) {
        this.content = content;
        this.cd.markForCheck();
    }
}
