export class BlockGridWidget {
    public items: BlockGridWidgetItem[] = [];
    public itemHoverOverlayColor?: string;
    public gridGap = '.5rem';
    public linkIndicatorIconColor = '';
    public linkIndicatorBackgroundColor = '';
}

export class BlockGridWidgetItem {
    public enabled = true;
    public layout: BlockGridWidgetBlockLayout = 'x1_y1';
    public title: string;
    public description: string;
    public backgroundColor?: string;
    public textColor?: string;
    public href?: string;
    public hrefNewTab?: boolean;
    public imageSrc?: string;
    public imageAlt?: string;
    public imagePosition?: BlockGridWidgetBlockImagePosition = 'start';
}

/**
 * x_y, x = number of columns, y = number of rows
 */
export type BlockGridWidgetBlockLayout = 'x1_y1' | 'x1_y2' | 'x2_y1';
export type BlockGridWidgetBlockImagePosition = 'start' | 'end';
